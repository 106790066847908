import React from 'react';

import clsx from 'clsx';
import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import styles from './Datepicker.module.css';

const renderMonthString = (
  date: Date,
  monthNames: { [key: string]: string }
): string => {
  const month = monthNames[date.getMonth()];

  return `${month} ${date.getUTCFullYear()}`;
};

interface Props {
  date: Date;
  onMonthChange: (direction: string) => void;
  disabledNextMonth?: boolean;
  disabledPrevMonth?: boolean;
  monthNames: { [key: string]: string };
}

export const MonthHeader = ({
  date,
  onMonthChange,
  disabledNextMonth,
  disabledPrevMonth,
  monthNames,
}: Props) => {
  return (
    <div className={clsx(styles.monthHeader)}>
      <div
        onClick={disabledPrevMonth ? undefined : () => onMonthChange('prev')}
        data-cy="prev"
      >
        <Chevron
          data-testid="prev"
          colour="bloomGreyNavy500"
          direction={Direction.Left}
          disabled={disabledPrevMonth}
          className={clsx({ [styles.disabledNav]: disabledPrevMonth })}
        />
      </div>
      <div data-cy="monthHeader">{renderMonthString(date, monthNames)}</div>
      <div
        onClick={disabledNextMonth ? undefined : () => onMonthChange('next')}
        data-cy="next"
      >
        <Chevron
          data-testid="next"
          colour="bloomGreyNavy500"
          direction={Direction.Right}
          disabled={disabledNextMonth}
          className={clsx({ [styles.disabledNav]: disabledNextMonth })}
        />
      </div>
    </div>
  );
};
