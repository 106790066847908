import { fetchCached, RequestData } from 'js/helpers/service';
import { RecommendedVenueResponseOutput } from 'js/model/rainbow/RecommendedVenueResponseOutput';

export function fetchRecommendedVenues(
  state: RequestData,
  domainUserId: string,
  ruid?: string
): Promise<RecommendedVenueResponseOutput | null> {
  return fetchCached<RecommendedVenueResponseOutput>(
    state,
    `/api/v1/personalisation/${domainUserId}/venue/recommended`,
    ruid
  );
}
