import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';

export interface VenueMenuItemOutput {
  id: string;
  name?: string; // TODO why can there be no name?
  priceRange: PriceRangeOutput;
  guests?: number;
  pricingDisplayType: PricingDisplayType;
  primaryTreatmentCategoryId?: number;

  // This should not be optional, but https://wahanda.atlassian.net/browse/MAPPS-470
  // was reverted, and so the field is not available.
  //
  // Once MAPPS-470 is reinstated
  //    - this field should be changed to required
  //    - this comment block should be removed
  requiresPatchTest?: boolean;
}

export enum PricingDisplayType {
  total = 'total_price',
  perPerson = 'per_person_price',
}
