import * as React from 'react';
import styles from './ListHeading.module.css';

interface Props {
  label: string;
}

export interface ListHeadingHandle {
  isSelectable: () => boolean;
}

export const ListHeading = React.forwardRef(
  (props: Props, ref: React.Ref<ListHeadingHandle>) => {
    React.useImperativeHandle(ref, () => ({
      isSelectable(): boolean {
        return false;
      },
    }));

    return <div className={styles.listHeading}>{props.label}</div>;
  }
);

ListHeading.displayName = 'ListHeading';
