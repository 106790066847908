import React from 'react';
import clsx from 'clsx';

import { Action, Category, trackToggleTreatmentSearchHeader } from './tracking';
import styles from './StickyCollapse.module.css';
import { animationTime } from './WhatWhereWhen.module.css';

enum CollapseReason {
  None,
  Click = 'click',
  Scroll = 'scroll',
}

interface Props {
  children: (
    collapsed: boolean,
    setCollapsed: (collapsed: boolean) => void,
    animating: boolean
  ) => React.ReactNode;
  openOnMount?: boolean;
  collapseOnEvent?: string;
  trackingCategory: Category;
  updateHeroHeight?: () => void;
  hidden?: boolean;
  heightRef?: React.RefObject<HTMLDivElement>;
}

interface State {
  collapsed: boolean;
  ignoreScrolls: boolean;
  animating: boolean;
}

export const collapseIgnoreScrollsContext = React.createContext(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (ignoreScrolls: boolean) => {}
);

export class StickyCollapse extends React.PureComponent<Props, State> {
  public state = {
    collapsed: !this.props.openOnMount ?? false,
    ignoreScrolls: false,
    animating: false,
  };

  private setClickCollapsed = (collapsed: boolean) => {
    this.setCollapsed(collapsed, CollapseReason.Click);
  };

  private setCollapsed = (collapsed: boolean, reason: CollapseReason) => {
    if (collapsed === this.state.collapsed || this.state.animating) {
      return;
    }

    this.setState({ collapsed });

    if (reason !== CollapseReason.None) {
      trackToggleTreatmentSearchHeader(
        this.props.trackingCategory,
        reason,
        collapsed ? Action.Collapse : Action.Expand
      );

      // Only animate if user-initiated change
      this.setState({ animating: true });

      // Extract the animationTime from css module and ensure we cannot trigger collapse/expand
      // for this time period with a little extra to avoid clashes
      const paddedAnimationTime = parseInt(animationTime, 10) * 1.1;
      setTimeout(
        () => this.setState({ animating: false }),
        paddedAnimationTime
      );
    }
    if (this.props.updateHeroHeight) {
      this.props.updateHeroHeight();
    }
  };

  private setIgnoreScrolls = (ignoreScrolls: boolean) => {
    this.setState({ ignoreScrolls });
  };

  private onScroll = () => {
    if (this.state.ignoreScrolls) {
      return;
    }

    const previousScrollY = window.scrollY;

    setTimeout(() => {
      if (this.state.ignoreScrolls) {
        return;
      }

      const delta = Math.abs(window.scrollY - previousScrollY);
      if (delta > 0) {
        this.setCollapsed(true, CollapseReason.Scroll);
      }
    }, 50);
  };

  private onCollapse = () => {
    setTimeout(() => {
      if (this.state.ignoreScrolls) {
        return;
      }

      this.setCollapsed(true, CollapseReason.Scroll);
    }, 50);
  };

  public componentDidMount(): void {
    const collapsed = !this.props.openOnMount;
    this.setCollapsed(collapsed, CollapseReason.None);

    document.addEventListener('scroll', this.onScroll);

    if (this.props.collapseOnEvent) {
      document.addEventListener(this.props.collapseOnEvent, this.onCollapse);
    }
  }

  public componentWillUnmount(): void {
    document.removeEventListener('scroll', this.onScroll);

    if (this.props.collapseOnEvent) {
      document.removeEventListener(this.props.collapseOnEvent, this.onCollapse);
    }
  }

  public render(): React.ReactNode {
    return (
      <div
        className={clsx({
          [styles.container]: true,
          [styles.heroTransition]: true,
          [styles.sticky]: !this.state.ignoreScrolls,
          [styles.hidden]: this.props.hidden,
        })}
        style={
          {
            '--StickyCollapse-height': `${this.props.heightRef?.current?.offsetHeight}px`,
          } as React.CSSProperties
        }
        data-cy="Hero"
      >
        <collapseIgnoreScrollsContext.Provider value={this.setIgnoreScrolls}>
          {this.props.children(
            this.state.collapsed,
            this.setClickCollapsed,
            this.state.animating
          )}
        </collapseIgnoreScrollsContext.Provider>
      </div>
    );
  }
}
