import * as React from 'react';
import { Button } from 'js/components/Button';
import { ButtonColour } from 'js/components/Button/ButtonColour';
import { ButtonArrow } from 'js/components/ButtonArrow';
import { ArrowPosition } from 'js/components/ButtonArrow/ArrowPosition';

export interface ButtonData {
  label: string;
  key: string;
  arrow?: boolean;
}

interface Props {
  buttons: ButtonData[];
  activeButtonKey?: string;
  arrowPosition?: ArrowPosition;
  onClick: (buttonKey: string) => void;
}

export function ButtonGroup(props: Props): React.ReactElement {
  return (
    <>
      {props.buttons.map((button: ButtonData) => {
        const buttonColour =
          button.key === props.activeButtonKey
            ? ButtonColour.BlueOutline
            : ButtonColour.White;

        const buttonProps = {
          colour: buttonColour,
          label: button.label,
          onClick: () => props.onClick(button.key),
        };

        if (!button.arrow) {
          return <Button key={button.key} {...buttonProps} />;
        }
        return (
          <ButtonArrow
            key={button.key}
            {...buttonProps}
            showArrow={button.key === props.activeButtonKey}
            arrowPosition={props.arrowPosition}
          />
        );
      })}
    </>
  );
}
