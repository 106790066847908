import React from 'react';
import clsx from 'clsx';
import { parseDate } from 'js/helpers/datetime-format';
import styles from './Datepicker.module.css';

interface Props {
  dateStr: string;
  onClick: (dateStr: string, isAvailable: boolean) => void;
  isAvailable: boolean;
  visibleMonth: Date;
  bookingStart: string | null;
  bookingEnd: string | null;
  daySquareSize: string | null;
}

export function Day({
  dateStr,
  onClick,
  isAvailable,
  visibleMonth,
  bookingStart,
  bookingEnd,
  daySquareSize,
}: Props): React.ReactElement {
  const isBookingStart = bookingStart === dateStr;
  const isRangeStart =
    isBookingStart && bookingStart && bookingEnd && bookingEnd > bookingStart;
  const isBookingEnd =
    bookingEnd !== bookingStart ? bookingEnd === dateStr : false;
  const isInRange =
    bookingStart &&
    bookingEnd &&
    dateStr > bookingStart &&
    dateStr < bookingEnd;
  const date = parseDate(dateStr);
  const otherMonth =
    visibleMonth && date.getMonth() !== visibleMonth.getMonth();

  const innerClasses = {
    [styles.selectedStart]: isBookingStart,
    [styles.dayInner]: true,
    [styles.selectedEnd]: isBookingEnd,
  };

  const innerClassStyle = daySquareSize
    ? {
        width: daySquareSize,
        height: daySquareSize,
      }
    : {};

  const outerClasses = {
    [styles.dayWrap]: true,
    [styles.available]: isAvailable,
    [styles.unavailable]: !isAvailable,
    [styles.otherMonth]: otherMonth,
    [styles.selectedRange]: isInRange,
  };
  const bufferClasses = {
    [styles.buffer]: true,
    [styles.bufferStart]: isBookingStart,
    [styles.bufferEnd]: isBookingEnd,
  };

  return (
    <div className={clsx(outerClasses)}>
      {isRangeStart ? <div className={clsx(bufferClasses)} /> : null}
      <div
        className={clsx(innerClasses)}
        style={innerClassStyle}
        onClick={otherMonth ? undefined : () => onClick(dateStr, isAvailable)}
      >
        {otherMonth ? '' : date.getDate()}
      </div>
    </div>
  );
}
