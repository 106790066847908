import { storage } from '@treatwell/ui';
import { Dictionary } from 'js/types/generic-types';

const LOCAL_STORAGE_KEY = 'homepage-search-data';
const NUM_RECENT_LIST_ITEMS = 5;

/*
 * Tabs
 */
enum Tab {
  Treatment = 'treatment',
  Salon = 'salon',
}

// the following are used in imports from JS. TODO replace with Tab enum once migrated to ts
export const TREATMENT = Tab.Treatment;
export const SALON = Tab.Salon;

export function readLastSearchTab(
  languageCode: string,
  defaultTab: Tab = Tab.Treatment
): Tab {
  const lastSearchTab = readHomepageSearchDataLocalStorage(languageCode)
    ?.lastSearchTab as Tab;

  switch (lastSearchTab) {
    case Tab.Treatment:
    case Tab.Salon:
      return lastSearchTab;
    default:
      return defaultTab;
  }
}

/*
 * Treatment Tab Data
 */

interface LastTreatmentSearchData {
  treatment: ListItemData | null;
  location: ListItemData | null;
  date: Date | null;
  time: { from: string; to: string } | null;
}

function validatedLastTreatmentSearchData(
  tabData: unknown
): LastTreatmentSearchData | null {
  if (!isDictionary(tabData)) {
    return null;
  }

  const lastSearch = tabData.lastSearch;

  if (!isDictionary(lastSearch)) {
    return null;
  }

  let treatment;
  let location;
  let date;
  let time;
  try {
    treatment = validatedNullableListItemData(lastSearch.treatment);
    location = validatedNullableListItemData(lastSearch.location);
    date = validatedNullableDate(lastSearch.date);
    time = validatedTime(lastSearch.time);
  } catch (error) {
    return null;
  }

  return {
    treatment,
    location,
    date,
    time,
  };
}

interface TreatmentTabData {
  lastSearch: LastTreatmentSearchData;
  recentTreatments: ListItemData[];
  recentLocations: ListItemData[];
}

export function readTreatmentTabData(
  languageCode: string,
  defaultTreatment: ListItemData | null = null,
  defaultLocation: ListItemData | null = null,
  defaultDate: Date | null = null,
  defaultTime: { from: string; to: string } | null = null
): TreatmentTabData {
  let lastSearch = {
    treatment: defaultTreatment,
    location: defaultLocation,
    date: defaultDate,
    time: defaultTime,
  };
  let recentTreatments: ListItemData[] = [];
  let recentLocations: ListItemData[] = [];

  try {
    const tabData = readHomepageSearchDataLocalStorage(languageCode)?.[
      Tab.Treatment
    ];
    lastSearch = validatedLastTreatmentSearchData(tabData) || lastSearch;
    recentTreatments = (tabData as Dictionary<unknown[]>)
      .recentTreatments!.map(validatedNonNullableListItemData)
      // filter out disabled treatments that could have previously made it into localStorage
      .filter(item => item.entityValue)
      .slice(0, NUM_RECENT_LIST_ITEMS);
    recentLocations = (tabData as Dictionary<unknown[]>)
      .recentLocations!.map(validatedNonNullableListItemData)
      .slice(0, NUM_RECENT_LIST_ITEMS);
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return {
    lastSearch,
    recentTreatments,
    recentLocations,
  };
}

export function saveTreatmentSearch(
  treatment: ListItemData | null,
  location: ListItemData | null,
  date: Date | null,
  time: { from: string; to: string } | null,
  languageCode: string
): void {
  const { recentTreatments, recentLocations } = readTreatmentTabData(
    languageCode
  );

  writeHomepageSearchDataLocalStorage(
    Tab.Treatment,
    {
      lastSearch: {
        treatment: validatedNullableListItemData(treatment),
        location: validatedNullableListItemData(location),
        date,
        time,
      },
      recentTreatments: insertedItemInList(treatment, recentTreatments),
      recentLocations: insertedItemInList(location, recentLocations),
    },
    languageCode
  );
}
/*
 * Salon Tab Data
 */

interface SalonTabData {
  lastSearch: {
    salon: ListItemData | null;
  };
  recentSalons: ListItemData[];
}

export function readSalonTabData(
  languageCode: string,
  defaultSalon: ListItemData | null = null
): SalonTabData {
  let recentSalons: ListItemData[] = [];

  try {
    const tabData = readHomepageSearchDataLocalStorage(languageCode)?.[
      Tab.Salon
    ];
    recentSalons = (tabData as Dictionary<unknown[]>)
      .recentSalons!.map(validatedNonNullableListItemData)
      .slice(0, NUM_RECENT_LIST_ITEMS);
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return {
    lastSearch: {
      salon: recentSalons.length > 0 ? recentSalons[0] : defaultSalon,
    },
    recentSalons,
  };
}

export function saveSalonTabSearch(
  salon: ListItemData,
  languageCode: string
): void {
  const { recentSalons } = readSalonTabData(languageCode);

  writeHomepageSearchDataLocalStorage(
    Tab.Salon,
    {
      recentSalons: insertedItemInList(salon, recentSalons),
    },
    languageCode
  );
}

/*
 * Data Transformation and Validation
 */
interface ListItemData {
  entityType: string;
  entityValue: string;
  name: string;
}

function isDuplicateItem(a: ListItemData, b: ListItemData): boolean {
  // we do not want to have multiple items with the same name in the history even when they might be different
  return (
    a.name === b.name ||
    (a.entityType === b.entityType && a.entityValue === b.entityValue)
  );
}

function insertedItemInList(
  item: ListItemData | null,
  itemList: ListItemData[]
): ListItemData[] {
  let sanitizedItem: ListItemData;

  try {
    sanitizedItem = validatedNonNullableListItemData(item);
  } catch (error) {
    return itemList;
  }

  return [
    sanitizedItem,
    ...itemList.filter(listItem => !isDuplicateItem(sanitizedItem, listItem)),
  ].slice(0, NUM_RECENT_LIST_ITEMS);
}

function validatedNullableDate(dateStr: unknown): Date | null {
  if (dateStr === null) {
    return null;
  }

  return validatedDate(dateStr);
}

function validatedDate(dateStr: unknown): Date {
  if (typeof dateStr !== 'string' || Number.isNaN(Date.parse(dateStr))) {
    throw new TypeError('invalid date');
  }

  return new Date(dateStr);
}

function validatedTime(time: unknown): { from: string; to: string } | null {
  if (!isDictionary(time)) {
    return null;
  }

  if (typeof time.from !== 'string' || typeof time.to !== 'string') {
    return null;
  }

  return time as { from: string; to: string };
}

function validatedNullableListItemData(data: unknown): ListItemData | null {
  if (data === null) {
    return null;
  }

  return validatedNonNullableListItemData(data);
}

function validatedNonNullableListItemData(data: unknown): ListItemData {
  if (
    !isDictionary(data) ||
    typeof data.entityType !== 'string' ||
    typeof data.entityValue !== 'string' ||
    typeof data.name !== 'string'
  ) {
    throw new TypeError(`invalid data : ${JSON.stringify(data)}`);
  }

  return {
    entityType: data.entityType,
    entityValue: data.entityValue,
    name: data.name,
  };
}

function isDictionary(data: unknown): data is Dictionary<unknown> {
  return (
    typeof data === 'object' && data !== null && data.constructor === Object
  );
}

/*
 * LocalStorage Access
 */

export function readHomepageSearchDataLocalStorage(
  languageCode: string
): Dictionary<unknown> | null {
  const jsonData = storage.local.getItem(LOCAL_STORAGE_KEY);

  // If the language currently in use is not the same as
  // the one used when the data was saved, then the data
  //  is not suitable for use.
  // (This implies that the user has changed languages.)
  //
  // Returning an empty object will result in the same
  // experience as for a new user with no saved data.

  if (jsonData) {
    if (languageCode !== jsonData.languageCode) {
      return {};
    }
  }

  // we are looking for an object literal, not an array
  if (!isDictionary(jsonData)) {
    return {};
  }

  return jsonData;
}

function writeHomepageSearchDataLocalStorage(
  key: string,
  data: unknown,
  languageCode: string
): void {
  storage.local.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...readHomepageSearchDataLocalStorage(languageCode),
      [key]: data,
      lastSearchTab: key,
      languageCode,
    })
  );
}
