import React from 'react';
import {
  ColorBloomBasePink600,
  ColorBloomTeal700,
} from '@treatwell/design-tokens';
import {
  IconDiscountOutline,
  IconHomebasedVenue,
  IconMobileVenue,
} from '@treatwell/ui';

import {
  IconWithContent,
  Props as IconWithContentProps,
} from 'js/components/IconWithContent';

export enum VenueTagType {
  MobileVenue = 'mobileVenue',
  YieldDiscount = 'yieldDiscount',
  HomeBased = 'homeBased',
}

type IconProps = {
  size: 16 | 24;
};

interface TypeDetails {
  icon: (props: IconProps) => React.ReactElement<React.ReactSVGElement>;
  colour: string;
}

const typesDetails: { [key in VenueTagType]: TypeDetails } = {
  [VenueTagType.MobileVenue]: {
    icon: IconMobileVenue,
    colour: ColorBloomBasePink600,
  },
  [VenueTagType.YieldDiscount]: {
    icon: IconDiscountOutline,
    colour: ColorBloomTeal700,
  },
  [VenueTagType.HomeBased]: {
    icon: IconHomebasedVenue,
    colour: ColorBloomBasePink600,
  },
};

export type Size = IconWithContentProps['size'];

export interface VenueTagData {
  type: VenueTagType;
  label: string;
  size?: Size;
  tooltipText?: string;
}

type Props = VenueTagData;

export function VenueTag(props: Props): React.ReactElement {
  const { label, size, tooltipText, type } = props;
  const typeDetails = typesDetails[type];

  return (
    <IconWithContent
      size={size}
      icon={typeDetails.icon}
      iconColour={typeDetails.colour}
      text={label}
      colour={typeDetails.colour}
      tooltip={tooltipText}
    />
  );
}
