import { BrowseResult } from 'js/pages/BrowsePage/BrowseResult';
import { EyecatcherType } from 'js/pages/BrowsePage/BrowseResult/Eyecatcher';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import {
  DiscountSet,
  SalesPriceDiscountType,
} from 'js/model/rainbow/SalesPriceDiscountType';
import { VenueListingResultOutput } from 'js/model/rainbow/browse-page/VenueListingResultOutput';
import { VenueListingSpecificationOutput } from 'js/model/rainbow/browse-page/VenueListingSpecificationOutput';
import { LocationPoint } from 'js/model/rainbow/LocationPoint';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { createVenueTags } from 'js/model/view/venue-tags';
import { VenueTagData } from 'js/components/VenueTag/VenueTag';
import {
  formatAddress,
  formatDistance,
  formatDuration,
} from 'js/helpers/formatters';
import { haversine, Units } from 'js/helpers/haversine';
import { PropsType } from 'js/types/react';
import * as i18n from 'js/helpers/i18n';
import { createBestTreatmentsBadgeLabel } from 'js/helpers/btt';
import { createOpeningHoursViewModel } from 'js/components/VenueOpeningTimes';
import { VenueRating } from 'js/model/rainbow/venue/VenueOutput';
import { choiceFormatter } from 'js/helpers/choice-formatter';
import { generateVenuePageUri } from 'js/pages/VenuePage/uri';
import { PricingDisplayType } from 'js/model/rainbow/venue/VenueMenuItemOutput';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { extractVectorImageUri } from 'js/model/rainbow/browse-page/AccoladeOutput';
import { DurationRange } from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';

export function createBrowseResultViewModel(
  listingSpecification: VenueListingSpecificationOutput,
  venueListingResult: VenueListingResultOutput,
  cms: CmsBrowsePage,
  channelOutput: ChannelOutput
): PropsType<BrowseResult> {
  const data = venueListingResult.data;
  const listingLocationPoint = listingSpecification.location
    ? listingSpecification.location.point
    : undefined;
  const distance = formatVenueDistance(
    cms,
    channelOutput,
    listingLocationPoint,
    data.location.point
  );
  const venuePageUri = getVenuePageUri(
    venueListingResult,
    listingSpecification
  );
  const quickviewLabels = {
    openLabel: cms.page.browse.results['quickview-open'],
    closeLabel: cms.page.browse.results['quickview-close'],
    buttonLabel: cms.venue['go-to-venue-button'],
  };

  const openingHours = createOpeningHoursViewModel(
    venueListingResult.data.openingHours,
    cms.venue['opening-hours'].closed,
    cms.common['day-names'],
    cms.common.calendar['day-names-short'],
    channelOutput.country.countryCode
  );

  const addressPostalCodePosition =
    channelOutput.code === 'WHN_GB' || channelOutput.code === 'WHN_IE'
      ? 'newline'
      : 'prefix';

  const treatmentBadges = data.treatmentBadges;

  return {
    name: data.name,
    shortAddress: data.location.tree.name,
    distance,
    openingHours,
    rating: data.rating,
    reviewCountTemplate: cms.venue['reviews-template'],
    openMapText: cms.page.browse.results['open-map'],
    images: data.images.slice(0, 5),
    eyecatcher: createEyecatcherViewModel(venueListingResult, cms),
    tags: createVenueTagViewModel(venueListingResult, cms),
    menuHighlights: data.menuHighlights.map(menuHighlight =>
      createMenuHighlightViewModel(menuHighlight, cms)
    ),
    venuePageUri,
    venueId: data.id,
    description: data.description,
    quickviewLabels,
    mapPopup: {
      mapApiKey: cms.page.maps['google-map']['api-key'],
      geoCode: data.location.point,
      mapCenter: data.location.map,
      addressLines: formatAddress(
        data.location.address.addressLines,
        data.location.address.postalCode,
        addressPostalCodePosition
      ),
      ctaText: cms.venue['go-to-venue-button'],
    },
    bestTreatmentsBadgeLabel: createBestTreatmentsBadgeLabel(
      treatmentBadges.map(treatmentBadge => treatmentBadge.treatmentName),
      cms.common.labels['btt-badge-singular'],
      cms.common.labels['btt-badge-plural']
    ),
    bestTreatmentsBadgeTooltip: cms.common.labels['btt-badge-tooltip'],
    bestTreatmentsTrackingIds: treatmentBadges.map(
      treatmentBadge => treatmentBadge.treatmentId
    ),
    channelOutput,
    cmsBrowsePage: cms,
    isNewVenue: data.newVenue,
    accolades: data.accolades,
  };
}

export function createRatingText(
  reviewCountTemplate: string,
  venueRating: VenueRating
): string {
  return choiceFormatter(reviewCountTemplate, venueRating.count);
}

export function createMenuHighlightViewModel(
  venueMenuItem: VenueMenuItemTypeOutput,
  cms: CmsBrowsePage
): {
  dataOfferId: string;
  name: string;
  duration?: string;
  numGuests?: number;
  priceRangeOutput: PriceRangeOutput;
  pricingDisplayType: PricingDisplayType;
  durationRange: DurationRange;
} {
  const duration = createDurationString(venueMenuItem.data.durationRange, cms);

  return {
    dataOfferId: venueMenuItem.data.id,
    name: venueMenuItem.data.name !== undefined ? venueMenuItem.data.name : '',
    duration,
    numGuests: venueMenuItem.data.guests,
    priceRangeOutput: venueMenuItem.data.priceRange,
    pricingDisplayType: venueMenuItem.data.pricingDisplayType,
    durationRange: venueMenuItem.data.durationRange,
  };
}

function createDurationString(
  durationRange: { minDurationMinutes: number; maxDurationMinutes: number },
  cms: CmsBrowsePage
): string {
  const minDuration = durationRange.minDurationMinutes;
  const maxDuration = durationRange.maxDurationMinutes;
  const formattedMinDuration = formatDuration(
    minDuration,
    cms.venue.menu.labels
  );

  // TODO what is the purpose of range and durationMix in the API?
  if (minDuration !== maxDuration) {
    const formattedMaxDuration = formatDuration(
      maxDuration,
      cms.venue.menu.labels
    );
    return `${formattedMinDuration} - ${formattedMaxDuration}`;
  }
  return formattedMinDuration;
}

function createEyecatcherViewModel(
  venueListingResult: VenueListingResultOutput,
  cms: CmsBrowsePage
): EyecatcherType | undefined {
  const featuredAccolade = venueListingResult.data.accolades.find(
    accolade => accolade.featured
  );

  if (featuredAccolade && featuredAccolade.images) {
    const accoladeTooltipCms =
      cms.entities.accolade[featuredAccolade.tooltipTextCMSKey];

    return {
      imageUri: extractVectorImageUri(featuredAccolade),
      tooltipText:
        accoladeTooltipCms !== undefined ? accoladeTooltipCms.description : '', // TODO allow for undefined tooltipText return
    };
  }
  if (venueListingResult.data.newVenue) {
    return cms.page.browse.results.new;
  }

  return undefined;
}

export function venueMenuItemsDiscountSet(
  items: VenueMenuItemTypeOutput[]
): DiscountSet {
  return items.reduce(
    (acc: DiscountSet, menuHighlight: VenueMenuItemTypeOutput): DiscountSet => {
      menuHighlight.data.priceRange.yieldDiscountTypes.forEach(
        (discountType: SalesPriceDiscountType) => {
          acc[discountType] = true;
        }
      );

      return acc;
    },
    {}
  );
}

function createVenueTagViewModel(
  venueListingResultOutput: VenueListingResultOutput,
  cms: CmsBrowsePage
): VenueTagData[] {
  const discountSet = venueMenuItemsDiscountSet(
    venueListingResultOutput.data.menuHighlights
  );

  return createVenueTags(
    discountSet,
    venueListingResultOutput.data.type,
    venueListingResultOutput.data.subTypes,
    cms.venue
  );
}

function formatVenueDistance(
  cms: CmsBrowsePage,
  channel: ChannelOutput,
  listingLocation: LocationPoint | undefined,
  venueLocation: LocationPoint
): string {
  if (!listingLocation) {
    return '';
  }

  const units = channel.distanceUnit === 'mile' ? Units.MILE : Units.KM;
  const distance = haversine(listingLocation, venueLocation, units);
  const formattedDistance = formatDistance(distance, channel);

  return i18n.substitute(cms.page.browse.results.distance, formattedDistance);
}

export function getVenuePageUri(
  result: VenueListingResultOutput,
  listingSpecification: VenueListingSpecificationOutput
): string {
  return generateVenuePageUri(result.data.uri.desktopUri!, {
    serviceIds: result.data.menuHighlights.map(service => service.data.id),
    date: listingSpecification.date && listingSpecification.date.from,
    startTime: listingSpecification.startHours,
    endTime: listingSpecification.endHours,
    treatmentIds: (listingSpecification.treatmentCategories || []).map(
      treatment => treatment.id
    ),
    treatmentTypeId:
      listingSpecification.treatmentCategoryGroup &&
      listingSpecification.treatmentCategoryGroup.id,
  });
}
