import * as React from 'react';

interface Props {
  positioningClassName?: string;
}

export function DateIcon({ positioningClassName }: Props): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={positioningClassName}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          stroke="var(--color-grey-70)"
          strokeWidth="2"
          d="M11 5h5m3 0c.552 0 1 .445 1 1v14c0 .552-.445 1-1 1H5c-.552 0-1-.445-1-1V6a1 1 0 0 1 1.01-1H8"
        />
        <path
          fill="var(--color-grey-70)"
          d="M7 12h2v2H7zM11 12h2v2h-2zM11 16h2v2h-2zM15 12h2v2h-2zM7 16h2v2H7zM7 2h2v6H7zM15 2h2v6h-2z"
        />
      </g>
    </svg>
  );
}
