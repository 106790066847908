import React from 'react';
import clsx from 'clsx';
import { DotIndicators } from 'js/components/generic/DotIndicators';
import { getWindowDeviceType } from 'js/helpers/dom';
import {
  GestureCardinalDirection,
  TouchGesture,
} from 'js/helpers/TouchGesture';
import styles from './PropositionSlotsCarousel.module.css';

interface Props {
  heading: string;
  activeSlot?: number;
  slots: {
    imageUri: string;
    heading: string;
    body: string;
  }[];
}

export function PropositionSlotsCarousel(props: Props): React.ReactElement {
  const carouselNodeReference = React.useRef<HTMLDivElement>(null);

  const slotNodeReference = React.useRef<HTMLDivElement>(null);

  let currentTouch: TouchGesture | undefined;

  const [activeSlot, setActiveSlot] = React.useState<number>(
    props.activeSlot || 0
  );
  const [carouselOffset, setCarouselOffset] = React.useState<string>('0px');
  const [isMobile, setIsMobile] = React.useState<boolean>(
    () => getWindowDeviceType() === 'mobile'
  );
  const [animating, setIsAnimating] = React.useState<boolean>(false);

  React.useEffect(() => {
    moveCarouselToSlot(activeSlot);
    let animationTimeoutId = 0;

    // Only animate after didMount + one render cycle
    animationTimeoutId = window.requestAnimationFrame(() => {
      setIsAnimating(true);
    });

    window.addEventListener('resize', handleWindowResize);
    return () => clearTimeout(animationTimeoutId);
  }, []);

  const onCarouselTouchStart = (event: React.TouchEvent) => {
    if (event.targetTouches.length === 1) {
      const touch = event.targetTouches.item(0);

      currentTouch = new TouchGesture(touch, 0);
    }
  };

  const onCarouselTouchMove = (event: React.TouchEvent) => {
    if (currentTouch === undefined) {
      return;
    }

    currentTouch.update(event.targetTouches);
  };

  const onCarouselTouchEnd = (event: React.TouchEvent): void => {
    if (currentTouch === undefined) {
      return;
    }

    currentTouch.update(event.changedTouches);

    switch (currentTouch.getCardinalDirection()) {
      case GestureCardinalDirection.West:
        moveCarouselToSlot(activeSlot - 1);

        break;

      case GestureCardinalDirection.East:
        moveCarouselToSlot(activeSlot + 1);

        break;
    }
  };

  const handleWindowResize = (): void => {
    setIsMobile(getWindowDeviceType() === 'mobile');
    moveCarouselToSlot(activeSlot);
  };

  const moveCarouselToSlot = (slotIndex: number): void => {
    let index = slotIndex;

    if (
      !carouselNodeReference.current ||
      !slotNodeReference.current ||
      !isMobile
    ) {
      return;
    }

    const { slots } = props;

    if (index >= slots.length) {
      index = slots.length - 1;
    } else if (index < 0) {
      index = 0;
    }

    const carouselWidth = carouselNodeReference.current.offsetWidth;
    const slotWidth = slotNodeReference.current.offsetWidth;
    const carouselOffset = (carouselWidth - slotWidth) / 2 - slotWidth * index;

    setActiveSlot(index);
    setCarouselOffset(`${carouselOffset}px`);
  };

  const { slots, heading } = props;

  return (
    <>
      <h2 className={styles.heading}>{heading}</h2>

      <div
        className={styles.carousel}
        ref={carouselNodeReference}
        onTouchStart={onCarouselTouchStart}
        onTouchMove={onCarouselTouchMove}
        onTouchEnd={onCarouselTouchEnd}
      >
        {slots.map((slot, slotIndex) => {
          const ref = slotIndex === 0 ? slotNodeReference : null;
          const style =
            slotIndex === 0 && isMobile
              ? { marginLeft: carouselOffset }
              : undefined;
          const className = clsx(styles.slot, {
            [styles.active]: slotIndex === activeSlot,
            [styles.animating]: animating,
          });

          return (
            <div
              className={className}
              ref={ref}
              style={style}
              key={slotIndex}
              onClick={() => moveCarouselToSlot(slotIndex)}
            >
              <img src={slot.imageUri} alt="" />
              <h3>{slot.heading}</h3>
              <p>{slot.body}</p>
            </div>
          );
        })}
      </div>

      <div className={styles.dotIndicatorsWrapper}>
        <DotIndicators
          indicatorCount={slots.length}
          activeIndicator={activeSlot}
        />
      </div>
    </>
  );
}
