import React from 'react';

interface Props {
  positioningClassname?: string;
  fillColour: string;
  outlineColour: string;
  onClick?: () => void;
}

export class ArrowSvg extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={this.props.positioningClassname}
        width="24"
        height="12"
        viewBox="0 0 24 12"
        version="1.1"
        onClick={this.props.onClick}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="
              translate(12.000000, 7.410000)
              rotate(180.000000)
              translate(-12.000000, -7.410000)
              translate(0.000000, 1.590000)
            "
          >
            <polygon
              transform="translate(0.000000, -1.0)"
              fill={this.props.fillColour}
              fillRule="nonzero"
              points="12 0.41 24 12.41 0 12.41"
            />
            <polygon
              fill={this.props.outlineColour}
              fillRule="nonzero"
              points="12 1.85 24 13.85 0 13.85"
            />
            <rect
              stroke="#FFFFFF"
              fill="#D8D8D8"
              x="0.5"
              y="13.91"
              width="23"
              height="1"
            />
          </g>
        </g>
      </svg>
    );
  }
}
