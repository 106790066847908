import { PriceRange } from 'js/helpers/price/PriceRange';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { PricingDisplayType } from 'js/model/rainbow/venue/VenueMenuItemOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { ServicePriceRangeCms } from './ServicePriceRangeCms';
import { CurrencyFormat } from './CurrencyFormat';
import {
  CurrencySymbolString,
  getCurrencySymbolFromCode,
} from './currencySymbol';

/**
 * Converts any numerical value of type number or string into a numerical string with 2 fraction digits
 * 3 --> 3.00
 * Returns '0.00' for non numerical input types
 * @param amount
 */
export function amountToString(amount: number | string): string {
  let out = '';
  if (typeof amount === 'number' && !isNaN(amount)) {
    out = amount.toFixed(2);
  }
  if (typeof amount === 'string') {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount)) {
      out = parsedAmount.toFixed(2);
    }
  }
  return out;
}

/**
 * Formats numerical price value (passed in as string or number) with 2 fraction digits and
 * decimal separator + currency symbol as defined in ChannelOutput
 * If fraction digits are '00' they are omitted unless forceFraction flag is true.
 * @param amount
 * @param channelOutput
 * @param forceFraction
 */
export function formatPrice(
  amount: number | string,
  channelOutput: ChannelOutput,
  forceFraction = false
): string {
  const amountLocal = amountToString(amount);
  if (amountLocal === '') {
    return '';
  }
  const amountSplit = amountLocal.split('.');
  const fraction = amountSplit[1];
  const showFraction =
    (fraction !== undefined && fraction !== '' && fraction !== '00') ||
    forceFraction;
  const priceInteger = amountSplit[0];
  const separator = channelOutput.decimalSeparator || '.';
  const priceFraction = showFraction ? amountSplit[1] : '';

  let p = priceInteger;
  if (showFraction) {
    p += separator + priceFraction;
  }
  return formatPriceString(p, channelOutput);
}

/**
 * Formats a price string as per ChannelOutput definition and decorates it with currency symbol/code
 * @param priceString
 * @param channelOutput
 */
export function formatPriceString(
  priceString: string,
  channelOutput: ChannelOutput
): string {
  const currencySymbol: string = getCurrencySymbolFromCode(
    channelOutput.currencyCode as CurrencySymbolString
  );
  switch (channelOutput.currencyFormat) {
    case CurrencyFormat.SYMBOL_START:
      return `${currencySymbol}${priceString}`;
    case CurrencyFormat.SYMBOL_END:
      return `${priceString}${currencySymbol}`;
    case CurrencyFormat.CODE_START:
      return `${channelOutput.currencyCode}${priceString}`;
    case CurrencyFormat.CODE_END:
      return `${priceString}${channelOutput.currencyCode}`;
    case CurrencyFormat.WHITESPACE_SYMBOL:
      return `${priceString}\u00A0${currencySymbol}`;
    case CurrencyFormat.SYMBOL_WHITESPACE:
      return `${currencySymbol}\u00A0${priceString}`;
    case CurrencyFormat.WHITESPACE_CODE:
      return `${priceString}\u00A0${channelOutput.currencyCode}`;
    case CurrencyFormat.CODE_WHITESPACE:
      return `${channelOutput.currencyCode}\u00A0${priceString}`;
    default:
      console.warn(
        `Unknown channel currency symbol "${channelOutput.currencyFormat}"`
      );
      return `${currencySymbol}${priceString}`;
  }
}

/**
 * There is currently only one possible price prefix string ('from')
 * which should be returned if the range flag on PriceRange is true.
 * Otherwise the price prefix is an empty string.
 * @param priceRange
 * @param servicePriceRangeCms
 */
export function getPricePrefix(
  priceRange: PriceRange,
  servicePriceRangeCms: ServicePriceRangeCms
): string {
  let prefix = '';
  if (
    priceRange.isRange() &&
    servicePriceRangeCms &&
    Object.prototype.hasOwnProperty.call(servicePriceRangeCms, 'from')
  ) {
    prefix = servicePriceRangeCms.from;
  }
  return prefix;
}

/**
 * There is currently only one possible price suffix ('per person').
 * @param servicePriceRangeCms
 * @param pricingDisplayType
 */
export function getPriceSuffix(
  servicePriceRangeCms: ServicePriceRangeCms,
  pricingDisplayType: PricingDisplayType
): string {
  if (!servicePriceRangeCms.perPerson) {
    return '';
  }
  return pricingDisplayType === PricingDisplayType.perPerson
    ? servicePriceRangeCms.perPerson
    : '';
}

export function getServicePriceRangeCms(
  i18n: (path: string) => string
): ServicePriceRangeCms {
  const cms = ServicePriceRangeCms.create();

  try {
    cms.perPerson = i18n('venue.menu.labels.per-person') || '';
    cms.saveUpTo = i18n('page.venue.menu.labels.save-up-to') || '';
    cms.save = i18n('page.venue.menu.labels.save') || '';
    cms.from = i18n('page.venue.menu.labels.from') || '';
  } catch (error) {
    // console.warn(error);
  }

  return cms;
}

export function cmsBrowsePageToServicePriceRangeCms(
  cmsBrowsePage: CmsBrowsePage
): ServicePriceRangeCms {
  const cms = ServicePriceRangeCms.create();

  try {
    cms.perPerson = cmsBrowsePage.venue.menu.labels['per-person'];
    cms.saveUpTo = cmsBrowsePage.venue.menu.labels['save-up-to'];
    cms.save = cmsBrowsePage.venue.menu.labels.save;
    cms.from = cmsBrowsePage.venue.menu.labels.from;
  } catch (error) {
    // console.warn(error);
  }

  return cms;
}
