// A version of Button which permanently is set to loading after onClick, useful for buttons which change the page
import React from 'react';
import { Button } from './Button';

interface State {
  loading: boolean;
}

interface Props extends React.ComponentProps<typeof Button> {
  disableLoading?: boolean;
  staticLoading?: boolean;
}

export class LoadingButton extends React.Component<Props, State> {
  public static readonly defaultProps = Button.defaultProps;

  public state: State = {
    loading: this.props.staticLoading || false,
  };

  public componentDidUpdate(): void {
    if (this.props.disableLoading && this.state.loading) {
      this.setState({
        loading: false,
      });
    }
  }

  private onClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    const loading = !this.props.disableLoading;

    this.setState({ loading }, () => {
      if (this.props.onClick) {
        this.props.onClick(event);
      }
    });
  };

  public render(): React.ReactNode {
    return (
      <Button
        {...this.props}
        onClick={this.onClick}
        loading={this.state.loading}
      />
    );
  }
}
