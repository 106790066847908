export class ServicePriceRangeCms {
  public perPerson: string | undefined = '';

  public saveUpTo = '';

  public save = '';

  public from = '';

  public static create(): ServicePriceRangeCms {
    return new ServicePriceRangeCms();
  }
}
