import { fetchCached, RequestData } from 'js/helpers/service';
import debounce from 'p-debounce';

import {
  Result,
  SearchOutput,
  Source,
} from 'js/model/rainbow/search-unified/SearchOutput';
import {
  makeItemResult,
  ItemResult,
  ItemResultType,
} from 'js/components/controls/search-item-result';
import { TreatmentCategoryOutput } from 'js/model/rainbow/TreatmentCategoryOutput';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';

export enum ResultValueType {
  Id,
  NormalisedName,
}

/*
  Remove duplicate results.

  Duplicates can occur because of misconfigured data.
  For example two treatments with the same name, or a treatment
  alias with the same name as a treatment.

  Ideally this should not happen, but experiences shows that it does.
*/
function dedupeResults(results: Result[]): Result[] {
  const dedupedResults: Result[] = [];

  for (const result of results) {
    if (
      dedupedResults.some(
        existingResult =>
          existingResult.type === result.type &&
          existingResult.data &&
          result.data &&
          existingResult.data.name === result.data.name
      )
    ) {
      console.warn('Duplicate search result', result);
    } else {
      dedupedResults.push(result);
    }
  }

  return dedupedResults;
}

async function fetchSearchResults(
  state: RequestData,
  sources: string,
  text: string,
  resultValueType: ResultValueType,
  size = 15
): Promise<ItemResult[]> {
  const path =
    '/api/v1/search/unified?' +
    `sources=${encodeURIComponent(sources)}&` +
    `text=${encodeURIComponent(text)}&` +
    `size=${encodeURIComponent(String(size))}`;

  try {
    const data = await fetchCached<SearchOutput>(state, path);
    if (!data || !data.results) {
      return [];
    }

    return dedupeResults(data.results)
      .map(item => createItemResult(item, resultValueType))
      .filter((item): item is ItemResult => item !== undefined);
  } catch (e) {
    console.warn(e);
    return [];
  }
}

function createItemResult(
  result: Result,
  resultValueType: ResultValueType
): ItemResult | undefined {
  let value;

  switch (result.type) {
    case Source.ExternalLocation:
      return makeItemResult(
        'search',
        ItemResultType.ExternalLocation,
        result.data.reference,
        result.data.name
      );

    case Source.Location:
      value =
        resultValueType === ResultValueType.Id
          ? String(result.data.id)
          : result.data.normalisedName;
      return makeItemResult(
        'search',
        ItemResultType.Location,
        value,
        result.data.name
      );

    case Source.PostalArea:
      value =
        resultValueType === ResultValueType.Id
          ? String(result.data.id)
          : result.data.normalisedName;
      return makeItemResult(
        'search',
        ItemResultType.PostalArea,
        value,
        result.data.name
      );

    case Source.PostalReference:
      value =
        resultValueType === ResultValueType.Id
          ? String(result.data.id)
          : result.data.normalisedName;
      return makeItemResult(
        'search',
        ItemResultType.PostalReference,
        value,
        result.data.name
      );

    case Source.TreatmentCategory:
      return makeTreatmentCategoryItemResultType(result.data, resultValueType);

    case Source.VenueType:
      return makeVenueTypeItemResultType(result.data, resultValueType);

    case Source.Venue:
      return makeItemResult(
        'search',
        ItemResultType.Venue,
        result.data.normalisedName,
        result.data.name
      );

    default:
      // Compile failure if cases are not exhaustive.
      // TODO create an "assertNever" function

      // No compile failure if result.type is missing from the Result discriminated union.
      // So at least log a message.
      console.warn('Unrecognised result type', result);

      return result;
  }
}

function makeTreatmentCategoryItemResultType(
  data: TreatmentCategoryOutput,
  resultValueType: ResultValueType
): ItemResult | undefined {
  if (data.id) {
    return makeTreatmentsResultType(data, resultValueType);
  }
  return makeTreatmentTypeResultType(data, resultValueType);
}

function makeVenueTypeItemResultType(
  data: VenueTypeOutput,
  resultValueType: ResultValueType
): ItemResult | undefined {
  if (data.id) {
    return makeVenueTypeResultType(data, resultValueType);
  }
}

function makeTreatmentsResultType(
  data: TreatmentCategoryOutput,
  resultValueType: ResultValueType
): ItemResult | undefined {
  if (data.normalisedName === undefined || data.name === undefined) {
    return undefined;
  }

  const value =
    resultValueType === ResultValueType.Id
      ? String(data.id)
      : data.normalisedName;
  return makeItemResult(
    'search',
    ItemResultType.Treatments,
    value,
    data.name,
    undefined,
    data.aliasId
  );
}

function makeVenueTypeResultType(
  data: TreatmentCategoryOutput,
  resultValueType: ResultValueType
): ItemResult | undefined {
  if (data.normalisedName === undefined || data.name === undefined) {
    return undefined;
  }

  const value =
    resultValueType === ResultValueType.Id
      ? String(data.id)
      : data.normalisedName;
  return makeItemResult(
    'search',
    ItemResultType.VenueType,
    value,
    data.name,
    undefined,
    data.aliasId
  );
}

function makeTreatmentTypeResultType(
  data: TreatmentCategoryOutput,
  resultValueType: ResultValueType
): ItemResult | undefined {
  if (
    data.treatmentGroup === undefined ||
    data.treatmentGroup.normalisedName === undefined ||
    data.treatmentGroup.name === undefined
  ) {
    return undefined;
  }

  const value =
    resultValueType === ResultValueType.Id
      ? String(data.treatmentGroup.id)
      : data.treatmentGroup.normalisedName;
  return makeItemResult(
    'search',
    ItemResultType.TreatmentType,
    value,
    data.treatmentGroup.name,
    undefined,
    data.aliasId
  );
}

type SearchFunc = (
  state: RequestData,
  text: string,
  size?: number
) => Promise<ItemResult[]>;

function fetchSearchResultsDebounced(
  sources: string,
  debounceMs: number,
  resultValueType: ResultValueType = ResultValueType.Id
): SearchFunc {
  async function fetch(
    state: RequestData,
    text: string,
    size?: number
  ): Promise<ItemResult[]> {
    return await fetchSearchResults(
      state,
      sources,
      text,
      resultValueType,
      size
    );
  }

  return debounce(fetch, debounceMs);
}

export function fetchTreatmentSearchResultsDebounced(
  debounceMs: number,
  resultValueType: ResultValueType = ResultValueType.Id
): SearchFunc {
  return fetchSearchResultsDebounced(
    'treatment_category,venue_type',
    debounceMs,
    resultValueType
  );
}

export function fetchLocationSearchResultsDebounced(
  debounceMs: number,
  resultValueType: ResultValueType = ResultValueType.Id
): SearchFunc {
  return fetchSearchResultsDebounced('location', debounceMs, resultValueType);
}

export function fetchSalonNameSearchResultsDebounced(
  debounceMs: number
): SearchFunc {
  return fetchSearchResultsDebounced('venue', debounceMs);
}
