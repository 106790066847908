import { useNotify } from '@treatwell/ui';
import { StateData } from 'js/model/rainbow/StateData';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useError = (pageData: StateData) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();

  useEffect(() => {
    const error = pageData.pageParameters?.error;
    if (error) {
      let errorMessage;
      switch (error[0]) {
        case 'referral_failure':
        default:
          errorMessage = t('referral.otpInputModal.error.generic');
      }
      notifyError(errorMessage);
      const url = new URL(window.location.href);
      url.searchParams.delete('error');
      window.history.replaceState('', '', url.toString());
    }
  }, [pageData]);
};
