import { RecommendedVenueResponseOutput } from 'js/model/rainbow/RecommendedVenueResponseOutput';
import { SalesPriceDiscountType } from 'js/model/rainbow/SalesPriceDiscountType';
import { CmsHomePage } from 'js/model/cms/cms-home-page';
import { Props as RecommendedVenuesProps } from 'js/pages/HomePage/RecommendedVenues/RecommendedVenues';
import { LineColour } from 'js/components/VenueCard/VenueCard';

type RecommendedVenuesViewModel = RecommendedVenuesProps;

function getDiscountText(
  discountTypes: string[],
  cms: CmsHomePage
): string | undefined {
  if (!discountTypes.length) {
    return;
  }

  if (
    discountTypes.includes(SalesPriceDiscountType.Jit) &&
    discountTypes.includes(SalesPriceDiscountType.Offpeak)
  ) {
    return cms.venue.menu['jit-and-offpeak-discount-active'];
  }
  if (discountTypes.includes(SalesPriceDiscountType.Jit)) {
    return cms.venue.menu['jit-discount-active'];
  }
  if (discountTypes.includes(SalesPriceDiscountType.Offpeak)) {
    return cms.venue.menu['offpeak-discount-active'];
  }
}

export function createRecommendedVenuesViewModel(
  recommendedVenues: RecommendedVenueResponseOutput['recommendedVenues'],
  cms: CmsHomePage,
  generateUri: (
    pageType: string,
    options: { normalisedName: string }
  ) => string,
  countryCode: string
): RecommendedVenuesViewModel {
  const sectionHeader = cms.page.home['recommended-venues'].header;
  const lineColours = [
    LineColour.Pink,
    LineColour.Orange,
    LineColour.Green,
    LineColour.Teal,
  ];

  const venuesModel = recommendedVenues
    .slice(0, 4)
    .map((recommendedVenue, index) => {
      const discountText = getDiscountText(recommendedVenue.discountTypes, cms);

      return {
        id: recommendedVenue.venue.id,
        venuePageUri: generateUri('venue', {
          normalisedName: recommendedVenue.venue.normalisedName,
        }),
        imageUri: recommendedVenue.venue.primaryImage.uris['360x240'],
        lineColour: lineColours[index],
        name: recommendedVenue.venue.name,
        ratingValue: recommendedVenue.venue.rating.displayAverage,
        ratingCount: recommendedVenue.venue.rating.count,
        location: recommendedVenue.venue.location.tree.name,
        discountText,
      };
    });

  return {
    sectionHeader,
    venues: venuesModel,
    countryCode,
  };
}
