import * as React from 'react';

const COLOURS = {
  default: 'var(--color-grey-70)',
  error: '#c80f00',
};

interface Props {
  error?: boolean;
  positioningClassName?: string;
}

export function LocationIcon({
  error,
  positioningClassName,
}: Props): React.ReactElement {
  const colour = error ? COLOURS.error : COLOURS.default;
  return (
    <svg
      className={positioningClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          stroke={colour}
          strokeWidth="2"
          d="M12 21c1.5 0 7-7 7-11s-3-7-7-7-7 3-7 7 5.5 11 7 11z"
        />
        <circle cx="12" cy="10" r="2" fill={colour} />
      </g>
    </svg>
  );
}
