import * as track from './tracking';

export const VENUE_TAB = track.VENUE_TAB;
const VENUE_FIELD = 'venue_field';

export function salonInputFocus(): void {
  track.fieldFocus(VENUE_TAB, VENUE_FIELD);
}

export function salonInputBlur(): void {
  track.fieldBlur(VENUE_TAB, VENUE_FIELD);
}

export function salonInputClear(): void {
  track.fieldClear(VENUE_TAB, VENUE_FIELD);
}

export function salonInputType(value: string, resultCount: number): void {
  track.fieldType(VENUE_TAB, VENUE_FIELD, value, resultCount);
}

export function salonInputSelect(
  value: string,
  treatmentPosition: number
): void {
  track.fieldSelectWithPosition(
    VENUE_TAB,
    VENUE_FIELD,
    value,
    treatmentPosition
  );
}

// Search Button
export function searchButtonSuccess(): void {
  track.fieldSelect(VENUE_TAB, track.SEARCH_BUTTON, 'success');
}

export function searchButtonFail(reason: string): void {
  track.fieldSelect(VENUE_TAB, track.SEARCH_BUTTON, reason);
}
