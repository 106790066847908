/*
 * Get the absolute position of the top left corner of an element relative
 * to the <body> element.
 */
export function absoluteOffset(element: HTMLElement): { x: number; y: number } {
  const elementRect = element.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  const x = elementRect.left - bodyRect.left;
  const y = elementRect.top - bodyRect.top;

  return { x, y };
}
