import * as React from 'react';

interface Props {
  positioningClassName?: string;
}

export function TimeIcon({ positioningClassName }: Props): React.ReactElement {
  return (
    <svg
      className={positioningClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <circle
          cx="12"
          cy="12"
          r="9"
          stroke="var(--color-grey-70)"
          strokeWidth="2"
        />
        <path stroke="var(--color-grey-70)" strokeWidth="2" d="M12 6v6l3 3" />
      </g>
    </svg>
  );
}
