import { isProductionEnvironment } from 'js/helpers/environment';

export const salesforceBotConfig = isProductionEnvironment
  ? {
      init: {
        orgId: '00D4L000000iBHI',
        eswConfigDevName: 'TW_Marketplace_Chat_Web',
        siteUrl:
          'https://treatwell2021.my.site.com/ESWTWMarketplaceChatWe1712743691428',
        scrt2URL: 'https://treatwell2021.my.salesforce-scrt.com',
      },
      bootstrapJsSrc:
        'https://treatwell2021.my.site.com/ESWTWMarketplaceChatWe1712743691428/assets/js/bootstrap.min.js',
    }
  : {
      init: {
        orgId: '00DUE000000MNvd',
        eswConfigDevName: 'TW_Marketplace_Chat_Web',
        siteUrl:
          'https://treatwell2021--partial.sandbox.my.site.com/ESWTWMarketplaceChatWe1711466057369',
        scrt2URL:
          'https://treatwell2021--partial.sandbox.my.salesforce-scrt.com',
      },
      bootstrapJsSrc:
        'https://treatwell2021--partial.sandbox.my.site.com/ESWTWMarketplaceChatWe1711466057369/assets/js/bootstrap.js',
    };
