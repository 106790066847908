interface Parts {
  serviceIds: string[];
  date?: string;
  startTime?: number; // hours
  endTime?: number; // hours
  treatmentIds: number[];
  treatmentTypeId?: number;
}

export const PARAM_SERVICES = 'serviceIds';
export const PARAM_DATE = 'date';
export const PARAM_START_TIME = 'timeFrom';
export const PARAM_END_TIME = 'timeTo';
export const PARAM_TREATMENTS = 't';
export const PARAM_TREATMENT_TYPE = 'tt';

function fourDigitHours(hours: number): string {
  return `0${hours}00`.slice(-4);
}

export function generateVenuePageUri(baseUri: string, parts: Parts): string {
  const searchParams: string[] = [];

  searchParams.push(`${PARAM_SERVICES}=${parts.serviceIds.join(',')}`);

  if (parts.date) {
    searchParams.push(`${PARAM_DATE}=${parts.date}`);
  }

  // time range
  if (parts.startTime) {
    searchParams.push(`${PARAM_START_TIME}=${fourDigitHours(parts.startTime)}`);
  }
  if (parts.endTime) {
    searchParams.push(`${PARAM_END_TIME}=${fourDigitHours(parts.endTime)}`);
  }

  if (parts.treatmentIds.length > 0) {
    searchParams.push(`${PARAM_TREATMENTS}=${parts.treatmentIds.join(',')}`);
  }

  if (parts.treatmentTypeId) {
    searchParams.push(`${PARAM_TREATMENT_TYPE}=${parts.treatmentTypeId}`);
  }

  // create fragment (or not)
  const fragment = searchParams.length > 0 ? `?${searchParams.join('&')}` : '';

  return baseUri + fragment;
}
