import React from 'react';

import { Button, ButtonColour, ButtonSize } from 'js/components/Button';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { Viewport, useDevice } from '@treatwell/ui';
import styles from './ViewModeToggle.module.css';
import { Category, Property, trackViewModeToggle } from './tracking/tracking';

export enum ViewMode {
  Hybrid = 'hybrid',
  List = 'list',
  Map = 'map',
}

export function viewModeForValue(value: string): ViewMode | undefined {
  return Object.values(ViewMode).find(mode => mode === value);
}

type CmsKey =
  | 'view-mode-hybrid'
  | 'view-mode-list'
  | 'view-mode-list-from-hybrid'
  | 'view-mode-map';

const iconCross = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M17.793 5.293l1.414 1.414L13.914 12l5.293 5.293-1.414 1.414-5.293-5.293-5.293 5.293-1.414-1.414L11.086 12 5.793 6.707l1.414-1.414 5.293 5.293 5.293-5.293z"
    />
  </svg>
);

const iconList = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(3 4)">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M5 1h12v2H5V1zm0 6h12v2H5V7zm0 6h12v2H5v-2zM1.5.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
      />
    </g>
  </svg>
);

const iconMap = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M21.759 17.807c.123.553 0 1.112-.337 1.532-.337.42-.855.661-1.422.661H4a1.805 1.805 0 0 1-1.422-.661 1.805 1.805 0 0 1-.337-1.532l1.566-7.046C4.026 9.774 4.989 9 6 9h1v2H6c-.081 0-.223.115-.24.194L4.247 18h15.506l-1.512-6.806c-.018-.079-.16-.194-.241-.194h-1V9h1c1.011 0 1.974.774 2.193 1.761l1.566 7.046zM12 2a4 4 0 0 1 4 4c0 3-4 8-4 8S8 9 8 6a4 4 0 0 1 4-4zm0 6a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 8z"
    />
  </svg>
);

interface AlternateMode {
  mode: ViewMode;
  cmsKey: CmsKey;
  cmsKeyHidden: CmsKey;
  icon: React.ReactElement;
  eventCategory:
    | Category.BrowseHybridResults
    | Category.BrowseListResults
    | Category.BrowseMapResults;
  eventProperty:
    | Property.ToggleToListButton
    | Property.ToggleToHybridButton
    | Property.ToggleToMapButton;
}

// Alternate modes for mobile and tablet sizes.
//
// The key is the current browse mode, and the value contains details
// of the alternate view (needed to render the toggle).
const alternateModesNonDesktop: { [mode: string]: AlternateMode } = {
  [ViewMode.Hybrid]: {
    mode: ViewMode.Map,
    cmsKey: 'view-mode-map',
    cmsKeyHidden: 'view-mode-list',
    icon: iconMap,
    eventCategory: Category.BrowseListResults,
    eventProperty: Property.ToggleToMapButton,
  },
  [ViewMode.List]: {
    mode: ViewMode.Map,
    cmsKey: 'view-mode-map',
    cmsKeyHidden: 'view-mode-list',
    icon: iconMap,
    eventCategory: Category.BrowseListResults,
    eventProperty: Property.ToggleToMapButton,
  },
  [ViewMode.Map]: {
    mode: ViewMode.Hybrid,
    cmsKey: 'view-mode-list',
    cmsKeyHidden: 'view-mode-map',
    icon: iconList,
    eventCategory: Category.BrowseMapResults,
    eventProperty: Property.ToggleToListButton,
  },
};

// Alternate modes for desktop sizes.
//
// The key is the current browse mode, and the value contains details
// of the alternate view (needed to render the toggle).
const alternateModesDesktop: { [mode: string]: AlternateMode } = {
  [ViewMode.Hybrid]: {
    mode: ViewMode.List,
    cmsKey: 'view-mode-list-from-hybrid',
    cmsKeyHidden: 'view-mode-hybrid',
    icon: iconCross,
    eventCategory: Category.BrowseHybridResults,
    eventProperty: Property.ToggleToListButton,
  },
  [ViewMode.List]: {
    mode: ViewMode.Hybrid,
    cmsKey: 'view-mode-hybrid',
    cmsKeyHidden: 'view-mode-list-from-hybrid',
    icon: iconMap,
    eventCategory: Category.BrowseListResults,
    eventProperty: Property.ToggleToHybridButton,
  },
  // User would not normally see this view, but a non-desktop size might
  // result in map view, and then the window is made larger.
  [ViewMode.Map]: {
    mode: ViewMode.Hybrid,
    cmsKey: 'view-mode-list',
    cmsKeyHidden: 'view-mode-map',
    icon: iconList,
    eventCategory: Category.BrowseMapResults,
    eventProperty: Property.ToggleToListButton,
  },
};

interface Props {
  cms: CmsBrowsePage['page']['browse'];
  currentMode: ViewMode;
  // defaultMode: ViewMode.List | ViewMode.Hybrid;
  onChange: (mode: ViewMode) => void;
}

export const ViewModeToggle = ({ currentMode, onChange, cms }: Props) => {
  const { isDesktop } = useDevice();
  const alternateModeNonDesktop = alternateModesNonDesktop[currentMode];
  const labelNonDesktop = cms[alternateModeNonDesktop.cmsKey];
  const hiddenLabelNonDesktop = cms[alternateModeNonDesktop.cmsKeyHidden];
  const alternateModeDesktop = alternateModesDesktop[currentMode];
  const labelDesktop = cms[alternateModeDesktop.cmsKey];
  const hiddenLabelDesktop = cms[alternateModeDesktop.cmsKeyHidden];

  const iconNonDesktop = () => alternateModeNonDesktop.icon;
  const iconDesktop = () => alternateModeDesktop.icon;
  const onClick = (alternateModes: { [mode: string]: AlternateMode }) => {
    const alternateMode = alternateModes[currentMode];

    trackViewModeToggle(
      alternateMode.eventCategory,
      alternateMode.eventProperty
    );

    onChange(alternateMode.mode);
  };

  return (
    <>
      <Viewport serverRender={!isDesktop} device={['mobile', 'tablet']}>
        <Button
          positioningClassname={styles.button}
          size={ButtonSize.Large}
          colour={ButtonColour.WhiteUndecorated}
          label={labelNonDesktop}
          leftAlignLabels
          hiddenLabels={[hiddenLabelNonDesktop]}
          icon={iconNonDesktop}
          onClick={() => onClick(alternateModesNonDesktop)}
        />
      </Viewport>

      <Viewport serverRender={isDesktop} device="desktop">
        <Button
          positioningClassname={styles.button}
          size={ButtonSize.Large}
          colour={ButtonColour.WhiteUndecorated}
          label={labelDesktop}
          leftAlignLabels
          hiddenLabels={[hiddenLabelDesktop]}
          icon={iconDesktop}
          onClick={() => onClick(alternateModesDesktop)}
        />
      </Viewport>
    </>
  );
};
