import { haversine, Units } from 'js/helpers/haversine';
import { arrayWithoutNullables } from 'js/helpers/array';
import { PriceRange } from 'js/helpers/price/PriceRange';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { VenueListingOutput } from '../rainbow/browse-page/VenueListingOutput';
import { DatalayerPage } from './types';
import { Location } from '../rainbow/browse-page/VenueListingSpecificationOutput';
import { LocationPoint } from '../rainbow/LocationPoint';
import { VenueBrowseOutput } from '../rainbow/venue/VenueOutput';
import { ChannelOutput } from '../rainbow/content/ChannelOutput';
import { SalesPriceDiscountType } from '../rainbow/SalesPriceDiscountType';
import { venueMenuItemsDiscountSet } from '../view/browse-page/browse-result';
import { VenueMenuItemTypeOutput } from '../rainbow/venue/VenueMenuItemTypeOutput';
import { VenueListingResultOutput } from '../rainbow/browse-page/VenueListingResultOutput';

// Snowplow cannot handle arrays of data of different shapes.
// For example
//    [1.0, 27, 0.56]     // okay
//    [1.0, null, 0.56]   // cannot handle
//
// To workaround this limitation, absent numbers are represented by
// a specific value.
// This will undoubtedly cause us a problem at some point in the
// future, but for now no other solution comes to mind.
const ABSENT_NUMBER = -1;

export function browsePageDataLayer(
  browse: VenueListingOutput,
  channel?: ChannelOutput
): DatalayerPage {
  if (
    !browse ||
    !browse.specification ||
    !browse.pagination ||
    !browse.results
  ) {
    return {
      pageType: 'browse',
      pageSpecific: {},
    };
  }

  const { location } = browse.specification;
  const slicedResults = browse.results.slice(0, 20);

  return {
    pageType: 'browse',
    pageSpecific: {
      location: getLocation(location),
      treatmentGroup: browse.specification.treatmentCategoryGroup && {
        id: browse.specification.treatmentCategoryGroup.id,
      },
      filters: {
        category: {
          ids:
            browse.specification.treatmentCategories &&
            browse.specification.treatmentCategories.map(item => item.id),
        },
        offerType: 'local',
        orderBy: browse.specification.sort,
        radius: location && location.radius,
        startDate: browse.specification.date && browse.specification.date.from,
        endDate: browse.specification.date && browse.specification.date.to,
      },
      listing: {
        type: 'browse_results',
        venueCount: browse.pagination.totalElements,
        firstItem: browse.pagination.from + 1, // one-based numbering
        lastItem: Math.min(
          browse.pagination.from + browse.pagination.pageSize,
          browse.pagination.totalElements
        ), // one-based numbering
        page: browse.pagination.page + 1, // one-based numbering
        venues: venueDetails(slicedResults, channel, location),
        venueCountBookable: browse.results.reduce(
          (count: number, result) => count + result.data.menuHighlights.length,
          0
        ), // total # of services shown on the page
      },
    },
  };
}

function getLocation(
  location?: Location
): { id: number } | { point: LocationPoint } | undefined {
  if (!location) {
    return undefined;
  }

  if (location.tree) {
    return { id: location.tree.id };
  }

  if (location.point) {
    return { point: location.point };
  }

  return undefined;
}

function venueDistance(
  venue: VenueBrowseOutput,
  distanceUnits: Units,
  location?: Location
): number {
  if (!location || !location.point) {
    return ABSENT_NUMBER;
  }

  const distance = haversine(
    location.point,
    venue.location.point,
    distanceUnits
  );
  return parseFloat(distance.toFixed(2));
}

// A hodgepodge of various things about a venue that are shown to users
// in some way, and need to be tracked.
function venueTags(venue: VenueBrowseOutput): string[] {
  const discounts = venueMenuItemsDiscountSet(venue.menuHighlights);
  const venueTags = [
    ...venue.treatmentBadges.map(
      badge => `treatment-badge-${badge.treatmentId}`
    ),

    ...venue.accolades
      .filter(accolade => accolade.featured)
      .map(() => 'top-rated'),

    discounts.jit ? SalesPriceDiscountType.Jit : null,
    discounts.offpeak ? SalesPriceDiscountType.Offpeak : null,
    venue.newVenue ? 'new' : null,
    venue.type && venue.type.mobile ? 'mobile' : null,

    (venue.type && venue.type.id === 61) ||
    (venue.subTypes && venue.subTypes.some(venue => venue.id === 61))
      ? 'home-based'
      : null,
  ];

  return arrayWithoutNullables(venueTags);
}

function servicesDiscountTypes(
  service: VenueMenuItemTypeOutput
): string | null {
  const priceRange: PriceRangeOutput = service.data.priceRange;
  const fromDiscount = priceRange.range ? 'from' : null;
  const discountTypes = priceRange.yieldDiscountTypes
    .concat(
      new PriceRange(priceRange).shouldShowStrikeThrough()
        ? [SalesPriceDiscountType.SalePrice]
        : ([] as SalesPriceDiscountType[])
    )
    .join('-');

  return discountTypes || fromDiscount || 'null';
}

function servicesDiscountValue(service: VenueMenuItemTypeOutput): number {
  const priceRange: PriceRange = new PriceRange(service.data.priceRange);
  if (
    !priceRange.hasYieldDiscount() &&
    !priceRange.shouldShowStrikeThrough() &&
    priceRange.isRange()
  ) {
    return 0;
  }

  if (service.data.priceRange.maxDiscountPercentage === 0.0) {
    return 0;
  }

  return service.data.priceRange.maxDiscountPercentage / 100;
}

function venueDetails(
  results: VenueListingResultOutput[],
  channel?: ChannelOutput,
  location?: Location
): {} {
  let distanceUnits = Units.KM;
  if (channel && channel.distanceUnit === 'mile') {
    distanceUnits = Units.MILE;
  }

  return {
    venueIds: results.map(value => value.data.id),
    venueDistance: results.map(value =>
      venueDistance(value.data, distanceUnits, location)
    ),
    venueRating: results.map(value => value.data.rating.average || null),
    venueReviewCount: results.map(value => value.data.rating.count || null),
    venueNumberOfServices: results.map(
      value => value.data.menuHighlights.length || null
    ),
    venueServiceIds: results.map(value =>
      value.data.menuHighlights.map(service =>
        parseInt(service.data.id.substr(2), 10)
      )
    ),
    venueServiceDisplayPrice: results.map(value =>
      value.data.menuHighlights.map(service =>
        parseFloat(service.data.priceRange.minSalePriceAmount)
      )
    ),
    venueServiceDiscounts: results.map(value => {
      const discounts = value.data.menuHighlights.map(servicesDiscountTypes);

      return discounts;
    }),
    venueServiceDiscountsValues: results.map(value =>
      value.data.menuHighlights.map(servicesDiscountValue)
    ),
    venueTags: results.map(value => venueTags(value.data)),
  };
}
