import parse from 'date-fns/parse';
import { FORMAT, formatDate, parseDate } from 'js/helpers/datetime-format';

const DAY = 1000 * 60 * 60 * 24;

// startOfWeek - 0 = Sunday, 1 = Monday etc
// Top left cell in calendar grid is always the startOfWeek day
// e.g. Monday 1st of this `date`'s month, or the previous month's last Monday.
export const getMonthViewStart = (date: Date, startOfWeek = 0): Date => {
  // Use Date.UTC to avoid issues with daylight savings
  const start = new Date(Date.UTC(date.getUTCFullYear(), date.getMonth(), 1));
  const offsetDays = (7 + start.getDay() - startOfWeek) % 7;
  const mvStart = new Date(start.getTime() - offsetDays * DAY);
  mvStart.setHours(12);
  return mvStart;
};

export const getMonthViewWeeks = (startDate: Date): string[][] => {
  const startTime = new Date(startDate).getTime();
  const monthViewWeeks = [];

  for (let i = 0; i < 6; i++) {
    const weeks = [];

    for (let t = 0; t < 7; t++) {
      const date = new Date(startTime + DAY * (i * 7 + t));

      weeks.push(formatDate(date));
    }

    monthViewWeeks.push(weeks);
  }

  return monthViewWeeks;
};

export const addDays = (dateStr: string, days: number): string => {
  const newDate = parseDate(dateStr);

  if (newDate) {
    newDate.setDate(newDate.getDate() + days);
  }
  return formatDate(newDate);
};

export const incrementMonth = (date: Date): Date => {
  // returns full date of first day of subsequent month
  const newDate = new Date(date);

  newDate.setDate(1);
  newDate.setMonth(date.getMonth() + 1);
  return newDate;
};

export const decrementMonth = (date: Date): Date => {
  // returns full date of first day of previous month
  const newDate = new Date(date);

  newDate.setDate(1);
  newDate.setMonth(date.getMonth() - 1);
  return newDate;
};

export const firstDayOfMonth = (date: Date): string => {
  if (!date) {
    return '';
  }
  return formatDate(new Date(date.getUTCFullYear(), date.getMonth(), 1));
};

export const lastDayOfMonth = (date: Date): string => {
  if (!date) {
    return '';
  }
  return formatDate(new Date(date.getUTCFullYear(), date.getMonth() + 1, 0));
};
// The start and end dates are inclusive. So the last available date
// includes the end date.
//
// The arguments should be strings in the format specified by FORMAT.
export const generateAvailableDatesForRange = (
  startDateString: string,
  endDateString: string
) => {
  const startDate = parse(startDateString, FORMAT, new Date());
  const endDate = parse(endDateString, FORMAT, new Date());

  const availableDates = [];
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    availableDates.push(formatDate(date));
  }

  return availableDates;
};
