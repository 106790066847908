import Url from 'url-parse';
import { generateUri, parseUri } from 'js/helpers/uri-util';
import { isBrowser } from './environment';

export function setHost(url: string, protocol: string, host: string): string {
  const urlInstance = new Url(url);
  urlInstance.set('protocol', protocol);
  urlInstance.set('host', host);
  return urlInstance.toString();
}

function generateBrowseUri(
  parameters: {},
  channel: { code: string; languageCode: string },
  initialPageParameters?: { [key: string]: unknown }
): string {
  let currentPageParameters = initialPageParameters;
  if (isBrowser) {
    const uri = parseUri(
      window.location.pathname,
      channel.code,
      channel.languageCode
    );
    if (uri) {
      currentPageParameters = uri.values;
    }
  }

  return (
    generateUri(
      'browse',
      {
        ...currentPageParameters,
        ...parameters,
      },
      channel.code,
      channel.languageCode
    ) || ''
  );
}

export function createBrowsePageUri(
  targetPage: number,
  channel: { code: string; languageCode: string },
  initialPageParameters?: { [key: string]: unknown }
): string {
  return generateBrowseUri(
    {
      page: targetPage > 1 ? [targetPage.toString()] : [],
    },
    channel,
    initialPageParameters
  );
}

export function createBrowseLocationUri(
  normalisedName: string,
  channel: { code: string; languageCode: string },
  initialPageParameters?: { [key: string]: unknown }
): string {
  return generateBrowseUri(
    {
      page: null,
      location: [normalisedName],
    },
    channel,
    initialPageParameters
  );
}

export function createBrowseTreatmentUri(
  normalisedName: string,
  channel: { code: string; languageCode: string },
  initialPageParameters?: { [key: string]: unknown }
): string {
  return generateBrowseUri(
    {
      page: null,
      treatments: [normalisedName],
    },
    channel,
    initialPageParameters
  );
}

export function createBrowseTreatmentTypeUri(
  normalisedName: string,
  channel: { code: string; languageCode: string },
  initialPageParameters?: { [key: string]: unknown }
): string {
  return generateBrowseUri(
    {
      page: null,
      treatmentType: [normalisedName],
    },
    channel,
    initialPageParameters
  );
}
