import * as React from 'react';
import styles from './DateTimeOptions.module.css';

interface Props {
  icon: React.ReactNode;
  header: string;
  children: React.ReactNode;
  dataCy?: string;
}

export function DateTimeOptions({
  icon,
  header,
  children,
  dataCy,
}: Props): React.ReactElement {
  return (
    <>
      <div className={styles.header}>
        {icon}
        <span className={styles.headerText}>{header}</span>
      </div>
      <div className={styles.buttonGroup} data-cy={dataCy}>
        {children}
      </div>
    </>
  );
}
