import React from 'react';
import { VenueCard } from 'js/components/VenueCard';
import { HorizontalOverflowHint } from 'js/components/HorizontalOverflowHint';
import { VenueCardType } from 'js/components/VenueCard/VenueCard';
import styles from './RecommendedVenues.module.css';

export interface Props {
  sectionHeader: string;
  venues: VenueCardType[];
  onVenueCardClick?: VenueCard['props']['onClick'];
  countryCode: string;
}

export function RecommendedVenues(props: Props): React.ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.recommendedVenues}>
        <h2 className={styles.header}>{props.sectionHeader}</h2>
        <HorizontalOverflowHint
          containerClassName={styles.venues}
          initialSpacing={32}
        >
          {props.venues.map((venue, index: number) => (
            <VenueCard
              key={venue.id}
              cardNumber={index}
              onClick={props.onVenueCardClick}
              pageName="home_page"
              countryCode={props.countryCode}
              {...venue}
            />
          ))}
        </HorizontalOverflowHint>
      </div>
    </div>
  );
}
