import { VenueTagData, VenueTagType } from 'js/components/VenueTag/VenueTag';
import { substitute } from 'js/helpers/i18n';

import { CmsVenue } from 'js/model/cms/cms-venue';
import { DiscountSet } from 'js/model/rainbow/SalesPriceDiscountType';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';

export function createVenueTags(
  discounts: DiscountSet,
  venueTypeOutput: VenueTypeOutput | undefined,
  venueSubtypeOutput: VenueTypeOutput[] | undefined,
  cms: CmsVenue
): VenueTagData[] {
  const result: VenueTagData[] = [];

  const discountVenueTagData:
    | VenueTagData
    | undefined = createDiscountVenueTagViewModel(discounts, cms);
  if (discountVenueTagData) {
    result.push(discountVenueTagData);
  }

  if (venueTypeOutput && venueTypeOutput.mobile) {
    result.push(createMobileVenueTagViewModel(cms));
  }

  const isHomeBasedVenueType =
    venueTypeOutput &&
    venueTypeOutput.id.toString() === cms['home-based-venue']['venue-type-id'];
  const isHomeBasedVenueSubType =
    venueSubtypeOutput &&
    venueSubtypeOutput.some(
      element =>
        element.id.toString() === cms['home-based-venue']['venue-type-id']
    );
  if (isHomeBasedVenueType || isHomeBasedVenueSubType) {
    result.push(createHomeBasedTagViewModes(cms));
  }

  return result;
}

export function createDiscountVenueTagViewModel(
  discounts: DiscountSet,
  cms: CmsVenue
): VenueTagData | undefined {
  function createResult(
    cmsKey:
      | 'jit-and-offpeak-discount-active'
      | 'jit-discount-active'
      | 'offpeak-discount-active'
  ): VenueTagData {
    const label = cms.menu[cmsKey];

    return {
      type: VenueTagType.YieldDiscount,
      label,
      tooltipText: substitute(cms.menu['discounts-tooltip'], label),
    };
  }

  if (discounts.jit && discounts.offpeak) {
    return createResult('jit-and-offpeak-discount-active');
  }
  if (discounts.jit) {
    return createResult('jit-discount-active');
  }
  if (discounts.offpeak) {
    return createResult('offpeak-discount-active');
  }

  return undefined;
}

function createMobileVenueTagViewModel(cms: CmsVenue): VenueTagData {
  return {
    type: VenueTagType.MobileVenue,
    label: cms['mobile-venue'].label,
    tooltipText: cms['mobile-venue']['tooltip-content'],
  };
}

function createHomeBasedTagViewModes(cms: CmsVenue): VenueTagData {
  return {
    type: VenueTagType.HomeBased,
    label: cms['home-based-venue'].label,
    tooltipText: cms['home-based-venue']['tooltip-content'],
  };
}
