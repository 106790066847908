import React from 'react';

import { trackMerchandisingSlotSelect } from 'js/pages/HomePage/tracking/tracking';
import { MerchandisingSlotData } from 'js/pages/HomePage/MerchandisingSlots/MerchandisingSlotData';
import { Button, Text, Viewport } from '@treatwell/ui';
import { buttonCustomColourFromString } from 'js/components/Button/ButtonCustomColour';
import bigWaveRight from 'assets/images/bigWaveRight.svg';
import mobileWaveRight from 'assets/images/mobileWaveRight.svg';
import mobileWaveLeft from 'assets/images/mobileWaveLeft.svg';
import styles from './AppBanner.module.css';

interface Props {
  slot: MerchandisingSlotData;
}

export function AppBanner({ slot }: Props): React.ReactElement {
  const onClick = (): void => {
    trackMerchandisingSlotSelect(slot.targetUrl);
  };

  const textLines = slot.text.split('\n');
  const buttonColours = buttonCustomColourFromString(slot.buttonColour);
  const buttonCssProperties = slot.buttonColour
    ? ({
        '--Button--primary-backgroundColor': `${buttonColours.colour}`,
        '--Button--primary-color': `${buttonColours.text}`,
      } as React.CSSProperties)
    : ({
        '--Button--primary-backgroundColor': 'var(--color-coral)',
        '--Button--primary-color': 'var(--color-white)',
      } as React.CSSProperties);
  return (
    <a
      href={slot.targetUrl}
      onClick={onClick}
      className={styles.appBanner}
      data-testid="merchSlot"
    >
      <div className={styles.appBannerSlot}>
        <div className={styles.textContainer}>
          <Text type="smHero" as="h2">
            {slot.heading}
          </Text>
          {textLines.map((line, index) => (
            <Text as="p" type="body" key={index}>
              {line}
            </Text>
          ))}
          <div style={buttonCssProperties}>
            <Button buttonStyle="primary">{slot.buttonText}</Button>
          </div>
        </div>
        <div className={styles.imageAspectBox}>
          <div
            style={{
              backgroundImage: `url(${encodeURI(slot.imageUrl)})`,
            }}
            className={styles.slotImage}
          />
        </div>
        <Viewport device="mobile">
          <div className={styles.mobileWaves}>
            <img
              src={mobileWaveRight}
              className={styles.rightMotionWaveMobile}
              aria-hidden
            />
            <img
              src={mobileWaveLeft}
              className={styles.leftMotionWaveMobile}
              aria-hidden
            />
          </div>
        </Viewport>
        <Viewport device={['desktop', 'tablet']}>
          <img
            src={bigWaveRight}
            className={styles.rightMotionWaveDesktop}
            aria-hidden
          />
        </Viewport>
      </div>
    </a>
  );
}
