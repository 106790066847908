import * as React from 'react';

import { Datepicker } from 'js/components/controls/Datepicker/Datepicker';
import { CmsCalendar } from 'js/model/cms/cms-calendar';
import { formatDate } from 'js/helpers/datetime-format';
import { firstAvailableDate, lastAvailableDate } from './date-range-helper';

interface Props {
  cmsCalendar: CmsCalendar;
  maxDaysInFuture: number;
  selectedDate?: Date;
  selectableToEndOfMonth?: boolean;
  onDateSelect: (dateString: string, date: Date) => void;
}

export function DatepickerInRange({
  selectableToEndOfMonth = true,
  ...props
}: Props): React.ReactElement {
  const availableDates = {
    first: formatDate(firstAvailableDate()),
    last: formatDate(
      lastAvailableDate(props.maxDaysInFuture, selectableToEndOfMonth)
    ),
  };

  const selectedDate = props.selectedDate
    ? formatDate(props.selectedDate)
    : null;

  return (
    <Datepicker
      availableDates={availableDates}
      selectedDate={selectedDate}
      onDateSelect={props.onDateSelect}
      cmsCalendar={props.cmsCalendar}
    />
  );
}
