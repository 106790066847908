import React from 'react';
import clsx from 'clsx';
import { Context } from 'js/components/LocaleWrapper';
import { Item } from './TabBar/Item';
import { TabBar } from './TabBar/TabBar';
import styles from './TabBox.module.css';
import { SalonSearchTab } from '../SearchBoxTabs/SalonSearchTab';
import { TreatmentSearchTab } from '../SearchBoxTabs/TreatmentSearchTab';

interface Props {
  items: Item[];
  positioningClassName?: string;
  activeIndex: number;
  isRenderHiddenTabs: boolean;
  onTabItemClick?: (index: number) => void;
}

export function TabBox({
  activeIndex,
  isRenderHiddenTabs = false,
  ...props
}: Props): React.ReactElement {
  return (
    <div className={clsx(styles.container, props.positioningClassName)}>
      <TabBar
        items={props.items}
        onTabItemClick={props.onTabItemClick}
        activeIndex={activeIndex}
      />

      {isRenderHiddenTabs ? (
        props.items.map((item, index) => {
          return (
            <div
              key={item.label}
              style={{
                display: activeIndex === index ? 'block' : 'none',
              }}
            >
              <TabBoxItem index={index} />
            </div>
          );
        })
      ) : (
        <div key={props.items[activeIndex].label}>
          <TabBoxItem index={activeIndex} />
        </div>
      )}
    </div>
  );
}

const TabBoxItem = ({ index }: { index: number }) => {
  const { pageData, generateUri } = React.useContext(Context);

  const treatmentTab = () => {
    return (
      <TreatmentSearchTab
        pageData={pageData}
        generateUri={generateUri}
        cmsCommon={pageData.cms.common}
        cmsSearch={pageData.cms.page.home.search}
      />
    );
  };

  const salonTab = () => {
    return (
      <SalonSearchTab
        cmsCommon={pageData.cms.common}
        cmsSearch={pageData.cms.page.home.search}
      />
    );
  };
  return (
    <>
      {index === 0 && treatmentTab()}
      {index === 1 && salonTab()}
    </>
  );
};
