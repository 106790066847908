import * as React from 'react';
import clsx from 'clsx';
import { ItemResultType } from 'js/components/controls/search-item-result';
import styles from './ListItem.module.css';

interface Props {
  label: string;
  labelRight?: string;
  separatorLine?: boolean;
  keyValue: string;
  type?: ItemResultType;
  isSelected?: boolean;
  onClick?: (keyValue: string) => void;
  url?: string;
}

export interface ListItemHandle {
  isSelectable: () => boolean;
}

export const ListItem = React.forwardRef(
  ({ isSelected = false, ...props }: Props, ref: React.Ref<ListItemHandle>) => {
    React.useImperativeHandle(ref, () => ({
      isSelectable(): boolean {
        return true;
      },
    }));

    function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
      event.preventDefault();
      if (!props.onClick) {
        return;
      }
      props.onClick(props.keyValue);
    }

    let typeClassName: string | undefined;

    if (props.type === ItemResultType.CurrentLocation) {
      typeClassName = styles.currentLocation;
    }

    const className = clsx(styles.listItem, styles.selectable, typeClassName, {
      [styles.selected]: isSelected,
    });

    const content = (
      <>
        <span>{props.label}</span>
        {props.labelRight && <span>{props.labelRight}</span>}
      </>
    );

    return (
      <>
        {props.url ? (
          <a href={props.url} className={className} onClick={onClick}>
            {content}
          </a>
        ) : (
          <div className={className} onClick={onClick}>
            {content}
          </div>
        )}
        {props.separatorLine && <div className={styles.listItemSeparator} />}
      </>
    );
  }
);

ListItem.displayName = 'ListItem';
