export enum CurrencyFormat {
  SYMBOL_START = 'symbol_start',
  SYMBOL_END = 'symbol_end',
  CODE_START = 'code_start',
  CODE_END = 'code_end',
  WHITESPACE_SYMBOL = 'whitespace_symbol',
  SYMBOL_WHITESPACE = 'symbol_whitespace',
  WHITESPACE_CODE = 'whitespace_code',
  CODE_WHITESPACE = 'code_whitespace',
}
