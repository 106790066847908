import * as React from 'react';
import addDays from 'date-fns/addDays';
import { dayHourList } from 'js/helpers/time';
import { formatDateTimeString } from 'js/helpers/datetime-format';
import { DropdownInput } from 'js/components/controls/DropdownInput';
import { dateWithinRange } from 'js/components/controls/DatepickerInRange';
import { CmsCommonControls } from 'js/model/cms/cms-common-controls';
import { CmsCalendar } from 'js/model/cms/cms-calendar';
import { DateTimeButtonKeys } from './DateTimeButtonKeys';
import { DateTimeDialog } from './DateTimeDialog';
import { DateIcon } from './DateIcon';
import {
  ClearableMode,
  DropdownInputHandle,
} from '../DropdownInput/DropdownInput';
import { TrackingDetails } from './TrackingDetails';

interface TimeRange {
  from: string;
  to: string;
}

interface Props {
  maxDaysInFuture: number;
  placeholder: string;
  cmsCalendar: CmsCalendar;
  cmsCommonControls: CmsCommonControls;
  selectableToEndOfMonth?: boolean;
  isClearable?: ClearableMode;
  onBlur?: () => void;
  onFocus?: () => void;
  onTrack: (details: TrackingDetails) => void;
  onChange: (
    date: Date | null,
    timeFrom: string | null,
    timeTo: string | null,
    dateTimeString: string
  ) => void;
  isPatternedBackground?: boolean;
}

export interface DateTimeInputHandle {
  setDateTime: (
    date: Date | null,
    timeFrom?: string | null,
    timeTo?: string | null
  ) => void;
}

export function generateDateTimeLabel(
  cmsCommonControls: CmsCommonControls,
  date: Date | null,
  startHour: string | null,
  endHour: string | null
): string {
  const validatedDate = dateWithinRange(date, 90, true);

  const validatedTime: TimeRange | null =
    startHour !== null && endHour !== null
      ? { from: startHour, to: endHour }
      : null;

  return formatDateTimeString(
    {
      dateFormat: cmsCommonControls['date-input'].format,
      dateTimeFormat: cmsCommonControls['datetime-input']['datetime-format'],
      timeRangeFormat: cmsCommonControls['datetime-input']['timerange-format'],
    },
    validatedDate,
    validatedTime
  );
}

export const DateTimeInput = React.forwardRef(
  (
    {
      selectableToEndOfMonth = true,
      isClearable = ClearableMode.Clearable,
      ...props
    }: Props,
    ref: React.Ref<DateTimeInputHandle>
  ) => {
    const dropdownInputRef = React.useRef<DropdownInputHandle>(null);
    const [date, setDate] = React.useState<Date | null>(null);
    const [showDatepicker, setShowDatepicker] = React.useState<boolean>(false);
    const [activeDateButton, setActiveDateButton] = React.useState<string>(
      DateTimeButtonKeys.ANY_DATE
    );
    const [activeTimeButton, setActiveTimeButton] = React.useState<string>(
      DateTimeButtonKeys.ANY_TIME
    );
    const [selectedTimeFrom, setSelectedTimeFrom] = React.useState<string>(
      '0800'
    );
    const [selectedTimeTo, setSelectedTimeTo] = React.useState<string>('2000');

    React.useImperativeHandle(ref, () => ({
      setDateTime,
    }));

    const timeButtons = [
      {
        key: DateTimeButtonKeys.ANY_TIME,
        label:
          props.cmsCommonControls['datetime-input']['time-any-time-button'],
      },
      {
        key: DateTimeButtonKeys.CHOOSE_TIME,
        label:
          props.cmsCommonControls['datetime-input']['time-choose-time-button'],
        arrow: true,
      },
    ];

    const dateButtons = [
      {
        key: DateTimeButtonKeys.ANY_DATE,
        label:
          props.cmsCommonControls['datetime-input']['date-any-date-button'],
      },
      {
        key: DateTimeButtonKeys.TODAY,
        label: props.cmsCommonControls['datetime-input']['date-today-button'],
      },
      {
        key: DateTimeButtonKeys.TOMORROW,
        label:
          props.cmsCommonControls['datetime-input']['date-tomorrow-button'],
      },
      {
        key: DateTimeButtonKeys.CHOOSE_DATE,
        label:
          props.cmsCommonControls['datetime-input']['date-choose-date-button'],
        arrow: true,
      },
    ];

    /**
     * Public method to set the the selected date & time.
     * This will update the state to reflect the provided date & time,
     * it will set the active date & time buttons accordingly,
     * and show the datepicker, if a date is provided that is neither today nor tomorrow.
     *
     * @param {Date} date - A Date object, or null. If null, date selection will be cleared.
     *
     * @param {String} timeFrom - A time string, or null. If null, time selection will be cleared.
     *
     * @param {String} timeTo - A time string, or null. If null, time selection will be cleared.
     *
     * @returns {undefined}
     */
    function setDateTime(
      date: Date | null = null,
      timeFrom: string | null = null,
      timeTo: string | null = null
    ): void {
      const validatedDate = dateWithinRange(
        date,
        props.maxDaysInFuture,
        selectableToEndOfMonth
      );

      const validatedTimeFrom = _validatedTime(timeFrom);
      const validatedTimeTo = _validatedTime(timeTo);

      const validatedTime: TimeRange | null =
        validatedTimeFrom && validatedTimeTo
          ? { from: validatedTimeFrom, to: validatedTimeTo }
          : null;

      const activeDateButton = getButtonFromDate(validatedDate);
      const activeTimeButton = getButtonFromTime(validatedTime);

      const dateTimeString = generateDateTimeLabel(
        props.cmsCommonControls,
        validatedDate,
        validatedTimeFrom,
        validatedTimeTo
      );

      if (dropdownInputRef.current) {
        dropdownInputRef.current.setValue(dateTimeString);
      }

      setDate(validatedDate);
      setActiveDateButton(activeDateButton);
      setActiveTimeButton(activeTimeButton);
      setShowDatepicker(activeDateButton === DateTimeButtonKeys.CHOOSE_DATE);
      setSelectedTimeFrom(timeFrom || selectedTimeFrom);
      setSelectedTimeTo(timeTo || selectedTimeTo);

      if (props.onChange) {
        props.onChange(
          validatedDate,
          validatedTimeFrom,
          validatedTimeTo,
          dateTimeString
        );
      }
    }

    function _validatedTime(time: string | null): string | null {
      if (
        !dayHourList(false, 0, 24).some(
          (entry: { value: string; label: string }) => entry.value === time
        )
      ) {
        return null;
      }
      return time;
    }

    function onDateSelect(date: Date | null): void {
      if (activeTimeButton === DateTimeButtonKeys.CHOOSE_TIME) {
        setDateTime(date, selectedTimeFrom, selectedTimeTo);
      } else {
        setDateTime(date, null, null);
      }
    }

    function onTimeSelect(from: string, to: string): void {
      setDateTime(date, from, to);
    }

    function _onChange(value: string, isUserChange: boolean): void {
      if (isUserChange && value === '') {
        setDateTime(null, null, null);
      }
    }

    function onDone(): void {
      if (dropdownInputRef.current) {
        dropdownInputRef.current.blur();
      }
    }

    function getButtonFromDate(date: Date | null): string {
      if (!date) {
        return DateTimeButtonKeys.ANY_DATE;
      }

      const today = new Date();
      const tomorrow = addDays(today, 1);

      switch (date.toDateString()) {
        case today.toDateString():
          return DateTimeButtonKeys.TODAY;
        case tomorrow.toDateString():
          return DateTimeButtonKeys.TOMORROW;
        default:
          return DateTimeButtonKeys.CHOOSE_DATE;
      }
    }

    function getButtonFromTime(time: TimeRange | null): string {
      return time
        ? DateTimeButtonKeys.CHOOSE_TIME
        : DateTimeButtonKeys.ANY_TIME;
    }

    function setShowDatepickerState(showDatepicker: boolean): void {
      setShowDatepicker(showDatepicker);
    }

    function setDateButtonState(activeDateButton: string): void {
      setActiveDateButton(activeDateButton);
    }

    function setTimeButtonState(activeTimeButton: string): void {
      if (activeTimeButton === DateTimeButtonKeys.CHOOSE_TIME) {
        setDateTime(date, selectedTimeFrom, selectedTimeTo);
      } else {
        setDateTime(date, null, null);
      }

      setActiveTimeButton(activeTimeButton);
    }

    function track(details: TrackingDetails): void {
      if (props.onTrack) {
        props.onTrack(details);
      }
    }

    function onBlur(): void {
      props.onBlur?.();
    }

    function onFocus(): void {
      props.onFocus?.();
    }

    function onOpen(): void {
      track({
        property: 'date_and_time',
        action: 'open',
      });
    }

    function onClose(): void {
      track({
        property: 'date_and_time',
        action: 'close',
      });
    }

    const dropdownWidthRatio = showDatepicker ? 2 : 1;

    return (
      <DropdownInput
        ref={dropdownInputRef}
        icon={DateIcon}
        isClearable={isClearable}
        isTextEditable={false}
        placeholder={props.placeholder}
        isFocusOnClear={false}
        isHotJarWhiteList
        closeButtonText={
          props.cmsCommonControls['dropdown-input']['close-button']
        }
        dropdownWidthRatio={dropdownWidthRatio}
        onChange={_onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onOpen={onOpen}
        onClose={onClose}
        isPatternedBackground={props.isPatternedBackground}
      >
        <DateTimeDialog
          cmsCalendar={props.cmsCalendar}
          cmsCommonControls={props.cmsCommonControls}
          maxDaysInFuture={props.maxDaysInFuture}
          selectedDate={date || undefined}
          selectedTimeFrom={selectedTimeFrom}
          selectedTimeTo={selectedTimeTo}
          selectableToEndOfMonth={selectableToEndOfMonth}
          timeButtons={timeButtons}
          dateButtons={dateButtons}
          activeTimeButton={activeTimeButton}
          activeDateButton={activeDateButton}
          showDatepicker={showDatepicker}
          onDatepickerToggle={setShowDatepickerState}
          onDateButtonSelect={setDateButtonState}
          onDateSelect={onDateSelect}
          onTimeSelect={onTimeSelect}
          onTimeButtonSelect={setTimeButtonState}
          onDone={onDone}
          onTrack={track}
        />
      </DropdownInput>
    );
  }
);

DateTimeInput.displayName = 'DateTimeInput';
