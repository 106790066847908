import React from 'react';
import clsx from 'clsx';
import styles from './MultilineEllipsis.module.css';

type MultilineEllipsisType = {
  text: string;
  className?: string;
  id?: string;
};

export const MultilineEllipsis = ({
  text,
  className,
  id,
}: MultilineEllipsisType) => (
  <div id={id} className={clsx(styles.root, className)}>
    {text}
  </div>
);
