import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

export function trackBrowsePageEvent(
  category: Category,
  property: Property,
  action: Action,
  label?: string,
  value?: string
): Promise<void> {
  return trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export function trackViewModeToggle(
  // Reflects the current view mode.
  category:
    | Category.BrowseHybridResults
    | Category.BrowseListResults
    | Category.BrowseMapResults,
  // The mode being switched to.
  property:
    | Property.ToggleToListButton
    | Property.ToggleToHybridButton
    | Property.ToggleToMapButton
): Promise<void> {
  return trackBrowsePageEvent(category, property, Action.Click);
}

export enum Category {
  BrowseListResults = 'browse_list_results',
  BrowseMapResults = 'browse_map_results',
  BrowseHybridResults = 'browse_hybrid_results',
  BrowseListCard = 'browse_list_card',
  BrowseHybridCard = 'browse_hybrid_card',
}

export enum Property {
  QuickView = 'quick_view',
  BestTreatmentInTown = 'best_treatment_in_town',
  GoToVenue = 'go_to_venue',
  ImageSlider = 'image_slider',
  ToggleToListButton = 'toggle_to_list_button',
  ToggleToHybridButton = 'toggle_to_hybrid_button',
  ToggleToMapButton = 'toggle_to_map_button',
}

export enum Action {
  Open = 'open',
  Close = 'close',
  Click = 'click',
}
