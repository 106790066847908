import React from 'react';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';
import { TrackingDetails } from 'js/components/controls/DateTimeInput/TrackingDetails';
import { yieldToMain } from 'js/common/yield-to-main';

function trackEvent(
  category: Category,
  property: Property,
  action: Action,
  label?: string,
  value?: number | string
): void {
  trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}
function trackEventWhereActionIsString(
  category: Category,
  property: Property,
  action: string,
  label?: string,
  value?: number | string
): void {
  trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export enum Category {
  List = 'browse_list_www',
  Map = 'browse_map_www',
  Hybrid = 'browse_hybrid_www',
}

enum Property {
  DateDaysInFuture = 'calendar',
  DateTime = 'date_and_time_field',
  DateType = 'date_field',
  Duration = 'duration',
  Location = 'location_field',
  LocationRadius = 'radius_expansion',
  TimeEnd = 'max_time',
  TimeStart = 'min_time',
  TimeType = 'calendar',
  Treatment = 'treatment_field',
  VenueType = 'venue_type',
  Visibility = 'filters',
}

export enum Action {
  Collapse = 'collapse',
  Expand = 'expand',
  Open = 'open',
  Search = 'type',
  Select = 'select',
  SelectTreatment = 'select_treatment',
  SelectTreatmentType = 'select_treatmenttype',
  SelectVenueType = 'select_venuetype',
}

export enum LocationType {
  Location = 'tree',
  ExternalLocation = 'external',
  PostalReference = 'postal_ref',
  Geo = 'geo_location',
}

export function trackTreatmentSelect(
  category: Category,
  type: Action.SelectTreatment | Action.SelectTreatmentType,
  treatmentPosition: number,
  treatmentName: string,
  id: number
): void {
  trackEventWhereActionIsString(
    category,
    Property.Treatment,
    `${type}_${treatmentPosition}`,
    treatmentName,
    id
  );
}

export function trackVenueTypeSelect(
  category: Category,
  type: Action.SelectVenueType,
  treatmentPosition: number,
  treatmentName: string,
  id: number
): void {
  trackEventWhereActionIsString(
    category,
    Property.VenueType,
    `${type}_${treatmentPosition}`,
    treatmentName,
    id
  );
}

export function trackDurationSearch(
  category: Category,
  duration: string
): void {
  trackEvent(category, Property.Duration, Action.Select, duration);
}

export function trackTreatmentSearch(
  category: Category,
  searchTerm: string,
  resultCount: number
): void {
  trackEvent(
    category,
    Property.Treatment,
    Action.Search,
    searchTerm,
    resultCount
  );
}

export function trackLocationSelect(
  category: Category,
  type: LocationType,
  treatmentPosition?: number,
  value?: number | string
): void {
  trackEventWhereActionIsString(
    category,
    Property.Location,
    `${Action.Select}_${treatmentPosition}`,
    type,
    value
  );
}

export function trackLocationSearch(
  category: Category,
  searchTerm: string,
  resultCount: number
): void {
  trackEvent(
    category,
    Property.Location,
    Action.Search,
    searchTerm,
    resultCount
  );
}

export function trackLocationRadiusSelect(
  category: Category,
  distanceUnit: string,
  radius?: number
): void {
  if (radius) {
    trackEvent(
      category,
      Property.LocationRadius,
      Action.Select,
      distanceUnit,
      radius
    );
  } else {
    trackEvent(category, Property.LocationRadius, Action.Select, 'nearby');
  }
}

export async function trackDatetime(
  category: Category,
  details: TrackingDetails
) {
  await yieldToMain();

  trackEvent(
    category,
    details.property as Property,
    details.action as Action,
    details.label,
    details.value as number | undefined
  );
}

export async function trackToggleTreatmentSearchHeader(
  category: Category,
  eventType: 'click' | 'scroll',
  action: Action.Collapse | Action.Expand
) {
  await yieldToMain();

  trackEvent(category, Property.Visibility, action, eventType);
}

/*
  wwwTrackingContext is a context for providing the 'category' to
  use for WhatWhereWhen related tracking.

  Use its consumer when renderering, to obtain the Category to use
  for tracking functions.

  Example usage:
      <wwwTrackingContext.Consumer>
        {trackingCategory => (
          <div> The tracking category is {trackingCategory}. </div>
        )}
      </wwwTrackingContext.Consumer>
*/
export const wwwTrackingContext = React.createContext<Category>(Category.List);
