import { PropsType } from 'js/types/react';
import { CmsPropositionSlots } from 'js/model/cms/cms-proposition-slots';
import slot0 from 'assets/images/proposition-slots/slot-0.svg';
import slot1 from 'assets/images/proposition-slots/slot-1.svg';
import slot2 from 'assets/images/proposition-slots/slot-2.svg';
import { PropositionSlots } from 'js/pages/BestForBrowsePage/PropositionSlots/PropositionSlots';

const slotImages = [slot0, slot1, slot2];

export function createPropositionSlotsViewModel(
  cms: CmsPropositionSlots
): PropsType<typeof PropositionSlots> {
  return {
    heading: cms.heading,
    slots: cms.slot.map((slot, slotIndex) => ({
      imageUri: slotImages[slotIndex],
      heading: slot.heading,
      body: slot.body,
    })),
  };
}
