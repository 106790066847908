import React from 'react';

import { Inline, Stack, Viewport, useDevice } from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import { MerchandisingSlotData } from 'js/pages/HomePage/MerchandisingSlots/MerchandisingSlotData';
import styles from './MerchandisingSlots.module.css';
import { MerchandisingSlot } from './MerchandisingSlot';
import { AppBanner } from '../AppBanner';

const APP_SLOT_INDEX = 2;
interface Props {
  slots: MerchandisingSlotData[];
}

export function MerchandisingSlots({ slots }: Props): React.ReactElement {
  const { isMobile } = useDevice();
  const { getMediaUri } = React.useContext(Context);
  function createImageUrl(imageUrl: string): string {
    return getMediaUri(imageUrl);
  }

  function renderMerchSlots(): React.ReactNode {
    const merchSlots = slots.slice(0, APP_SLOT_INDEX);
    return merchSlots.map((slot: MerchandisingSlotData, index: number) => (
      <MerchandisingSlot
        key={index}
        slot={{ ...slot, imageUrl: createImageUrl(slot.imageUrl) }}
      />
    ));
  }

  function renderAppSlot(): React.ReactNode {
    const appSlot = slots.slice(APP_SLOT_INDEX);
    return appSlot.map(
      (slot: MerchandisingSlotData, index: number) =>
        slot.targetUrl !== '' && (
          <AppBanner
            key={index}
            slot={{ ...slot, imageUrl: createImageUrl(slot.imageUrl) }}
          />
        )
    );
  }

  return (
    <>
      <div className={styles.merchandisingSlots}>
        <Viewport device="mobile" serverRender={isMobile}>
          <Stack space="md">{renderMerchSlots()}</Stack>
        </Viewport>
        <Viewport device={['tablet', 'desktop']} serverRender={!isMobile}>
          <Inline space="xl" align="stretch">
            {renderMerchSlots()}
          </Inline>
        </Viewport>
      </div>
      {renderAppSlot()}
    </>
  );
}
