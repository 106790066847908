import { SupportFooter, mParticleLogEvent } from '@treatwell/ui';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import mParticle from '@mparticle/web-sdk';

import { AccountDetailsState } from 'js/model/state/account/details';

import { connect } from 'react-redux';
import { ReduxState } from 'js/model/state';
import { getAccountDetails } from 'js/redux-modules/account/selectors';
import { loadAccountDetails } from 'js/redux-modules/account/details/actions';
import { requestSalesforceTokens } from 'js/service/salesforceService';
import { Context } from '../LocaleWrapper';
import { salesforceBotConfig } from './config';

type Props = {
  accountDetails: AccountDetailsState;
  loadAccountDetails: () => void;
  className?: string;
};

const SalesforceFooterInner = ({
  accountDetails,
  loadAccountDetails,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { channel } = useContext(Context);
  const strings = {
    genericError: t('referral.otpInputModal.error.generic'),
    helpCentre: t('footer.helpCentre'),
    chatSupport: t('footer.chatSupport'),
    modal: {
      close: t('footer.chatModal.close'),
      title: t('footer.chatModal.title'),
      body: t('footer.chatModal.body'),
      cta: t('footer.chatModal.cta'),
    },
  };

  const onHandleBotError = (error: Error) => {
    console.warn('Possible Salesforce bot error:', error);
  };

  React.useEffect(() => {
    if (
      !accountDetails.user &&
      !accountDetails.isFetching &&
      !accountDetails.error
    ) {
      loadAccountDetails();
    }
  }, [accountDetails, loadAccountDetails]);

  const preChat = {
    hidden: {
      externalUserId: accountDetails.user?.accountId?.toString() || '0',
      externalUserFullName: `${accountDetails.user?.firstName} ${accountDetails.user?.lastName}`,
      externalUserEmail: accountDetails.user?.email || '',
      externalLanguage: channel.locale,
      externalCountry: channel.country.countryCode,
      externalSource: 'Web',
    },
  };

  const onChatOpen = (conversationId: string | null) => {
    mParticleLogEvent('chat_opened', mParticle.EventType.Other, {
      country: channel.country.countryCode,
      platform: 'web',
      conversation_id: conversationId,
      chat_source: 'home_footer',
    });
  };

  const onChatClose = (conversationId: string | null) => {
    mParticleLogEvent('chat_closed', mParticle.EventType.Other, {
      country: channel.country.countryCode,
      platform: 'web',
      conversation_id: conversationId,
      chat_source: 'home_footer',
    });
  };

  const getTokens = async () => {
    try {
      return requestSalesforceTokens();
    } catch {
      return null;
    }
  };

  return (
    <div className={className} data-cy="SalesforceFooter">
      <SupportFooter
        botProps={{
          ...salesforceBotConfig,
          locale: channel.locale,
        }}
        preChat={preChat}
        strings={strings}
        onChatOpen={onChatOpen}
        onChatClose={onChatClose}
        getTokens={getTokens}
        onHandleBotError={onHandleBotError}
        userAuthenticated={accountDetails.user?.isAuthenticated || false}
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  accountDetails: getAccountDetails(state),
});

const mapDispatchToProps = {
  loadAccountDetails,
};

export const SalesforceFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesforceFooterInner);
