import React from 'react';

export const enum GesturePlaneDirection {
  Unknown,
  Horizontal,
  Vertical,
}

export const enum GestureCardinalDirection {
  Unknown,
  North,
  East,
  South,
  West,
}

interface PositionSample {
  clientX: number;
  clientY: number;
  timestamp: number;
}

export class TouchGesture {
  private readonly MAX_POSITION_SAMPLE_COUNT = 5;

  private readonly MAX_SPEED_CALCULATION_AGE_MS = 1000;

  private readonly DIRECTION_DECISION_THRESHOLD = 20;

  private readonly identifier: number;

  private readonly startClientX: number;

  private readonly startClientY: number;

  private readonly startFractionalIndex: number;

  private planeDirection: GesturePlaneDirection = GesturePlaneDirection.Unknown;

  private cardinalDirection: GestureCardinalDirection =
    GestureCardinalDirection.Unknown;

  private offsetX = 0;

  private offsetY = 0;

  private positionSamples: PositionSample[] = [];

  constructor(touch: React.Touch | Touch, startFractionalIndex: number) {
    this.identifier = touch.identifier;
    this.startClientX = touch.clientX;
    this.startClientY = touch.clientY;
    this.startFractionalIndex = startFractionalIndex;
  }

  public getStartFractionalIndex(): number {
    return this.startFractionalIndex;
  }

  public getPlaneDirection(): GesturePlaneDirection {
    return this.planeDirection;
  }

  public getCardinalDirection(): GestureCardinalDirection {
    return this.cardinalDirection;
  }

  public getOffsetX(): number {
    return this.offsetX;
  }

  public getOffsetY(): number {
    return this.offsetY;
  }

  public update(touches: React.TouchList | TouchList): void {
    const touch = this.getSingleTouch(touches);
    if (touch === undefined) {
      return;
    }

    this.pushPositionSample(touch);

    const offset = this.calcOffset(touch);

    this.offsetX = offset.x;
    this.offsetY = offset.y;

    this.updateDirection();
  }

  public containedIn(touches: React.TouchList | TouchList): boolean {
    for (let i = 0; i < touches.length; i++) {
      const touch = touches.item(i);
      if (touch === null) {
        continue;
      }

      if (touch.identifier === this.identifier) {
        return true;
      }
    }

    return false;
  }

  private updateDirection(): void {
    if (
      this.planeDirection !== GesturePlaneDirection.Unknown ||
      this.cardinalDirection !== GestureCardinalDirection.Unknown
    ) {
      return;
    }

    if (Math.abs(this.offsetX) > this.DIRECTION_DECISION_THRESHOLD) {
      this.planeDirection = GesturePlaneDirection.Horizontal;
      this.cardinalDirection =
        this.offsetX > 0
          ? GestureCardinalDirection.West
          : GestureCardinalDirection.East;
    } else if (Math.abs(this.offsetY) > this.DIRECTION_DECISION_THRESHOLD) {
      this.planeDirection = GesturePlaneDirection.Vertical;
      this.cardinalDirection =
        this.offsetY > 0
          ? GestureCardinalDirection.North
          : GestureCardinalDirection.South;
    }
  }

  private calcOffset(touch: React.Touch | Touch): { x: number; y: number } {
    return {
      x: touch.clientX - this.startClientX,
      y: touch.clientY - this.startClientY,
    };
  }

  private pushPositionSample(touch: React.Touch | Touch): void {
    this.positionSamples.push({
      clientX: touch.clientX,
      clientY: touch.clientY,
      timestamp: window.performance.now(),
    });

    this.positionSamples = this.positionSamples.slice(
      -this.MAX_POSITION_SAMPLE_COUNT
    );
  }

  /*
   * Returns vertical movement in pixels per millisecond
   */
  public getSpeedX(): number {
    const now = window.performance.now();
    const recentHistory = this.positionSamples.filter(
      (positionSample: PositionSample) =>
        positionSample.timestamp >= now - this.MAX_SPEED_CALCULATION_AGE_MS
    );

    let result = 0;

    for (
      let i = 0, pairsCount = recentHistory.length - 1;
      i < pairsCount;
      i++
    ) {
      const leftPositionSample: PositionSample = recentHistory[i];
      const rightPositionSample: PositionSample = recentHistory[i + 1];

      const positionOffset: number =
        rightPositionSample.clientX - leftPositionSample.clientX;
      const timeOffset: number =
        rightPositionSample.timestamp - leftPositionSample.timestamp;

      result += (positionOffset / timeOffset) * (1 / pairsCount);
    }

    return result;
  }

  private getSingleTouch(
    touches: React.TouchList | TouchList
  ): React.Touch | Touch | undefined {
    if (touches.length !== 1) {
      return;
    }

    const result = touches.item(0);
    if (result === null) {
      return;
    }

    if (this.identifier !== result.identifier) {
      return;
    }

    return result;
  }
}
