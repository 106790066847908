import React from 'react';
import clsx from 'clsx';
import styles from './DotIndicators.module.css';

interface Props {
  indicatorCount: number;
  activeIndicator: number;
}

export class DotIndicators extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const indicators = Array(this.props.indicatorCount).fill(false);

    indicators[this.props.activeIndicator] = true;

    return (
      <div className={styles.container}>
        {indicators.map((indicator, indicatorIndex) => (
          <div
            className={clsx(styles.indicator, { [styles.active]: indicator })}
            key={indicatorIndex}
          />
        ))}
      </div>
    );
  }
}
