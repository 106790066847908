import * as React from 'react';
import clsx from 'clsx';
import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import styles from './NativeSelectInput.module.css';

interface OptionValue {
  label: string;
  value: string;
}

interface Props {
  positioningClassname?: string;
  optionValues: OptionValue[];
  selectedValue: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
}

export function NativeSelectInput(props: Props): React.ReactElement {
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const selectRef = React.useRef<HTMLSelectElement>(null);

  function onChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    setIsActive(false);

    props.onChange?.(event.target.value);

    // defer the blur to next animation frame, so that a blur event is not emitted twice on mobile
    requestAnimationFrame(() => {
      if (
        selectRef.current !== null &&
        selectRef.current === document.activeElement
      ) {
        selectRef.current.blur();
      }
    });
  }

  function onFocus(): void {
    setIsActive(true);
    props.onFocus?.();
  }

  function onBlur(): void {
    setIsActive(false);
  }

  function renderSelect(): React.ReactNode {
    const { optionValues } = props;

    return (
      <select
        className={styles.selectBox}
        value={props.selectedValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={selectRef}
      >
        {optionValues.map((item: OptionValue) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    );
  }

  const classes = clsx(
    styles.nativeSelectInput,
    { [styles.active]: isActive },
    props.positioningClassname
  );
  const { optionValues, selectedValue } = props;
  const filteredItems = optionValues.filter(
    (item: OptionValue) => item.value === selectedValue
  );
  const selectedLabel = filteredItems.length > 0 ? filteredItems[0].label : '';

  return (
    <div className={classes}>
      <div className={styles.inputValue}>{selectedLabel}</div>
      <Chevron
        containerStyle={{
          marginLeft: 12,
          marginRight: 12,
        }}
        colour={isActive ? 'bloomBaseNavy800' : 'bloomGreyNavy300'}
        direction={isActive ? Direction.Up : Direction.Down}
        animated
      />
      {renderSelect()}
    </div>
  );
}
