import React from 'react';
import clsx from 'clsx';
import { CmsCalendar } from 'js/model/cms/cms-calendar';
import { parseDate } from 'js/helpers/datetime-format';
import styles from './Datepicker.module.css';
import { MonthView } from './MonthView';
import { MonthHeader } from './MonthHeader';
import { WeekHeader } from './WeekHeader';
import {
  generateAvailableDatesForRange,
  addDays,
  incrementMonth,
  decrementMonth,
  lastDayOfMonth,
  firstDayOfMonth,
} from './date-utils';

interface AvailableDates {
  first: string;
  last: string;
}

interface Props {
  availableDates: AvailableDates;
  selectedDate: string | null;
  onDateSelect: (selected: string, date: Date) => void;
  cmsCalendar: CmsCalendar;
}

// Return an array of available dates.
function normaliseAvailableDates(availableDates: AvailableDates): string[] {
  return generateAvailableDatesForRange(
    availableDates.first,
    availableDates.last
  );
}

export function Datepicker(props: Props): React.ReactElement {
  let selectedDate = props.selectedDate || null;
  const month = selectedDate ? parseDate(selectedDate) : new Date();
  const availableDates = normaliseAvailableDates(props.availableDates);

  const [visibleMonth, setVisibleMonth] = React.useState<Date>(month);
  const [daySquareSize, setDaySquareSize] = React.useState<string | null>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    updateDaySquareSize();

    window.addEventListener('resize', updateDaySquareSize);
    return () => window.removeEventListener('resize', updateDaySquareSize);
  }, []);

  const updateDaySquareSize = (): void => {
    if (!containerRef.current || !containerRef.current.clientWidth) {
      return;
    }

    const measuredDayWrapWidth = containerRef.current.clientWidth / 7;

    // minimum Datepicker width for stylesheet has to be 336px.
    // when width is less we are going to scale down the circles and spaces around the days

    if (measuredDayWrapWidth <= 0 || measuredDayWrapWidth >= 48) {
      setDaySquareSize(null);
      return;
    }
    setDaySquareSize(`${measuredDayWrapWidth}px`);
  };

  function isNextDisabled(date: Date): boolean {
    const lastAvailableDate = availableDates.slice(-1)[0];
    return (
      lastAvailableDate !== undefined &&
      firstDayOfMonth(incrementMonth(date)) > lastAvailableDate
    );
  }

  function isPreviousDisabled(date: Date): boolean {
    const firstAvailableDate = availableDates[0];
    return (
      firstAvailableDate !== undefined &&
      lastDayOfMonth(decrementMonth(date)) < firstAvailableDate
    );
  }

  function handleMonthChange(direction: string, visibleMonth: Date): void {
    if (direction === 'next') {
      setVisibleMonth(incrementMonth(visibleMonth));
    } else if (direction === 'prev') {
      setVisibleMonth(decrementMonth(visibleMonth));
    }
  }

  function handleDateSelect(
    dateStr: string,
    isAvailable: boolean,
    visibleMonth: Date
  ): void {
    const date = parseDate(dateStr);
    const changeMonth = date && date.getMonth() !== visibleMonth?.getMonth();
    const direction =
      firstDayOfMonth(date) < firstDayOfMonth(visibleMonth) ? 'prev' : 'next';

    if (changeMonth) {
      if (direction === 'prev' && isPreviousDisabled(visibleMonth)) {
        return;
      }

      if (direction === 'next' && isNextDisabled(visibleMonth)) {
        return;
      }

      handleMonthChange(direction, visibleMonth);
    }

    if (isAvailable) {
      return props.onDateSelect(dateStr, parseDate(dateStr));
    }
  }

  const { cmsCalendar } = props;

  if (selectedDate && !availableDates.includes(selectedDate)) {
    selectedDate = null;
  }

  const bookingEnd = selectedDate ? addDays(selectedDate, 0) : null;
  const weekdays: string[] = [];

  for (let i = 1; i <= 7; i++) {
    type DayIndex = '1' | '2' | '3' | '4' | '5' | '6' | '7';
    const dayIndex = `${i}` as DayIndex;
    weekdays.push(cmsCalendar.calendar['day-names-short'][dayIndex]);
  }

  const disabledNextMonth = isNextDisabled(visibleMonth);
  const disabledPrevMonth = isPreviousDisabled(visibleMonth);

  const datepickerClassnames = clsx(styles.datepicker, styles.highlightBlue);

  return (
    <div
      className={datepickerClassnames}
      ref={containerRef}
      data-cy="datePicker"
    >
      <MonthHeader
        monthNames={cmsCalendar.calendar['month-names']}
        date={visibleMonth}
        onMonthChange={(direction: string) =>
          handleMonthChange(direction, visibleMonth)
        }
        disabledNextMonth={disabledNextMonth}
        disabledPrevMonth={disabledPrevMonth}
      />
      <WeekHeader weekdays={weekdays} />
      <MonthView
        month={visibleMonth}
        bookingStart={selectedDate}
        bookingEnd={bookingEnd}
        availableDates={availableDates}
        onDateSelect={(selected: string, isAvailable: boolean) =>
          handleDateSelect(selected, isAvailable, visibleMonth)
        }
        daySquareSize={daySquareSize}
      />
    </div>
  );
}
