enum CurrencySymbol {
  GBP = '£',
  USD = '$',
  EUR = '€',
  CHF = 'Fr.',
  DKK = 'kr.',
}

export type CurrencySymbolString = keyof typeof CurrencySymbol;

export function getCurrencySymbolFromCode(
  currencyCode: CurrencySymbolString
): string {
  // eslint-disable-next-line no-prototype-builtins
  if (CurrencySymbol.hasOwnProperty(currencyCode)) {
    return CurrencySymbol[currencyCode];
  }
  console.warn('Unknown currency code', currencyCode);
  return '';
}
