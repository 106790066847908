import React, { useCallback } from 'react';

import { createRecommendedVenuesViewModel } from 'js/model/view/home-page/recommended-venues';
import { fetchRecommendedVenues } from 'js/service/recommendedVenueService';
import { getSnowplowDuid } from 'js/helpers/snowplow';
import { RecommendedVenueResponseOutput } from 'js/model/rainbow/RecommendedVenueResponseOutput';
import {
  trackRecommendedVenuesClick,
  trackRecommendedVenuesLoaded,
} from 'js/pages/HomePage/tracking/recommendedVenues';
import { StateData } from 'js/model/rainbow/StateData';
import { RecommendedVenues } from './RecommendedVenues';

interface Props {
  pageData: StateData;
  generateUri: (
    pageType: string,
    options: { normalisedName: string }
  ) => string;
}

export function RecommendedVenuesController(
  props: Props
): React.ReactElement | null {
  const [recommendedVenues, setRecommendedVenues] = React.useState<
    RecommendedVenueResponseOutput['recommendedVenues'] | undefined
  >(props.pageData.homepage.recommendedVenues);

  const fetch = useCallback(async (): Promise<void> => {
    try {
      const snowplowId = getSnowplowDuid()?.domain_userid;

      if (!snowplowId) {
        return;
      }
      const data = await fetchRecommendedVenues(props.pageData, snowplowId);
      if (!data) {
        return;
      }
      setRecommendedVenues(data.recommendedVenues);

      trackRecommendedVenuesLoaded(data.recommendedVenues.length);
    } catch (e) {
      console.warn(e);
    }
  }, [props.pageData]);

  React.useEffect(() => {
    if (recommendedVenues === undefined) {
      fetch();
    }
  }, [fetch, recommendedVenues]);

  async function onVenueCardClick(
    venueId: number,
    redirectUri: string,
    venueCardNumber: number
  ): Promise<void> {
    trackRecommendedVenuesClick(venueId, venueCardNumber);
    window.location.href = redirectUri;
  }

  if (!recommendedVenues || recommendedVenues.length === 0) {
    return null;
  }

  const currentRecommendedVenues = createRecommendedVenuesViewModel(
    recommendedVenues,
    props.pageData.cms,
    props.generateUri,
    props.pageData.channel.country.countryCode
  );

  return (
    <RecommendedVenues
      onVenueCardClick={onVenueCardClick}
      {...currentRecommendedVenues}
    />
  );
}
