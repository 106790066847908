import React from 'react';

import styles from './Datepicker.module.css';
import { Day } from './Day';

interface Props {
  onDateSelect: (selected: string, isAvailable: boolean) => void;
  availableDates: string[];
  bookingStart: string | null;
  bookingEnd: string | null;
  daySquareSize: string | null;
  visibleMonth: Date;
  days: string[];
}

export const Week = ({
  days = [],
  availableDates,
  onDateSelect,
  visibleMonth,
  bookingStart,
  bookingEnd,
  daySquareSize,
}: Props) => {
  const style = daySquareSize ? { height: daySquareSize } : {};
  return (
    <div className={styles.weekRow} style={style}>
      {days.map((dateStr, index) => (
        <Day
          key={index}
          dateStr={dateStr}
          isAvailable={availableDates.includes(dateStr)}
          visibleMonth={visibleMonth}
          bookingStart={bookingStart}
          bookingEnd={bookingEnd}
          onClick={onDateSelect}
          daySquareSize={daySquareSize}
        />
      ))}
    </div>
  );
};
