import { ImageOutput } from 'js/model/rainbow/ImageOutput';

export interface AccoladeOutput {
  id: number;
  name: string;
  featured: boolean;
  tooltipTextCMSKey: string;
  vectorImage: ImageOutput;
  rasterImage: ImageOutput;
  images: {
    regular: {
      vector: ImageOutput;
    };
  };
}

export function extractVectorImageUri(
  featuredAccolade: AccoladeOutput
): string {
  const firstKey = Object.keys(featuredAccolade.vectorImage.uris)[0];
  return featuredAccolade.vectorImage.uris[firstKey];
}

export interface Accolade {
  count: number;
  id: number;
  name: string;
  normalisedName: string;
}
