import React, { PureComponent, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './TabBarItem.module.css';

interface Props {
  isActive: boolean;
  // This prop is referenced from ./TabBar.
  index: number;
  icon: React.ReactNode;
  label: string;
  onClick?: (target: TabBarItem) => void;
}

export class TabBarItem extends PureComponent<Props> {
  private labelRef: HTMLDivElement | null = null;

  private onClick = (): void => {
    if (!this.props.onClick) {
      return;
    }

    this.props.onClick(this);
  };

  public getLabelBoundingClientRect(): DOMRect {
    if (this.labelRef === null) {
      throw new Error('TabBarItem no ref');
    }

    return this.labelRef.getBoundingClientRect();
  }

  public render(): ReactNode {
    return (
      <div
        className={clsx(styles.item, {
          [styles.active]: this.props.isActive,
        })}
        onClick={this.onClick}
      >
        <div className={styles.icon}>{this.props.icon}</div>
        <div
          ref={(ref: HTMLDivElement) => {
            this.labelRef = ref;
          }}
          className={styles.label}
        >
          {this.props.label}
        </div>
      </div>
    );
  }
}
