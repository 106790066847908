import React from 'react';
import { IconClose, Inline, Text } from '@treatwell/ui';
import clsx from 'clsx';
import styles from './Header.module.css';

interface Props {
  closeText: string;
  title?: string;
  icon?: { size: 16 | 24 };
  onCloseClick?: () => void;
  positioningClassNames?: string;
}

export class Header extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { closeText, title, positioningClassNames, icon } = this.props;
    const headerClassNames = clsx(styles.header, positioningClassNames);

    return (
      <header className={headerClassNames}>
        <Text type="smHeader">{title}</Text>

        <Inline
          space="xxs"
          className={styles.close}
          onClick={this.props.onCloseClick}
        >
          {icon && <IconClose size={icon.size} className={styles.icon} />}
          <Text>{closeText}</Text>
        </Inline>
      </header>
    );
  }
}
