import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { absoluteOffset } from 'js/helpers/dom-measure';
import { isIOS } from 'js/helpers/environment';
import { KEY_CODE_ESCAPE } from 'js/constants/keycodes';
import { collapseIgnoreScrollsContext } from 'js/pages/BrowsePage/WhatWhereWhen/StickyCollapse';
import { Header } from 'js/components/Popup/Header';
import { useDevice, useViewport } from '@treatwell/ui';
import { isIE11 } from 'js/helpers/is-ie11';
import styles from './DropdownInput.module.css';
import { InputField, InputFieldHandle } from './InputField';

// Clearable = Input field is clearable (i.e. has button to delete the contents) in all scenarios
// ClearableWhenActive = Input field is clearable only when focused or associated dialog is open
// NotClearable = Input field is never clearable
export enum ClearableMode {
  Clearable = 'clearable',
  ClearableWhenActive = 'clearable-when-active',
  NotClearable = 'not-clearable',
}

export type DropdownInputHandle = {
  blur: () => void;
  setValue: (value: string) => void;
};

interface Props {
  // A React component returning an SVG that will be shown to the left of the input.
  // The image be up to 24px x 24px.
  icon: React.ComponentType<{ positioningClassName?: string; error?: boolean }>;

  placeholder: string;

  // The text to show to close the mobile viewport popover.
  // If not provided, the text will be taken from a cms item value.
  closeButtonText: string;

  // One (optional) element that will be shown in a dropdown on desktop or a fullscreen mode on mobile
  // when the input field is focused. When undefined or null is passed no dropdown will be shown on desktop.
  // Although on mobile the fullscreen view will always be shown when focused.
  children: React.ReactChild | null;

  // not called when ESC is pressed.
  // onKeyDowns return value should be true if the keyCode invoked an action in the dropdown
  onKeyDown?: (keyCode: number) => boolean;

  // called when the text fields value changed
  // value: the value that was typed into the field
  // isUserChange: true when the change was initiated by the user. false if change occured due to setValue
  onChange: (value: string, isUserChange: boolean) => void;

  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  isPatternedBackground?: boolean;
}

interface DefaultProps {
  isClearable?: ClearableMode;

  // Whether the field should be focused and the dropdown opened when the user clicks the clear icon on
  // the right side of the input. Can be disabled on non mandatory fields.
  isFocusOnClear?: boolean;

  // Whether the user is allowed to type in the input field. Default: true
  isTextEditable?: boolean;

  // Show styling for validation failure
  // if true, red error styles are applied to the InputField & icon
  isErrorStyling?: boolean;

  isHotJarWhiteList?: boolean;

  // The ratio for the width of the dropdown compared to the input field's width.
  // By default the width of the dropdown is equal to the input field's by having a value of 1.0.
  // The dropdown is always left aligned with the input field and extends to the right.
  dropdownWidthRatio?: number;

  renderHiddenResults?: boolean;

  // onOpen and onClose are called when the dropdown is shown and hidden respectively.
  //
  // These events often, but not always, occur in parallel with onFocus and onBlur.
  // For example a dropdown may contain a native control. If the control loses focus
  // then onFocus may be called as this input field regains focus, but the dropdown
  // will remain open, and so onOpen will not be called.
  onOpen?: () => void;
  onClose?: () => void;
}

const NO_WINDOW_SCROLL_Y_BEFORE_MODAL = -1;

let windowScrollYBeforeModalOpen = NO_WINDOW_SCROLL_Y_BEFORE_MODAL;

export const DropdownInput = forwardRef(
  (
    {
      children,
      closeButtonText,
      placeholder,
      icon,
      isPatternedBackground,
      onChange,
      onFocus,
      onKeyDown,
      onBlur,
      isClearable = ClearableMode.Clearable,
      isFocusOnClear = true,
      isErrorStyling = false,
      isTextEditable = true,
      isHotJarWhiteList = false,
      dropdownWidthRatio = 1,
      renderHiddenResults = false,
      onOpen = () => {},
      onClose = () => {},
    }: Props & DefaultProps,
    ref: React.Ref<DropdownInputHandle>
  ) => {
    const collapseIgnoreScrolls = useContext(collapseIgnoreScrollsContext);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const inputFieldRef = useRef<InputFieldHandle>(null);
    const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
    const [
      inputFieldAbsoluteBoundingRect,
      setInputFieldAbsoluteBoundingRect,
    ] = useState<Pick<DOMRect, 'left' | 'bottom' | 'width'>>({
      left: 0,
      bottom: 0,
      width: 0,
    });
    const [value, setValue] = useState('');
    const { isMobile: isMobileDevice } = useDevice();
    const isMobile = useViewport({
      device: 'mobile',
      serverRender: isMobileDevice,
    });

    /*
     * measure and persist input field bounding box in state
     */
    const updateInputFieldMeasurement = () => {
      if (!inputFieldRef) {
        return;
      }

      const inputFieldAbsoluteBoundingRect = inputFieldRef.current?.getAbsoluteBoundingRect();

      if (inputFieldAbsoluteBoundingRect !== undefined) {
        setInputFieldAbsoluteBoundingRect(inputFieldAbsoluteBoundingRect);
      }
    };

    const renderDropdown = () => {
      if (React.Children.count(children) <= 0) {
        return null;
      }

      const dropdownDesktopPositioningStyle = {
        left: `${inputFieldAbsoluteBoundingRect.left}px`,
        top: `${inputFieldAbsoluteBoundingRect.bottom}px`,
        width: `${Math.round(
          inputFieldAbsoluteBoundingRect.width * dropdownWidthRatio
        )}px`,
      };

      const child = React.Children.only(children);

      if (!(child instanceof Object)) {
        throw new Error('Invalid type of child passed to DropdownInput');
      }

      const isNativeElement = typeof child.type === 'string';

      return (
        <div
          ref={dropdownRef}
          className={styles.dropdownContainer}
          style={isMobile ? {} : dropdownDesktopPositioningStyle}
        >
          {React.cloneElement(
            child,
            isNativeElement ? undefined : { isDesktop: !isMobile }
          )}
        </div>
      );
    };

    const toggleDropdownVisibility = useCallback(
      (show: boolean) => {
        if (show !== shouldShowDropdown) {
          if (show) {
            onOpen();
          } else {
            onClose();
          }
        }

        setShouldShowDropdown(show);
      },
      [onClose, onOpen, shouldShowDropdown]
    );

    const showDropdown = () => {
      if (shouldShowDropdown) {
        return;
      }

      updateInputFieldMeasurement();

      if (isMobile) {
        windowScrollYBeforeModalOpen = window.scrollY;
      } else {
        windowScrollYBeforeModalOpen = NO_WINDOW_SCROLL_Y_BEFORE_MODAL;
      }

      toggleDropdownVisibility(true);
    };

    const onInputFieldChange = (value: string) => {
      setValue(value);

      showDropdown();

      if (onChange) {
        onChange(value, true);
      }
    };

    const onInputFieldFocus = (event: React.FocusEvent) => {
      showDropdown();

      if (onFocus) {
        onFocus(event);
      }
    };

    const onInputFieldKeyDown = (event: React.KeyboardEvent): void => {
      switch (event.keyCode) {
        case KEY_CODE_ESCAPE:
          event.preventDefault();

          if (isTextEditable) {
            toggleDropdownVisibility(false);
          } else {
            inputFieldRef.current?.blur();
          }

          return;
        default:
          break;
      }

      let isDropdownAction = false;

      if (onKeyDown) {
        isDropdownAction = onKeyDown(event.keyCode) === true;
      }

      if (isDropdownAction) {
        event.preventDefault();

        showDropdown();

        return;
      }

      if (isMobile) {
        window.scrollTo(window.scrollX, 0);
      }
    };

    const renderInputField = (isFocusedStyling = false) => (
      <InputField
        ref={inputFieldRef}
        value={value}
        placeholder={placeholder}
        icon={icon}
        isEditable={isTextEditable}
        isClearable={
          isClearable === ClearableMode.Clearable ||
          (isClearable === ClearableMode.ClearableWhenActive &&
            shouldShowDropdown)
        }
        isErrorStyling={isErrorStyling}
        isHotJarWhiteList={isHotJarWhiteList}
        isFocusedStyling={isFocusedStyling}
        onChange={onInputFieldChange}
        onFocus={onInputFieldFocus}
        onKeyDown={onInputFieldKeyDown}
        isPatternedBackground={isPatternedBackground}
      />
    );

    const isOwnedFocusableNode = (
      node: Node | EventTarget,
      isOnlyCheckDropdown = false
    ) => {
      if (!(node instanceof Node)) {
        return false;
      }

      // we have to check the nodeNames directly, as we can not rely on tabIndex on iOS
      if (node.nodeName !== 'SELECT' && node.nodeName !== 'INPUT') {
        return false;
      }

      if (!isOnlyCheckDropdown) {
        if (
          inputFieldRef.current !== null &&
          inputFieldRef.current.equalsInputRef(node)
        ) {
          return true;
        }
      }

      if (dropdownRef.current !== null && dropdownRef.current.contains(node)) {
        return true;
      }

      return false;
    };

    const onMouseDown = (event: React.MouseEvent): void => {
      if (!inputFieldRef.current) {
        return;
      }

      /*
       * allow user focus on the input field, to position the cursor even when not focused
       * do not allow for this on iOS, because it is important to first rerender the <input>
       * with a larger font size. otherwise iOS will zoom in the page
       */
      if (inputFieldRef.current.equalsInputRef(event.target) && !isIOS()) {
        return;
      }

      if (!isOwnedFocusableNode(event.target)) {
        event.preventDefault();
        event.stopPropagation();
      }

      /*
       * special override to clear the text when tapping the clear icon
       * due to the fact that on tap the clear icons position can change.
       * at this point the mouse/finger is no longer over the icon, and a click event is not
       * triggered.
       */
      if (
        !shouldShowDropdown &&
        inputFieldRef.current.equalsClearIconRef(event.target)
      ) {
        setValue('');

        if (onChange) {
          onChange('', true);
        }

        if (!isFocusOnClear) {
          return;
        }
      }

      showDropdown();
    };

    const onBlurHandler = useCallback(
      (event: React.FocusEvent) => {
        if (
          event.relatedTarget === null &&
          isOwnedFocusableNode(event.target, true)
        ) {
          if (inputFieldRef.current !== null) {
            inputFieldRef.current.focus();
          }

          return;
        }

        if (
          event.relatedTarget !== null &&
          isOwnedFocusableNode(event.relatedTarget)
        ) {
          return;
        }

        toggleDropdownVisibility(false);

        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur, toggleDropdownVisibility]
    );

    const onCloseClick = (): void => {
      blur();
    };

    const renderShowingDropdown = () => {
      const dropdown = renderDropdown();

      return (
        <div
          className={styles.containerFocused}
          onMouseDown={onMouseDown}
          onBlur={onBlurHandler}
        >
          {isMobile && (
            <Header closeText={closeButtonText} onCloseClick={onCloseClick} />
          )}
          <div
            key="searchArea"
            className={clsx(styles.searchArea, styles.focused)}
          >
            {renderInputField(true)}
          </div>
          {isMobile ? dropdown : createPortal(dropdown, document.body)}
        </div>
      );
    };

    const renderContent = () => {
      if (shouldShowDropdown) {
        return renderShowingDropdown();
      }

      // render minimal shape of focused state for reconciliation to work
      // this ensures that the inputs field caret position, focus and selection is preserved on re-render.
      return (
        <div onMouseDown={onMouseDown}>
          <div key="searchArea" className={styles.searchArea}>
            {renderInputField()}
          </div>
        </div>
      );
    };

    const blur = useCallback(() => {
      toggleDropdownVisibility(false);

      inputFieldRef.current?.blur();

      if (isIE11()) {
        onBlurHandler({} as React.FocusEvent);
      }
    }, [onBlurHandler, toggleDropdownVisibility]);

    useImperativeHandle(
      ref,
      () => ({
        /**
         * Public method to close the dropdown and remove focus from the field.
         * Should mostly be called from composing components.
         */
        blur,
        /**
         * Public method to set the input field's value.
         */
        setValue(text: string) {
          if (value === text) {
            return;
          }

          setValue(text);

          if (onChange) {
            onChange(value, false);
          }
        },
      }),
      [blur, onChange, value]
    );

    useEffect(() => {
      const onResize = (): void => {
        if (shouldShowDropdown) {
          updateInputFieldMeasurement();
        }
      };

      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [shouldShowDropdown]);

    useEffect(() => {
      if (renderHiddenResults) {
        // Transitioned from rendering to hidden.
        // Blur is required to close the dropdown, and ensure that
        blur();
      }
    }, [blur, renderHiddenResults]);

    useEffect(() => {
      const scrollDropdownIntoView = (dropdownNode: HTMLElement) => {
        if (isMobile) {
          return;
        }

        /*
         * getting the INPUT_TO_DROPDOWN_OFFSET from css, as it does not change dynamically, is not used on mobile
         * and we would have to wait for two references to be updated before doing the measurements otherwise
         */
        const INPUT_TO_DROPDOWN_OFFSET =
          parseInt(styles.searchFieldHeight, 10) +
          parseInt(styles.desktopDropdownContainerMarginTop, 10);
        const GAP = 5; // when scrolling the amount of pixels to position away from window edge

        const dropdownBoundingRect = dropdownNode.getBoundingClientRect();
        const dropdownHeight = dropdownBoundingRect.height;

        if (dropdownHeight <= 0) {
          return;
        }

        const dropdownViewportBottom = dropdownBoundingRect.bottom;
        const dropdownAbsoluteTop = absoluteOffset(dropdownNode).y;
        const dropdownAbsoluteBottom = dropdownAbsoluteTop + dropdownHeight;
        const inputAbsoluteTop = dropdownAbsoluteTop - INPUT_TO_DROPDOWN_OFFSET;

        let scrollY = window.scrollY;

        if (dropdownViewportBottom > window.innerHeight) {
          scrollY = dropdownAbsoluteBottom - window.innerHeight + GAP;
        }

        if (inputAbsoluteTop - GAP < scrollY) {
          scrollY = inputAbsoluteTop - GAP;
        }

        window.scrollTo(window.scrollX, scrollY);
      };

      if (isMobile) {
        collapseIgnoreScrolls(shouldShowDropdown);
      }

      document.body.classList.toggle(styles.bodyFocused, shouldShowDropdown);

      if (shouldShowDropdown) {
        if (dropdownRef.current !== null) {
          scrollDropdownIntoView(dropdownRef.current);
        }

        inputFieldRef.current?.focus();
      } else {
        // more readable in this case
        // eslint-disable-next-line no-lonely-if
        if (
          isMobile &&
          windowScrollYBeforeModalOpen > NO_WINDOW_SCROLL_Y_BEFORE_MODAL
        ) {
          window.scrollTo(window.scrollX, windowScrollYBeforeModalOpen);
        }
      }

      return () => {
        document.body.classList.remove(styles.bodyFocused);
      };
    }, [collapseIgnoreScrolls, isMobile, shouldShowDropdown]);

    if (renderHiddenResults) {
      return (
        // Crawable results links.
        <div style={{ display: 'none' }}>{renderDropdown()}</div>
      );
    }

    return renderContent();
  }
);
