import { VenueOpeningHoursOutput } from 'js/model/rainbow/venue/VenueOpeningHoursOutput';
import { VenueOpeningTimesItem } from 'js/components/VenueOpeningTimes';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { getOrderedWeekdays, getWeekdayCalendarKey } from 'js/helpers/date';
import { localizedTime, TimeLabel } from 'js/helpers/time';

export function createOpeningHoursViewModel(
  openingHours: VenueOpeningHoursOutput[],
  closedLabel: string,
  dayNames: CmsBrowsePage['common']['day-names'],
  dayNamesShort: CmsBrowsePage['common']['calendar']['day-names-short'],
  countryCode: string
): VenueOpeningTimesItem[] {
  /* NOTE loop days of week - create 7 day schedule data.
  if days not in openingHours, we show those as closed */

  return getOrderedWeekdays().map(
    (day): VenueOpeningTimesItem => {
      const result = {
        dayOfWeekType: day,
        dayName: dayNames[day],
        dayNameShort: dayNamesShort[getWeekdayCalendarKey(day)],
      };

      const data = openingHours.find(
        openingHour => openingHour.dayOfWeek === day
      );

      if (
        data === undefined ||
        !data.open ||
        data.from === undefined ||
        data.to === undefined
      ) {
        return {
          ...result,
          isOpen: false,
          timeColumns: [closedLabel],
        };
      }
      const from: TimeLabel = localizedTime(data.from, countryCode);
      const to: TimeLabel = localizedTime(data.to, countryCode);
      return {
        ...result,
        isOpen: true,
        timeColumns: [from[0], from[1], to[0], to[1]],
      };
    }
  );
}
