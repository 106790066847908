import React from 'react';
import { MediaObject, Text, useDevice, useViewport } from '@treatwell/ui';
import { Spacing } from '@treatwell/design-tokens';
import {
  Tooltip,
  TooltipPosition,
  TooltipPointerPosition,
} from '../generic/Tooltip';
import styles from './styles.module.css';

type IconProps = {
  size: 16 | 24;
};

interface CommonProps {
  icon: (props: IconProps) => React.ReactElement<React.ReactSVGElement>;
  iconColour?: string;
  // If the size prop is omitted the icon, text size, and spacing
  // to use will be determined by the viewport width.
  size?: 'small' | 'large';
  tooltip?: string;
}

type TextProps = {
  text: string;
  colour?: string;
  children?: never;
} & CommonProps;

type ChildrenProps = {
  text?: never;
  colour?: never;
  children: React.ReactNode;
} & CommonProps;

// The content can be provided either with a "text" prop, or with
// child nodes (but not both).
// If the text prop is used, then the text type used is automatic,
// depending on the size prop or the viewport width.
export type Props = TextProps | ChildrenProps;

export function IconWithContent(props: Props): React.ReactElement {
  const {
    icon: Icon,
    iconColour,
    size,
    tooltip,
    text,
    colour,
    children,
  } = props;
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const { isMobile: isMobileDevice } = useDevice();
  const isSmallViewport = useViewport({
    device: 'mobile',
    serverRender: isMobileDevice,
  });

  let useSmall = isSmallViewport;
  if (size) {
    useSmall = size === 'small';
  }

  const iconSize = useSmall ? 16 : 24;
  const space: Spacing = useSmall ? 'xs' : 'md';

  function content(): React.ReactNode {
    if (children) {
      return <>{children}</>;
    }

    const type = size === 'small' ? 'captionHeavy' : 'smHeader';

    if (colour) {
      return (
        <div style={{ color: colour }}>
          <Text type={type}>{text}</Text>
        </div>
      );
    }
    return <Text type={type}>{text}</Text>;
  }

  function renderTooltip(): React.ReactNode {
    if (!tooltip || !tooltipVisible) {
      return null;
    }

    return (
      <Tooltip
        position={TooltipPosition.Top}
        pointer={TooltipPointerPosition.Middle}
        extraStyles={[styles.tooltip]}
      >
        {tooltip}
      </Tooltip>
    );
  }

  function onClick(event: React.MouseEvent): void {
    // If an <a> (within the content) is clicked on, then
    // do not prevent default handling.
    if ((event.target as HTMLElement).nodeName === 'A') {
      return;
    }

    // This component May be used inside an <a>.
    // In which case clicking to toggle the toolip should
    // not follow the anchor's href.
    //
    // Cannot do this in the touch event handler, as it's
    // a passive listener.
    event.preventDefault();
    event.stopPropagation();
  }

  // Toggle tooltip on touch devices, as mouse enter/leave
  // events won't be fired.
  function onTouch(): void {
    setTooltipVisible(!tooltipVisible);
  }

  function showTooltip(): void {
    setTooltipVisible(true);
  }

  function hideTooltip(): void {
    setTooltipVisible(false);
  }

  const iconProps = iconColour ? { fill: iconColour } : {};
  return (
    <div
      className={styles.iconWithContent}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onPointerDown={onTouch}
      onClick={onClick}
    >
      <MediaObject space={space} align="center">
        <Icon size={iconSize} {...iconProps} />
        {content()}
      </MediaObject>
      {renderTooltip()}
    </div>
  );
}
