import { LazyImage, Rating, Text, Inline } from '@treatwell/ui';
import clsx from 'clsx';
import { MultilineEllipsis } from 'js/components/VenueCard/MultiLineEllipsis';
import { VenueTag, VenueTagType } from 'js/components/VenueTag/VenueTag';
import React, { PureComponent, ReactNode } from 'react';
import i18next from 'i18next';
import { trackVenueResultData } from 'js/pages/BrowsePage/BrowseResult/tracking';
import styles from './VenueCard.module.css';

export enum LineColour {
  Pink = 'pink',
  Orange = 'orange',
  Green = 'green',
  Teal = 'teal',
}

export type VenueCardType = {
  id: number;
  venuePageUri: string;
  imageUri: string;
  name: string;
  ratingValue: string;
  ratingCount: number;
  location: string;
  lineColour?: LineColour;
  discountText?: string;
};

type Props = {
  positioningClassName?: string;
  cardNumber: number;
  onClick?: (venueId: number, redirectUri: string, cardNumber: number) => void;
  pageName: string;
  countryCode: string;
} & VenueCardType;

export class VenueCard extends PureComponent<Props> {
  private handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement>
  ): Promise<void> => {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick(
        this.props.id,
        event.currentTarget.href,
        this.props.cardNumber
      );
      await trackVenueResultData({
        eventName: 'search_venue_clicked',
        venueId: this.props.id,
        rating: {
          average: parseFloat(this.props.ratingValue),
          count: this.props.ratingCount,
          displayAverage: this.props.ratingValue,
        },
        pageName: this.props.pageName,
        venueRank: this.props.cardNumber,
        countryCode: this.props.countryCode,
      });
    }
  };

  public render(): ReactNode {
    const {
      imageUri,
      lineColour,
      ratingValue,
      ratingCount,
      name,
      location,
      discountText,
      id,
    } = this.props;
    const titleId = `title_${id}`;

    return (
      <div className={styles.venueCard} role="group" aria-labelledby={titleId}>
        <a
          href={this.props.venuePageUri}
          className={clsx(
            styles.venueCardLink,
            this.props.positioningClassName
          )}
          onClick={this.handleClick}
          aria-labelledby={titleId}
          data-cy="venue-card-link"
        />
        <LazyImage className={styles.image} alt="" src={imageUri} />
        {lineColour && (
          <div className={clsx(styles.line, styles[lineColour])} />
        )}
        <div className={styles.contentContainer}>
          <MultilineEllipsis text={name} className={styles.name} id={titleId} />
          <Inline space="xs">
            <Rating
              rating={ratingValue}
              size={16}
              ariaLabel={
                ratingCount === 0
                  ? i18next.t('a11y.ratingNoReviews')
                  : i18next.t('a11y.rating', {
                      rating: ratingValue,
                      count: ratingCount,
                    })
              }
              showRatingValue
            />
            <Text
              type="footnote"
              className={styles.reviewsCount}
              aria-hidden="true"
            >
              ({ratingCount})
            </Text>
          </Inline>
          <div className={styles.location}>{location}</div>
          {discountText && (
            <div className={styles.discount}>
              <VenueTag
                label={discountText}
                type={VenueTagType.YieldDiscount}
                size="small"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
