export enum DateTimeButtonKeys {
  // time buttons
  CHOOSE_TIME = 'CHOOSE_TIME',
  ANY_TIME = 'ANY_TIME',

  // date buttons
  ANY_DATE = 'ANY_DATE',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  CHOOSE_DATE = 'CHOOSE_DATE',
}
