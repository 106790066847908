import 'js/common/rafPolyfill';

const ANIMATION_FACTOR = 25;

export function scrollWindowTo(to: number): void {
  const bodyPositionTop = Math.floor(document.body.getBoundingClientRect().top);
  const targetTop = Math.abs(bodyPositionTop - to);

  let bodyPosition = Math.abs(bodyPositionTop);
  const scrollDiff = targetTop - bodyPosition;
  const tick = scrollDiff / ANIMATION_FACTOR;
  let alpha = 0;

  const animation = () => {
    bodyPosition += tick;
    alpha += Math.abs(tick);
    window.scrollTo(0, bodyPosition);

    if (alpha + Math.abs(tick) < Math.abs(scrollDiff)) {
      window.requestAnimationFrame(animation);
    } else {
      window.scrollTo(0, targetTop);
    }
  };

  window.requestAnimationFrame(animation);
}

// Scroll ref item to desired vertical or horizontal scroll position.
export function scrollElementTo(
  ref: HTMLElement,
  toX: number,
  toY: number
): void {
  const elementPositionTop = Math.floor(ref.scrollTop);
  let positionTop = Math.abs(elementPositionTop);
  const scrollDiffTop = toY - positionTop;
  const tickTop = scrollDiffTop / ANIMATION_FACTOR;
  let alphaTop = 0;

  const elementPositionLeft = Math.floor(ref.scrollLeft);
  let positionLeft = Math.abs(elementPositionLeft);
  const scrollDiffLeft = toX - positionLeft;
  const tickLeft = scrollDiffLeft / ANIMATION_FACTOR;
  let alphaLeft = 0;

  const animation = () => {
    positionTop += tickTop;
    alphaTop += Math.abs(tickTop);

    positionLeft += tickLeft;
    alphaLeft += Math.abs(tickLeft);

    ref.scrollTo(positionLeft, positionTop);

    if (
      alphaLeft + Math.abs(tickLeft) < Math.abs(scrollDiffLeft) ||
      alphaTop + Math.abs(tickTop) < Math.abs(scrollDiffTop)
    ) {
      window.requestAnimationFrame(animation);
    } else {
      ref.scrollTo(toX, toY);
    }
  };

  window.requestAnimationFrame(animation);
}
