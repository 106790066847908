import React from 'react';

import clsx from 'clsx';
import styles from './Datepicker.module.css';

const generateWeekHeader = (weekdays: string[]) =>
  weekdays.map((day, index) => (
    <div className={styles.weekday} key={index}>
      {day}
    </div>
  ));

export const WeekHeader = ({ weekdays }: { weekdays: string[] }) => (
  <div className={clsx(styles.weekHeader)}>{generateWeekHeader(weekdays)}</div>
);
