/* eslint-disable no-undef */
export async function getCurrentPosition(
  maxWaitPeriod: number = 30 * 1000
): Promise<GeolocationPosition> {
  if (!navigator || !navigator.geolocation) {
    return Promise.reject('geolocation not supported');
  }

  return await new Promise((resolve, reject) => {
    const options = {
      timeout: maxWaitPeriod,
      maximumAge: 60 * 1000,
    };
    window.navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

// PositionError does not have a public constructor so is not possible to do an instanceof check
export function isPositionError(error: any): error is GeolocationPositionError {
  return (
    error.toString() === '[object PositionError]' ||
    error.toString() === '[object GeolocationPositionError]'
  );
}
