export enum Weekday {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export function getOrderedWeekdays(): Weekday[] {
  return [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
    Weekday.Saturday,
    Weekday.Sunday,
  ];
}

export function getWeekdayToday(): Weekday {
  const todayIndex = new Date().getDay();
  const dayNames = [
    Weekday.Sunday,
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
    Weekday.Saturday,
  ];
  return dayNames[todayIndex];
}

// TODO remove once we use names from date-fns
export function getWeekdayCalendarKey(
  weekday: Weekday
): '1' | '2' | '3' | '4' | '5' | '6' | '7' {
  switch (weekday) {
    case Weekday.Monday:
      return '1';
    case Weekday.Tuesday:
      return '2';
    case Weekday.Wednesday:
      return '3';
    case Weekday.Thursday:
      return '4';
    case Weekday.Friday:
      return '5';
    case Weekday.Saturday:
      return '6';
    case Weekday.Sunday:
      return '7';
  }
}
