import startOfToday from 'date-fns/startOfToday';
import addDays from 'date-fns/addDays';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import isWithinInterval from 'date-fns/isWithinInterval';

export function firstAvailableDate(): Date {
  return startOfToday();
}

export function lastAvailableDate(
  maxDaysInFuture: number,
  isSelectableToEndOfMonth: boolean
): Date {
  const date = addDays(startOfToday(), maxDaysInFuture);

  if (isSelectableToEndOfMonth) {
    return lastDayOfMonth(date);
  }

  return date;
}

export function dateWithinRange(
  date: Date | null,
  maxDaysInFuture: number,
  isSelectableToEndOfMonth: boolean
): Date | null {
  if (date === null) {
    return null;
  }

  const firstDate = firstAvailableDate();
  const lastDate = lastAvailableDate(maxDaysInFuture, isSelectableToEndOfMonth);

  if (!isWithinInterval(date, { start: firstDate, end: lastDate })) {
    return null;
  }

  return date;
}
