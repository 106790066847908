import { substitute } from 'js/helpers/i18n';

export function createBestTreatmentsBadgeLabel(
  treatments: string[],
  singularLabelTemplate: string,
  pluralLabelTemplate: string
): string | undefined {
  if (treatments.length === 0) {
    return undefined;
  }

  const labelTemplate =
    treatments.length === 1 ? singularLabelTemplate : pluralLabelTemplate;
  const label = substitute(labelTemplate, ...treatments);

  return label;
}
