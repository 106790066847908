import * as React from 'react';
import clsx from 'clsx';
import * as DomMeasure from 'js/helpers/dom-measure';
import styles from './InputField.module.css';

interface Props {
  icon: React.ComponentType<{ positioningClassName?: string; error?: boolean }>;
  value: string;
  placeholder?: string;
  isClearable?: boolean;
  isFocusedStyling?: boolean;
  isErrorStyling?: boolean;
  isEditable?: boolean;
  isHotJarWhiteList?: boolean;
  // When the input field is positioned on a non-white background
  // this prop should be true. It removes the grey shadow
  // and adds a box shadow around the field.
  // The default value is false (on a white background).
  isPatternedBackground?: boolean;
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
}

export interface InputFieldHandle {
  focus: () => void;
  blur: () => void;
  equalsClearIconRef: (v: EventTarget) => boolean;
  equalsInputRef: (v: EventTarget) => boolean;
  getAbsoluteBoundingRect: () =>
    | { left: number; top: number; bottom: number; width: number }
    | undefined;
}

export const InputField = React.forwardRef(
  (
    {
      placeholder = '',
      isClearable = false,
      isFocusedStyling = false,
      isErrorStyling = false,
      isEditable = true,
      isHotJarWhiteList = false,
      isPatternedBackground = false,
      ...props
    }: Props,
    ref: React.Ref<InputFieldHandle>
  ) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const clearIconRef = React.useRef<HTMLDivElement>(null);

    React.useImperativeHandle(ref, () => ({
      focus(): void {
        if (inputRef.current === null) {
          return;
        }

        // put caret after text when focusing
        if (document.activeElement !== inputRef.current) {
          positionCaretAtEnd();
        }

        inputRef.current.focus();
      },
      blur(): void {
        if (inputRef.current === null) {
          return;
        }

        inputRef.current.blur();
      },
      equalsClearIconRef(v: EventTarget): boolean {
        return clearIconRef.current === v;
      },
      equalsInputRef(v: EventTarget): boolean {
        return inputRef.current === v;
      },
      getAbsoluteBoundingRect():
        | { left: number; top: number; bottom: number; width: number }
        | undefined {
        if (containerRef.current === null) {
          return;
        }

        const absoluteOffset = DomMeasure.absoluteOffset(containerRef.current);
        const width = containerRef.current.offsetWidth;
        const height = containerRef.current.offsetHeight;

        return {
          left: absoluteOffset.x,
          top: absoluteOffset.y,
          bottom: absoluteOffset.y + height,
          width,
        };
      },
    }));

    function positionCaretAtEnd(): void {
      if (inputRef.current === null) {
        return;
      }

      const caretPosition = inputRef.current.value.length;
      inputRef.current.setSelectionRange(caretPosition, caretPosition);
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
      if (!props.onChange) {
        return;
      }

      props.onChange(event.target.value);
    }

    function onClearClick(): void {
      if (!props.onChange) {
        return;
      }

      props.onChange('');
    }

    function onFocus(event: React.FocusEvent): void {
      if (!isEditable) {
        // do not want any visible selection when not editable
        positionCaretAtEnd();
      }

      if (isEditable) {
        setTimeout(() => {
          inputRef.current?.select();
        }, 0);
      }

      if (props.onFocus) {
        props.onFocus(event);
      }
    }

    const clearClasses = clsx(styles.reset, {
      [styles.visible]: props.value,
    });

    const clearIcon = isClearable ? (
      <div ref={clearIconRef} className={clearClasses} onClick={onClearClick} />
    ) : null;

    const containerClassNames = clsx({
      [styles.container]: true,
      [styles.patternedBackground]: isPatternedBackground && !isFocusedStyling,
      [styles.focused]: isFocusedStyling,
      [styles.error]: isErrorStyling,
      [styles.noneditable]: !isEditable,
    });

    const inputMode = isEditable ? 'search' : 'none';
    const readOnly = !isEditable;

    const Icon = props.icon;

    // Autocomplete is not wanted, as browser autocomplete (particularly Chrome)
    // will not work well with our own input dropdowns.
    //
    // Also, autocomplete keyboard suggestions cause issues with clearing on android.
    //
    // To make matters worse, Chrome does not respect the autocomplete attribute,
    // unless it's set to an invalid value.
    // See
    //    https://stackoverflow.com/questions/25823448/ng-form-and-autocomplete-off/39689037#39689037
    //    https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
    const autocomplete = 'nope';

    return (
      <div ref={containerRef} className={containerClassNames}>
        {Icon ? (
          <Icon error={isErrorStyling} positioningClassName={styles.icon} />
        ) : null}
        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          data-hj-whitelist={isHotJarWhiteList ? '' : null}
          inputMode={inputMode}
          autoComplete={autocomplete}
          readOnly={readOnly}
          value={props.value}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={props.onBlur}
          onChange={onChange}
          onKeyDown={props.onKeyDown}
          onMouseUp={event => event.preventDefault()}
        />
        {clearIcon}
      </div>
    );
  }
);

InputField.displayName = 'InputField';
