import { ButtonColour } from 'js/components/Button/ButtonColour';
import { Dictionary } from 'js/types/generic-types';
import { CmsHomePage } from 'js/model/cms/cms-home-page';

const buttonColourNames: Dictionary<ButtonColour> = {
  orange: ButtonColour.Orange,
  blue: ButtonColour.Blue,
  white: ButtonColour.White,
};

export function getButtonColour(
  buttonColourName: CmsHomePage['page']['home']['search']['highlight-colour']
): ButtonColour | undefined {
  if (!buttonColourName) {
    return undefined;
  }

  return buttonColourNames[buttonColourName];
}
