import React, { PureComponent } from 'react';
import clsx from 'clsx';
import { Text, Stack, Inline } from '@treatwell/ui';
import { getWeekdayToday } from 'js/helpers/date';
import styles from './VenueOpeningTimes.module.css';

export interface VenueOpeningTimesItem {
  isOpen: boolean;
  dayOfWeekType: string;
  dayName: string;
  dayNameShort: string;
  timeColumns:
    | [string]
    | [string, string | undefined, string, string | undefined];
}

interface Props {
  positioningClassName?: string;
  items: VenueOpeningTimesItem[];
}

interface State {
  today: string;
}

export class VenueOpeningTimes extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      today: '',
    };
  }

  public componentDidMount(): void {
    this.setState({
      today: getWeekdayToday(),
    });
  }

  public render() {
    return (
      <div
        className={this.props.positioningClassName}
        data-cy="VenueOpeningTimes"
      >
        <Stack space="xs">
          {this.props.items.map(item => (
            <div
              key={item.dayName}
              className={clsx(
                this.state.today === item.dayOfWeekType,
                this.state.today === item.dayOfWeekType && styles.bold,
                item.isOpen ? styles.open : styles.closed
              )}
            >
              <Inline justify="between">
                <Inline space="sm">
                  <div className={styles.indicator} />
                  <Text className={styles.labelDaynameShort}>
                    {item.dayNameShort}
                  </Text>
                  <Text className={styles.labelDayname}>{item.dayName}</Text>
                </Inline>
                {item.timeColumns.length > 1 ? (
                  <Inline space="xs">
                    <Text>{item.timeColumns[0]}</Text>
                    {item.timeColumns[1] !== undefined && (
                      <Text>{item.timeColumns[1]}</Text>
                    )}
                    <Text>–</Text>
                    <Text>{item.timeColumns[2]}</Text>
                    {item.timeColumns[3] !== undefined && (
                      <Text>{item.timeColumns[3]}</Text>
                    )}
                  </Inline>
                ) : (
                  <div>
                    <Text>{item.timeColumns[0]}</Text>
                  </div>
                )}
              </Inline>
            </div>
          ))}
        </Stack>
      </div>
    );
  }
}
