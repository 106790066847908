import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'js/components/Button';
import clsx from 'clsx';
import { colors } from '@treatwell/design-tokens';
import styles from './ButtonArrow.module.css';
import { ArrowSvg } from './ArrowSvg';
import { ButtonColour } from '../Button/ButtonColour';
import { ArrowPosition } from './ArrowPosition';

/*
  This component uses Button component's props
  Have default props showArrow=true and arrowPosition=bottom
*/

interface Props {
  positioningClassname?: string;
  label: string;
  onClick?: () => void;
}

interface DefaultProps {
  showArrow: boolean;
  arrowPosition: ArrowPosition;
}

export class ButtonArrow extends React.PureComponent<Props & DefaultProps> {
  public static propTypes = {
    positioningClassname: PropTypes.string,
    showArrow: PropTypes.bool,
    arrowPosition: PropTypes.oneOf([
      ArrowPosition.Right,
      ArrowPosition.Top,
      ArrowPosition.Left,
      ArrowPosition.Bottom,
    ]),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  public static readonly defaultProps: Readonly<DefaultProps> = {
    showArrow: true,
    arrowPosition: ArrowPosition.Bottom,
  };

  public render(): React.ReactNode {
    const classes = clsx(styles.buttonArrow, this.props.positioningClassname);
    const borderColour = this.props.showArrow
      ? ButtonColour.BlueOutline
      : ButtonColour.White;

    return (
      <div className={classes}>
        <Button
          {...this.props}
          colour={borderColour}
          positioningClassname={styles.button}
        />
        {this.props.showArrow && (
          <ArrowSvg
            positioningClassname={clsx(
              styles.arrowSvg,
              styles[this.props.arrowPosition]
            )}
            fillColour={colors.baseNavy}
            outlineColour={colors.white}
            onClick={this.props.onClick}
          />
        )}
      </div>
    );
  }
}
