/*
  Determine whether 'enough' of the target item is visible.

  If the item is wholly outside of the visible container.
    It is not visible.

  If the item is partially visible.
    At least the minPercentage of the left side must be visible within scrolled area.
    At least the minPercentage of the right side must be hidden to the right of the scrolled area.
*/
export const isTargetItemVisible = (
  childWidth: number,
  childMarginRight: number,
  containerPadding: number,
  containerVisibleWidth: number,
  containerScrollLeft: number,
  minPercentage: number,
  targetChildIndex: number
): boolean => {
  const minTargetPx = (childWidth / 100) * minPercentage;

  const targetItemMinEndPoint =
    targetChildIndex * (childWidth + childMarginRight) +
    minTargetPx +
    containerPadding;

  const targetItemMaxStartPoint =
    targetChildIndex * (childWidth + childMarginRight) +
    (childWidth - minTargetPx) +
    containerPadding;

  return (
    targetItemMinEndPoint <= containerVisibleWidth + containerScrollLeft &&
    targetItemMaxStartPoint >= containerScrollLeft
  );
};

export const calculateChildRightMargin = (
  numberOfChildren: number,
  childWidth: number,
  currentChildMarginRight: number,
  containerPadding: number,
  containerVisibleWidth: number,
  minPercentage: number
): number => {
  let newChildMarginRight = currentChildMarginRight;

  // check if all children width with default margins can fit in container
  // In other words, if there is no overflow.
  if (
    containerVisibleWidth >
    numberOfChildren * childWidth +
      (numberOfChildren - 1) * currentChildMarginRight
  ) {
    return newChildMarginRight;
  }

  // Count fully visible children within container.
  // 'i' represents the right edge of a child on each iteration.
  let fullyVisibleChildren = 0;
  for (
    let i = containerPadding + childWidth;
    i < containerVisibleWidth;
    i += currentChildMarginRight + childWidth
  ) {
    fullyVisibleChildren += 1;
  }

  // The left edge of the first child that is not fully visible.
  const hintChildLeftEdge =
    containerVisibleWidth -
    containerPadding -
    fullyVisibleChildren * (currentChildMarginRight + childWidth);

  // get pixels from percentage
  const hintMinVisiblePx = (childWidth / 100) * minPercentage;
  const hintMaxVisiblePx = (childWidth / 100) * (100 - minPercentage);

  // check if hint item visibility pixels are in range
  if (
    hintChildLeftEdge < hintMinVisiblePx ||
    hintChildLeftEdge > hintMaxVisiblePx
  ) {
    let showChildrenAmount = fullyVisibleChildren;

    if (hintChildLeftEdge < hintMinVisiblePx) {
      showChildrenAmount = fullyVisibleChildren - 1;
      // last item less that min visibility range
    }

    const itemWidthWithoutPadding =
      showChildrenAmount * childWidth + hintMaxVisiblePx;
    newChildMarginRight =
      (containerVisibleWidth - itemWidthWithoutPadding - containerPadding) /
      showChildrenAmount;
  } else {
    // Last item within visibility range - standard padding
  }

  return Math.round(newChildMarginRight);
};

// Calculate the scroll offset (if any) to use for the container, in order
// to make sure that the target child visible.
export const getScrollToPoint = (
  childWidth: number,
  childMarginRight: number,
  containerPadding: number,
  containerVisibleWidth: number,
  minPercentage: number,
  targetChildIndex: number
): number => {
  const targetItemVisibilityEndPoint =
    (childWidth + childMarginRight) * (targetChildIndex + 1) - childMarginRight;

  let scrollToPoint =
    containerPadding +
    targetItemVisibilityEndPoint -
    childWidth / 2 -
    containerVisibleWidth / 2;

  const itemAmountInLeftScroll =
    (scrollToPoint - containerPadding) / (childWidth + childMarginRight);

  const cutItemPercentage =
    itemAmountInLeftScroll - Math.floor(itemAmountInLeftScroll);
  const remainingItemPx = Math.round(
    cutItemPercentage * (childWidth + childMarginRight)
  );
  const lastMinVisiblePixels = (childWidth / 100) * minPercentage;
  const lastMaxVisiblePx = (childWidth / 100) * (100 - minPercentage);

  if (remainingItemPx < lastMinVisiblePixels) {
    // move scroll to left to avoid target item too much pixels
    scrollToPoint -= lastMinVisiblePixels - remainingItemPx;
  } else if (remainingItemPx > lastMaxVisiblePx) {
    // move scroll to right for missing pixels
    scrollToPoint += remainingItemPx - lastMaxVisiblePx;
  }

  // check if we are trying to set left scroll less than 0
  if (scrollToPoint < 0) {
    scrollToPoint = 0;
  }

  return Math.round(scrollToPoint);
};
