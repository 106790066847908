import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Dictionary } from 'js/types/generic-types';

import { toCamelCase } from 'js/helpers/formatters';
import styles from './Tooltip.module.css';

const PAGE_PADDING = 16;

export enum TooltipPointerPosition {
  Start = 'start',
  Middle = 'middle',
  End = 'end',
}

export enum TooltipPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

enum TooltipPositionOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

const getOrientation = (
  position: TooltipPosition
): TooltipPositionOrientation =>
  // Check if position is vertical and add alignment class
  position === TooltipPosition.Left || position === TooltipPosition.Right
    ? TooltipPositionOrientation.Horizontal
    : TooltipPositionOrientation.Vertical;

const getPositionClass = (
  position: TooltipPosition,
  pointer: TooltipPointerPosition
): string[] => {
  const result: string[] = [];

  result.push(styles[position]);

  const alignment = getOrientation(position);
  const tooltipPointerPositionClassname = (styles as Dictionary<string>)[
    toCamelCase(`${alignment} ${pointer}`)
  ];
  if (tooltipPointerPositionClassname !== undefined) {
    result.push(tooltipPointerPositionClassname);
  }

  return result;
};

interface Props {
  // TODO account for no tooltip text
  children: ReactNode;
  position: TooltipPosition;
  pointer: TooltipPointerPosition;
  extraStyles?: string[];
}

interface State {
  children: ReactNode;
  position: TooltipPosition;
  pointer: TooltipPointerPosition;
  extraStyles?: string[];
}

interface Bounds {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export class Tooltip extends React.Component<Props, State> {
  private windowLimits: Bounds;

  // eslint-disable-next-line no-undef
  private tooltipBounds: DOMRect | ClientRect | undefined = undefined;

  private tooltip: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.props,
    };

    this.windowLimits = { top: 0, bottom: 0, left: 0, right: 0 };
    this.tooltip = React.createRef();
  }

  public componentDidMount(): void {
    this.windowLimits = {
      top: 0,
      bottom: window.innerHeight,
      left: 0,
      right: window.innerWidth,
    };

    if (this.tooltip.current) {
      this.tooltipBounds = this.tooltip.current.getBoundingClientRect();
      this.positionTooltip();
    }
  }

  private positionTooltip(): void {
    if (!this.tooltipBounds) {
      return;
    }

    let pointer: TooltipPointerPosition;

    // Check which quadrant it fits
    const position = this.quadrantThatFits();

    // Check if it fits on the oposite quadrant
    if (
      this.tooltipBounds.left < PAGE_PADDING ||
      this.tooltipBounds.top < PAGE_PADDING
    ) {
      pointer = TooltipPointerPosition.Start;
    } else if (
      this.tooltipBounds.right > this.windowLimits.right - PAGE_PADDING ||
      this.tooltipBounds.bottom > this.windowLimits.bottom - PAGE_PADDING
    ) {
      pointer = TooltipPointerPosition.End;
    } else {
      pointer = this.props.pointer;
    }

    this.setState({ pointer });
    this.setTooltipMargin(pointer, position);
  }

  private setTooltipMargin(
    pointer: TooltipPointerPosition,
    position: TooltipPosition
  ): void {
    if (!this.tooltip.current) {
      return;
    }

    if (pointer !== TooltipPointerPosition.Middle || !this.tooltipBounds) {
      return;
    }

    const orientation = getOrientation(position);

    if (orientation === TooltipPositionOrientation.Horizontal) {
      this.tooltip.current.style.setProperty(
        'margin-top',
        `${(this.tooltipBounds.height / 2) * -1}px`,
        'important'
      );
    } else {
      this.tooltip.current.style.setProperty(
        'margin-left',
        `${(this.tooltipBounds.width / 2) * -1}px`,
        'important'
      );
    }
  }

  private quadrantThatFits(): TooltipPosition {
    const checkFitsQ = (position: TooltipPosition) => {
      if (!this.tooltipBounds) {
        return false;
      }

      switch (position) {
        case TooltipPosition.Top:
          return this.tooltipBounds.top > PAGE_PADDING;
        case TooltipPosition.Bottom:
          return (
            this.tooltipBounds.bottom < this.windowLimits.bottom - PAGE_PADDING
          );
        case TooltipPosition.Left:
          return this.tooltipBounds.left > PAGE_PADDING;
        case TooltipPosition.Right:
          return (
            this.tooltipBounds.right < this.windowLimits.right - PAGE_PADDING
          );
        default:
          return false;
      }
    };

    // Set list of quadrants depending on the default one's axis
    const positions = this.getPositions(this.props.position);

    return positions.filter(checkFitsQ)[0];
  }

  private getPositions(position: TooltipPosition): TooltipPosition[] {
    const alternatePositions = {
      [TooltipPosition.Left]: [
        TooltipPosition.Left,
        TooltipPosition.Right,
        TooltipPosition.Top,
        TooltipPosition.Bottom,
      ],
      [TooltipPosition.Right]: [
        TooltipPosition.Right,
        TooltipPosition.Left,
        TooltipPosition.Top,
        TooltipPosition.Bottom,
      ],
      [TooltipPosition.Top]: [
        TooltipPosition.Top,
        TooltipPosition.Bottom,
        TooltipPosition.Left,
        TooltipPosition.Right,
      ],
      [TooltipPosition.Bottom]: [
        TooltipPosition.Bottom,
        TooltipPosition.Top,
        TooltipPosition.Left,
        TooltipPosition.Right,
      ],
    };

    return alternatePositions[position];
  }

  public render(): ReactNode {
    const classes = clsx([
      styles.tooltip,
      getPositionClass(this.state.position, this.state.pointer),
      this.state.extraStyles,
    ]);

    return (
      <div className={classes} ref={this.tooltip}>
        {this.props.children}
      </div>
    );
  }
}
