export interface ButtonCustomColour {
  colour?: string;
  hoverColour?: string;
  text?: string;
}

/*
  Create a colour object from a string with zero, one, or two
  slash-separated ("/") colours.
  Spaces will be trimmed.

  The colours, in order, will be used for
    colour
    hover colour
    text

  Any colour part may be absent, in which case the returned object's
  corresponding property will be undefined.

  Examples
    "red"
    "red/green"
    "red//green"
    "/#c04010/hsl(68, 50%, 20%)"
*/

export function buttonCustomColourFromString(
  colourString: string
): ButtonCustomColour {
  const [colour, hoverColour, text] = colourString
    .trim()
    .split('/')
    .map((v: string | undefined) => {
      if (v === undefined) {
        return undefined;
      }

      const result: string = v.trim();

      if (result.length === 0) {
        return undefined;
      }

      return result;
    });

  return {
    colour,
    hoverColour,
    text,
  };
}
