export const MAX_RECENT_ENTRIES_COUNT = 5;

export enum ItemResultType {
  ExternalLocation = 'external_location',
  Location = 'location',
  PostalArea = 'postal_area',
  PostalReference = 'postal_reference',
  Treatments = 'treatments',
  TreatmentType = 'treatmentType',
  Venue = 'venue',
  CurrentLocation = 'current-location',
  BoundedBox = 'box',
  VenueType = 'venueType',
}

export interface ItemResultData {
  name: string;
  entityType: ItemResultType;
  entityValue: string;
  aliasId?: number;
}

export interface ItemResult {
  key: string;
  data: ItemResultData;
  label: string;
  labelRight?: string;
  url?: string;
  type: ItemResultType;
}

export function makeItemResult(
  section: string,
  entityType: ItemResultType,
  entityValue: string,
  displayName: string,
  displayRight?: string,
  aliasId?: number
): ItemResult {
  return {
    key: `${section}:${entityType}:${entityValue}`,
    data: { name: displayName, entityType, entityValue, aliasId },
    label: displayName,
    labelRight: displayRight,
    type: entityType,
  };
}

export function isItemResultType(
  candidate: string
): candidate is ItemResultType {
  return (Object.values(ItemResultType) as string[]).includes(candidate);
}
