import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

const CATEGORY = 'recently_viewed_venues';
const PROPERTY = 'venues_list';

export function trackRecommendedVenuesLoaded(venueCount: number): void {
  trackStructuredEvent({
    category: CATEGORY,
    property: PROPERTY,
    action: 'viewed',
    value: venueCount,
  });
}

export function trackRecommendedVenuesClick(
  venueId: number,
  venueCardIndex: number
): void {
  trackStructuredEvent({
    category: CATEGORY,
    property: PROPERTY,
    action: 'click',
    label: venueId,
    value: venueCardIndex + 1,
  });
}
