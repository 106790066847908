import React, { PureComponent, ReactNode } from 'react';
import { scrollElementTo } from 'js/helpers/animations';
import clsx from 'clsx';
import {
  isTargetItemVisible,
  calculateChildRightMargin,
  getScrollToPoint,
} from './horizontal-overflow-hint-calculations';
import styles from './HorizontalOverflowHint.module.css';

/*
  Assumes that all children have the same width.
  If this is not going to be true for a use case, then this component
  will have to be enhanced to support it.
*/

interface Props {
  children: ReactNode[];
  containerClassName: string;

  // The initial (desired) spacing between the child items.
  // The component may adjust the spacing.
  initialSpacing: number;
}

interface DefaultProps {
  // The minimum percentage of the last visible item that should be visible.
  minPreviewPercentage: number;
  // The index of the child that should be visible, scrolling if necessary.
  targetChildIndex: number;
}

interface State {
  childWidth: number;
  childRightMargin: number;
  containerPadding: number;
}

export class HorizontalOverflowHint extends PureComponent<
  Props & DefaultProps,
  State
> {
  private ref = React.createRef<HTMLDivElement>();

  public static readonly defaultProps: Readonly<DefaultProps> = {
    minPreviewPercentage: 20,
    targetChildIndex: 0,
  };

  public state: State = {
    childWidth: 0,
    childRightMargin: this.props.initialSpacing,
    containerPadding: 0,
  };

  public componentDidMount(): void {
    window.addEventListener('resize', this.onResize);

    this.setState(
      {
        childWidth: this.getChildWidth(),
        containerPadding: this.getContainerPadding(),
      },
      () => {
        this.adjustRightMarginAndScroll();
      }
    );
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  public componentDidUpdate(): void {
    this.setState(
      {
        childWidth: this.getChildWidth(),
      },
      () => {
        this.scrollToTargetChild();
      }
    );
  }

  private onResize = (): void => {
    this.setState(
      {
        childWidth: this.getChildWidth(),
        containerPadding: this.getContainerPadding(),
      },
      () => {
        this.scrollToTargetChild();
      }
    );
  };

  private getChildWidth = (): number => {
    if (!this.ref.current || !this.ref.current.children.item(0)) {
      return 0;
    }

    return this.ref.current.children.item(0)!.getBoundingClientRect().width;
  };

  private getContainerPadding = (): number => {
    if (!this.ref.current) {
      return 0;
    }

    const paddingLeft =
      window.getComputedStyle(this.ref.current).paddingLeft || '';

    return parseInt(paddingLeft.replace('px', ''), 10);
  };

  private adjustRightMarginAndScroll = (): void => {
    if (!this.ref.current) {
      return;
    }

    const { childWidth, containerPadding } = this.state;
    const {
      initialSpacing: childMarginRight,
      minPreviewPercentage: minPercentage,
    } = this.props;
    const containerRef = this.ref.current;
    const childrenAmount = containerRef.children.length;
    let childRightMargin = childMarginRight;
    const containerVisibleWidth = containerRef.getBoundingClientRect().width;

    const isVisibleTargetItem = isTargetItemVisible(
      childWidth,
      childRightMargin,
      containerPadding,
      containerVisibleWidth,
      0, // containerLeftScroll
      minPercentage,
      this.props.targetChildIndex
    );

    if (isVisibleTargetItem) {
      childRightMargin = calculateChildRightMargin(
        childrenAmount,
        childWidth,
        childMarginRight,
        containerPadding,
        containerVisibleWidth,
        minPercentage
      );
    }

    this.setState(
      {
        childRightMargin,
      },
      () => {
        // we do not adjust scroll area if target item is visible
        if (!isTargetItemVisible) {
          this.scrollToTargetChild(false);
        }
      }
    );
  };

  private scrollToTargetChild = (animate = true): void => {
    if (!this.ref.current) {
      return;
    }

    const { childWidth, childRightMargin, containerPadding } = this.state;
    const { targetChildIndex } = this.props;

    const scrollToPoint = getScrollToPoint(
      childWidth,
      childRightMargin,
      containerPadding,
      this.ref.current.getBoundingClientRect().width, // containerVisibleWidth
      50, // at least 50% of card to be visible in scroll
      targetChildIndex
    );

    if (animate) {
      scrollElementTo(this.ref.current, scrollToPoint, 0);
    } else {
      this.ref.current.scrollTo(scrollToPoint, 0);
    }
  };

  public render(): ReactNode {
    const classes = clsx(this.props.containerClassName, styles.scroll);

    return (
      <div ref={this.ref} className={classes}>
        {React.Children.map(
          this.props.children,
          (child: ReactNode, index: number) => {
            const isLastItem = index < this.props.children.length - 1;
            const marginRight = isLastItem ? this.state.childRightMargin : 0;

            return (
              <div style={{ marginRight: `${marginRight}px` }}>{child}</div>
            );
          }
        )}
        {/* This div is needed because we have to have padding on right when we have overflow */}
        <div
          style={{
            height: '1px',
            minWidth: `${this.state.containerPadding}px`,
          }}
        />
      </div>
    );
  }
}
