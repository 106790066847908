import React from 'react';
import { Button, Stack, Text, Viewport } from '@treatwell/ui';

import { CmsHomePage } from 'js/model/cms/cms-home-page';
import { trackMerchandisingSlotSelect } from '../tracking/tracking';
import styles from './B2BBanner.module.css';

const cssProperties = {
  '--Button--primary-backgroundColor': 'var(--color-white)',
  '--Button--primary-color': 'var(--color-base-navy)',
} as React.CSSProperties;

type Props = {
  cms: CmsHomePage['page']['home']['b2b-banner'];
};

export function B2BBanner({
  cms: {
    'button-text': buttonText,
    heading,
    text,
    'image-x1': imageX1,
    'image-x2': imageX2,
    'target-url': targetUrl,
  },
}: Props): React.ReactElement {
  function onClick(): void {
    trackMerchandisingSlotSelect(targetUrl);
  }

  function Content(): React.ReactElement {
    return (
      <Stack align="start" space="lg" className={styles.content}>
        <Text type="smHero">{heading}</Text>
        <Text type="smHeader">{text}</Text>

        <Button size="lg" buttonStyle="primary">
          {buttonText}
        </Button>
      </Stack>
    );
  }

  function Image(): React.ReactElement {
    return (
      <img
        className={styles.image}
        src={imageX1}
        srcSet={`${imageX1} 1x, ${imageX2} 2x`}
        alt=""
      />
    );
  }

  return (
    <a
      href={targetUrl}
      className={styles.banner}
      style={cssProperties}
      onClick={onClick}
    >
      <Viewport device={['mobile', 'tablet']}>
        <Stack space="xxl">
          <Content />
          <Image />
        </Stack>
      </Viewport>

      <Viewport device="desktop">
        <div className={styles.bannerInner}>
          <div className={styles.desktop}>
            <div className={styles.desktopInner}>
              <Content />
              <Image />
            </div>
          </div>
        </div>
      </Viewport>
    </a>
  );
}
