import { scrollWindowTo } from 'js/helpers/animations';

export const MOBILE = 'mobile';

export function getWindowDeviceType():
  | 'server'
  | 'mobile'
  | 'tablet'
  | 'desktop' {
  if (typeof window === 'undefined') {
    return 'server';
  }

  return getDeviceTypeForWidth(window.innerWidth);
}

export function getDeviceTypeForWidth(
  windowInnerWidth: number
): 'mobile' | 'tablet' | 'desktop' {
  if (windowInnerWidth >= 992) {
    return 'desktop';
  }
  if (windowInnerWidth >= 520) {
    return 'tablet';
  }
  return 'mobile';
}

export function scrollToElement(selector: string): void {
  if (!selector) {
    return;
  }

  const node = document.querySelectorAll(selector);

  if (!node.length) {
    return;
  }

  scrollWindowTo(node[0].getBoundingClientRect().top);
}

/**
 * This function forces the browser to apply css changes directly.
 *
 * This is useful when applying css transitions, where a initial value has
 * to be applied before the transition can start.
 *
 * @example
 * element.style.transition = 'left 1s linear';
 * element.style.left = '0px'
 * forceLayout(element);
 * element.style.left = '100px'
 * // the animation will start here
 */
export function forceLayout(element: Element): void {
  // getting an elements clientHeight has the side-effect that it forces a browser layout
  element.clientHeight;
}
