import * as React from 'react';

const COLOURS = {
  default: 'var(--color-grey-70)',
  white: 'white',
  error: '#c80f00',
};

interface Props {
  error?: boolean;
  white?: boolean;
  positioningClassName?: string;
}

export function SearchIcon({
  error,
  white,
  positioningClassName,
}: Props): React.ReactElement {
  const nonErrorColour = white ? COLOURS.white : COLOURS.default;
  const colour = error ? COLOURS.error : nonErrorColour;

  return (
    <svg
      className={positioningClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <circle cx="10" cy="10" r="7" stroke={colour} strokeWidth="2" />
        <path stroke={colour} strokeWidth="2" d="M17 17l4 4" />
      </g>
    </svg>
  );
}
