import * as track from './tracking';

const TREATMENT_FIELD = 'treatment_field';
const LOCATION_FIELD = 'location_field';
const DATE_FIELD = 'date_field';

export const TREATMENT_TAB = track.TREATMENT_TAB;

// Treatment Search
export function treatmentSearchFocus(): void {
  track.fieldFocus(TREATMENT_TAB, TREATMENT_FIELD);
}

export function treatmentSearchBlur(): void {
  track.fieldBlur(TREATMENT_TAB, TREATMENT_FIELD);
}

export function treatmentSearchClear(): void {
  track.fieldClear(TREATMENT_TAB, TREATMENT_FIELD);
}

export function treatmentSearchType(value: string, resultCount: number): void {
  track.fieldType(TREATMENT_TAB, TREATMENT_FIELD, value, resultCount);
}

export function treatmentSearchSelect(
  value: string,
  treatmentPosition: number
): void {
  track.fieldSelectWithPosition(
    TREATMENT_TAB,
    TREATMENT_FIELD,
    value,
    treatmentPosition
  );
}

// LocationSearch
export function locationSearchFocus(): void {
  track.fieldFocus(TREATMENT_TAB, LOCATION_FIELD);
}

export function locationSearchBlur(): void {
  track.fieldBlur(TREATMENT_TAB, LOCATION_FIELD);
}

export function locationSearchClear(): void {
  track.fieldClear(TREATMENT_TAB, LOCATION_FIELD);
}

export function locationSearchType(value: string, resultCount: number): void {
  track.fieldType(TREATMENT_TAB, LOCATION_FIELD, value, resultCount);
}

export function locationSearchSelect(
  value: string,
  resultPosition: number
): void {
  track.fieldSelectWithPosition(
    TREATMENT_TAB,
    LOCATION_FIELD,
    value,
    resultPosition
  );
}

// DatetimeInput
export function dateTimeFocus(): void {
  track.fieldFocus(TREATMENT_TAB, DATE_FIELD);
}

export function dateTimeBlur(): void {
  track.fieldBlur(TREATMENT_TAB, DATE_FIELD);
}

export function dateTimeClear(): void {
  track.fieldClear(TREATMENT_TAB, DATE_FIELD);
}

export function dateTimeSelect(value: string): void {
  track.fieldSelect(TREATMENT_TAB, DATE_FIELD, value);
}

// Search Button
export function searchButtonSuccess(): void {
  track.fieldSelect(TREATMENT_TAB, track.SEARCH_BUTTON, 'success');
}

export function searchButtonFail(reason: string): void {
  track.fieldSelect(TREATMENT_TAB, track.SEARCH_BUTTON, reason);
}
