import { isBrowser } from 'js/helpers/environment';

// As soon as  we decide to remove IE11 support, we can remove this helper function and its uses
export function isIE11(): boolean {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    isBrowser &&
    Boolean((window as any).MSInputMethodContext) &&
    Boolean((document as any).documentMode)
  );
  // tslint:enable:no-any
}
