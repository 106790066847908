import { ExternalLocation } from 'js/model/rainbow/ExternalLocation';
import { LocationPostalReference } from 'js/model/rainbow/LocationPostalReference';
import { LocationTree } from 'js/model/rainbow/LocationTree';
import { TreatmentCategoryOutput } from 'js/model/rainbow/TreatmentCategoryOutput';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { VenueTypeOutput } from '../venue/VenueTypeOutput';

export enum Source {
  ExternalLocation = 'external_location',
  Location = 'location',
  PostalArea = 'postal_area',
  PostalReference = 'postal_reference',
  TreatmentCategory = 'treatment_category',
  Venue = 'venue',
  VenueType = 'venue_type',
}

export type Result =
  | {
      type: Source.ExternalLocation;
      data: ExternalLocation;
    }
  | {
      type: Source.Location;
      data: LocationTree;
    }
  | {
      type: Source.PostalReference;
      data: LocationPostalReference;
    }
  | {
      type: Source.PostalArea;
      data: LocationTree;
    }
  | {
      type: Source.TreatmentCategory;
      data: TreatmentCategoryOutput;
    }
  | {
      type: Source.VenueType;
      data: VenueTypeOutput;
    }
  | {
      type: Source.Venue;
      data: VenueOutput;
    };

export interface SearchOutput {
  pagination: unknown;
  results: Result[];
}
