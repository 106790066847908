import * as React from 'react';
import styles from './ListLabel.module.css';

interface Props {
  label: string;
}

export interface ListLabelHandle {
  isSelectable: () => boolean;
}

export const ListLabel = React.forwardRef(
  (props: Props, ref: React.Ref<ListLabelHandle>) => {
    React.useImperativeHandle(ref, () => ({
      isSelectable(): boolean {
        return false;
      },
    }));

    return <div className={styles.listLabel}>{props.label}</div>;
  }
);

ListLabel.displayName = 'ListLabel';
