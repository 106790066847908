const RADII = {
  km: 6371,
  mile: 3960,
};

export enum Units {
  KM,
  MILE,
}

export interface Coordinates {
  lat: number;
  lon: number;
}

function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

export function haversine(
  start: Coordinates,
  end: Coordinates,
  units: Units
): number {
  const R = units === Units.KM ? RADII.km : RADII.mile;

  const dLat = toRadians(end.lat - start.lat);
  const dLon = toRadians(end.lon - start.lon);
  const lat1 = toRadians(start.lat);
  const lat2 = toRadians(end.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}
