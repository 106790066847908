import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

const track = (data: Record<'property' | 'label', string>) =>
  trackStructuredEvent({
    category: 'nearme_hp_footer',
    action: 'click',
    ...data,
  });

export const trackTreatmentSelection = (treatment: string) =>
  track({
    property: 'treatment_selection',
    label: treatment,
  });

export const trackCitySelection = (city: string) =>
  track({
    property: 'city_selection',
    label: city,
  });
