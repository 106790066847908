import React from 'react';

import { trackMerchandisingSlotSelect } from 'js/pages/HomePage/tracking/tracking';
import { MerchandisingSlotData } from 'js/pages/HomePage/MerchandisingSlots/MerchandisingSlotData';
import { Button, Text } from '@treatwell/ui';
import { buttonCustomColourFromString } from 'js/components/Button/ButtonCustomColour';
import styles from './MerchandisingSlot.module.css';

interface Props {
  slot: MerchandisingSlotData;
}

export function MerchandisingSlot({ slot }: Props): React.ReactElement {
  const onClick = (): void => {
    trackMerchandisingSlotSelect(slot.targetUrl);
  };

  const buttonColours = buttonCustomColourFromString(slot.buttonColour);
  const buttonCssProperties = slot.buttonColour
    ? ({
        '--Button--primary-backgroundColor': `${buttonColours.colour}`,
        '--Button--primary-color': `${buttonColours.text}`,
      } as React.CSSProperties)
    : ({
        '--Button--primary-backgroundColor': 'var(--color-coral)',
        '--Button--primary-color': 'var(--color-white)',
      } as React.CSSProperties);
  const textLines = slot.text.split('\n');

  return (
    <a
      href={slot.targetUrl}
      className={styles.merchandisingSlot}
      onClick={onClick}
      data-testid="merchSlot"
    >
      <div className={styles.imageAspectBox}>
        <div
          style={{
            backgroundImage: `url(${encodeURI(slot.imageUrl)})`,
          }}
          className={styles.slotImage}
        />
      </div>
      <div className={styles.textContainer}>
        <div>
          <Text as="h2">{slot.heading}</Text>
          {textLines.map((line, index) => (
            <Text key={index} as="p" type="caption">
              {line}
            </Text>
          ))}
        </div>
        <div style={buttonCssProperties}>
          <Button buttonStyle="primary">{slot.buttonText}</Button>
        </div>
      </div>
    </a>
  );
}
