import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Category {
  OTPVerification = 'otp_verification',
}

enum Action {
  Click = 'click',
  Close = 'close',
  Show = 'show',
}

enum Property {
  PhoneInput = 'phone_input',
  PhoneSubmit = 'phone_submit',
  PhoneError = 'phone_error',
  CodeInput = 'code_input',
  CodeSubmit = 'code_submit',
  CodeResend = 'code_resend',
  CodeError = 'code_error',
}

function trackEvent({
  category,
  property,
  action,
  label,
  value,
}: {
  category: Category;
  property: Property;
  action?: Action;
  label?: string;
  value?: string;
}): void {
  return trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

// tracking for Phone Input Modal
export function trackUserExitsPhoneInputModal() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.PhoneInput,
    action: Action.Close,
  });
}

export function trackUserSubmitsPhoneNumber() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.PhoneSubmit,
    action: Action.Click,
  });
}

export function trackDuplicatePhoneNumber() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.PhoneError,
    action: Action.Show,
    label: 'registered_phone',
  });
}

export function trackInvalidPhoneNumber() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.PhoneError,
    action: Action.Show,
    label: 'invalid_phone',
  });
}

export function trackMaxRequestsReachedPhoneInputModal() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.PhoneError,
    action: Action.Show,
    label: 'otp_year_limit',
  });
}

// tracking for OTP Input Modal
export function trackUserExitsOTPInputModal() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.CodeInput,
    action: Action.Close,
  });
}

export function trackResend() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.CodeResend,
    action: Action.Click,
  });
}

export function trackSubmit() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.CodeSubmit,
    action: Action.Click,
  });
}

export function trackOTPExpiredOrInvalid() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.CodeError,
    label: 'invalid_code',
  });
}

export function trackMaxRequestsReachedOTPInputModal() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.CodeError,
    label: 'otp_year_limit',
  });
}

export function trackOTPLimit() {
  return trackEvent({
    category: Category.OTPVerification,
    property: Property.CodeError,
    label: 'otp_try_limit',
  });
}
