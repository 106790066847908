import React from 'react';
import {
  Modal,
  Stack,
  IconBadge,
  ModalProps,
  IconCheckOutline,
  Text,
  useViewport,
  Inline,
  Button,
} from '@treatwell/ui';
import { useTranslation } from 'react-i18next';
import { PromotionInfoResponse } from 'js/service/referralService';
import { formatPrice } from 'js/helpers/price/priceUtilities';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import styles from './OTPConfirmation.module.css';
import { TEAL_100, TEAL_700, TEAL_600 } from '../colors';

export const OTPConfirmation = ({
  modalProps,
  promotionInfo,
  channel,
}: {
  modalProps: Omit<ModalProps, 'children'>;
  promotionInfo: PromotionInfoResponse;
  channel: ChannelOutput;
}) => {
  const isMobile = useViewport({ device: 'mobile' });
  const { t } = useTranslation();
  const rewardAmount = formatPrice(
    promotionInfo.rewardAmountForReferee,
    channel
  );

  return (
    <Modal {...modalProps} data-cy="OTPConfirmationModal">
      <Inline justify="end" className={styles.closeButton}>
        <Modal.CloseButton
          closeLabel={t('referral.otpConfirmationModal.close')}
        />
      </Inline>
      <Modal.Body>
        <Stack
          space={isMobile ? 'xs' : 'xxl'}
          align="center"
          justify="between"
          fullHeight
          className={styles.body}
        >
          <Stack
            space={isMobile ? 'xxl' : 'lg'}
            align="center"
            justify="between"
          >
            <IconBadge
              icon={<IconCheckOutline size={24} />}
              size="lg"
              colorPair={{
                backgroundColor: TEAL_100,
                color: isMobile ? TEAL_700 : TEAL_600,
              }}
            />
            <Stack space={isMobile ? 'xs' : 'md'}>
              <Text
                as="h2"
                type={isMobile ? 'hugeHeader' : 'xlHeader'}
                className={styles.title}
              >
                {t('referral.otpConfirmationModal.title', {
                  amount: rewardAmount,
                })}
              </Text>
              <Text as="p">
                {t('referral.otpConfirmationModal.body', {
                  amount: rewardAmount,
                  minSpendAmount: formatPrice(
                    promotionInfo.minimumSpendAmount,
                    channel
                  ),
                })}
              </Text>
            </Stack>
          </Stack>
          <Button
            onClick={modalProps.onClose}
            fullWidth
            className={styles.button}
          >
            {t('referral.otpConfirmationModal.cta')}
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
