/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React from 'react';
import debounce from 'p-debounce';
import {
  DateTimeInput,
  DateTimeInputHandle,
} from 'js/components/controls/DateTimeInput';
import {
  LocationSearchInput,
  LocationSearchInputHandle,
} from 'js/components/controls/LocationSearchInput';
import {
  fetchTreatmentSearchResultsDebounced,
  ResultValueType,
} from 'js/service/searchService';
import {
  availableOnValue,
  browseGeolocationValue,
  browseLocationValue,
} from 'js/helpers/uri-util';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';
import * as track from 'js/pages/HomePage/tracking/treatmentSearch';
import { externalLocationSearchSelect } from 'js/pages/HomePage/tracking/tracking';
import { RecentSearchesItem } from 'js/pages/HomePage/SearchBoxTabs/RecentSearchesItem';
import * as GeoLocation from 'js/helpers/geo-location';
import * as Environment from 'js/helpers/environment';
import {
  readTreatmentTabData,
  saveTreatmentSearch,
} from 'js/pages/HomePage/search-data';
import {
  TreatmentSearchInput,
  TreatmentSearchInputHandle,
} from 'js/components/controls/TreatmentSearchInput';
import {
  ItemResult,
  ItemResultData,
  ItemResultType,
} from 'js/components/controls/search-item-result';
import { LoadingButton } from 'js/components/Button/LoadingButton';
import * as navigationLocation from 'js/components/Navigation/navigation-location';
import { CmsHomePage } from 'js/model/cms/cms-home-page';
import { CmsCommon } from 'js/model/cms/cms-common';
import { usePrevious } from 'js/hooks/usePrevious';
import { StateData } from 'js/model/rainbow/StateData';
import { TrackingDetails } from 'js/components/controls/DateTimeInput/TrackingDetails';
import { storage } from '@treatwell/ui';
import { getButtonColour } from '../search-button-colour';
import styles from '../CommonSearchTab.module.css';

interface Props {
  cmsSearch: CmsHomePage['page']['home']['search'];
  cmsCommon: CmsCommon;
  pageData: StateData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateUri: any;
}
export function TreatmentSearchTab(props: Props): React.ReactElement {
  const [
    gotCurrentLocationRejection,
    setGotCurrentLocationRejection,
  ] = React.useState<boolean>(false);
  const [
    isValidLocationSearchInput,
    setIsValidLocationSearchInput,
  ] = React.useState<boolean>(true);
  const [
    isValidTreatmentSearchInput,
    setIsValidTreatmentSearchInput,
  ] = React.useState<boolean>(true);
  const [recentTreatmentSearches, setRecentTreatmentSearches] = React.useState<
    RecentSearchesItem[]
  >([]);
  const [recentLocationSearches, setRecentLocationSearches] = React.useState<
    RecentSearchesItem[]
  >([]);
  const [locationValue, setLocationValue] = React.useState<string>('');

  const debouncedSearch = fetchTreatmentSearchResultsDebounced(
    200,
    ResultValueType.NormalisedName
  );

  const debouncedTreatmentSearchTypeTrack = debounce(
    (value: string, resultCount: number) => {
      track.treatmentSearchType(value, resultCount);
    },
    300
  );

  const languageCode = props.pageData.channel.languageCode;

  const treatmentSearchFunction = async (
    value: string
  ): Promise<ItemResult[]> => {
    const results = await debouncedSearch(props.pageData, value);
    /**
     * Event must be tracked in 500ms after user stopped typing, to avoid registering too many irrelevant mid-state typing events.
     * Because we are awaiting for the request to complete, which is debounced after 200ms, we simply chain fire-and-forget
     * debounced tracking call with timeout of 300ms right after results request is completed.
     */
    debouncedTreatmentSearchTypeTrack(value, results.length);

    return results;
  };

  const treatmentSearchInputRef = React.useRef<TreatmentSearchInputHandle>(
    null
  );
  const locationSearchInputRef = React.useRef<LocationSearchInputHandle>(null);
  const dateTimeInputRef = React.useRef<DateTimeInputHandle>(null);
  const isCurrentTreatmentSearchEmpty = React.useRef<boolean>(true);
  const currentTreatmentSearchData = React.useRef<ItemResultData | null>(null);
  const currentLocationSearchData = React.useRef<ItemResultData | null>(null);
  const currentDate = React.useRef<Date | null>(null);
  const currentTime = React.useRef<{ from: string; to: string } | null>(null);
  const isDestroyed = React.useRef<boolean>(false);
  const isAwaitingCurrentLocation = React.useRef<boolean>(false);

  const prevGotCurrentLocationRejection = usePrevious(
    gotCurrentLocationRejection
  );

  React.useEffect(() => {
    const defaultLocation =
      Environment.isOSMobile() && locationSearchInputRef.current !== null
        ? locationSearchInputRef.current.currentLocationItem().data
        : null;
    const {
      recentTreatments: recentTreatmentSearches,
      recentLocations: recentLocationSearches,
    } = readTreatmentTabData(languageCode, null, defaultLocation, null, null);

    setRecentLocationSearches(recentLocationSearches);
    setRecentTreatmentSearches(recentTreatmentSearches);
  }, [languageCode]);

  React.useEffect(() => {
    const defaultLocation =
      Environment.isOSMobile() && locationSearchInputRef.current !== null
        ? locationSearchInputRef.current.currentLocationItem().data
        : null;
    const { lastSearch } = readTreatmentTabData(
      languageCode,
      null,
      defaultLocation,
      null,
      null
    );

    if (lastSearch.treatment && treatmentSearchInputRef.current !== null) {
      // TODO leverage key building from some util
      treatmentSearchInputRef.current?.setSelectedItemKey(
        `recent:${lastSearch.treatment.entityType}:${lastSearch.treatment.entityValue}`
      );
    }
    if (lastSearch.location && locationSearchInputRef.current !== null) {
      const selectedItemKey =
        lastSearch.location.entityType === ItemResultType.CurrentLocation
          ? locationSearchInputRef.current.currentLocationItem().key
          : // TODO leverage key building from some util
            `recent:${lastSearch.location.entityType}:${lastSearch.location.entityValue}`;

      locationSearchInputRef.current.setSelectedItemKey(selectedItemKey!);
    }

    if (
      (lastSearch.date || lastSearch.time) &&
      dateTimeInputRef.current !== null
    ) {
      dateTimeInputRef.current.setDateTime(
        lastSearch.date,
        lastSearch.time?.from || null,
        lastSearch.time?.to || null
      );
    }
  }, [recentTreatmentSearches, recentLocationSearches]);

  React.useEffect(() => {
    if (!prevGotCurrentLocationRejection && gotCurrentLocationRejection) {
      if (
        currentLocationSearchData.current &&
        currentLocationSearchData.current.entityType ===
          ItemResultType.CurrentLocation
      ) {
        if (locationSearchInputRef.current !== null) {
          locationSearchInputRef.current.clear();
        }
        setIsValidLocationSearchInput(false);
      }
    }
  }, [gotCurrentLocationRejection]);

  const onSubmit = async (): Promise<void> => {
    if (isAwaitingCurrentLocation.current) {
      track.searchButtonFail('fail: user current location not provided');
      return;
    }

    const isValidTreatment =
      currentTreatmentSearchData.current !== null ||
      isCurrentTreatmentSearchEmpty.current;

    const locationIsRequired = props.cmsSearch['location-required'] === 'true';

    let isValidLocation = true;
    let currentPosition: GeolocationPosition | null = null;
    if (currentLocationSearchData.current === null && locationIsRequired) {
      isValidLocation = false;
    } else if (
      currentLocationSearchData.current?.entityType ===
      ItemResultType.CurrentLocation
    ) {
      isAwaitingCurrentLocation.current = true;
      try {
        currentPosition = await GeoLocation.getCurrentPosition();
      } catch (error) {
        if (GeoLocation.isPositionError(error)) {
          if (error.code === error.PERMISSION_DENIED) {
            alert(props.cmsCommon.errors['location-blocked']);
          } else {
            console.warn(error);
            alert(props.cmsCommon.errors['location-error']);
          }
        } else {
          console.warn(error);
        }
        setGotCurrentLocationRejection(true);
        isValidLocation = false;
      }
      isAwaitingCurrentLocation.current = false;

      if (isDestroyed.current) {
        track.searchButtonFail('fail');
        return;
      }
    }

    setIsValidTreatmentSearchInput(isValidTreatment);
    setIsValidLocationSearchInput(isValidLocation);

    if (!isValidLocation && isValidTreatment && locationValue !== '') {
      track.searchButtonFail('fail: invalid location');
      return;
    }

    if (!isValidLocation && isValidTreatment && locationValue === '') {
      track.searchButtonFail('fail: no location');
      return;
    }

    if (!isValidTreatment && isValidLocation) {
      track.searchButtonFail('fail: invalid treatment');
      return;
    }

    if (!isValidLocation && !isValidTreatment && locationValue !== '') {
      track.searchButtonFail('fail: invalid treatment, invalid location');
      return;
    }

    if (!isValidLocation && !isValidTreatment && locationValue === '') {
      track.searchButtonFail('fail: invalid treatment, no location');
      return;
    }

    saveTreatmentSearch(
      currentTreatmentSearchData.current,
      currentLocationSearchData.current,
      currentDate.current,
      currentTime.current,
      languageCode
    );

    if (currentTreatmentSearchData.current !== undefined) {
      storage.session.setItem(
        'searchAliasTreatment',
        currentTreatmentSearchData.current?.aliasId
      );
    }

    await track.searchButtonSuccess();
    if (isDestroyed.current) {
      return;
    }

    goToBrowsePage(currentPosition ? currentPosition.coords : null);
  };

  async function goToBrowsePage(
    geoCoords: GeolocationCoordinates | null
  ): Promise<void> {
    const locationValue = geoCoords
      ? browseGeolocationValue(geoCoords)
      : await browseLocationValue(
          props.pageData,
          currentLocationSearchData.current
        );
    if (isDestroyed.current) {
      return;
    }

    navigationLocation.removeAllExcept(languageCode);
    navigationLocation.save(
      locationValue as navigationLocation.SaveableLocation,
      languageCode
    );

    const values: { [index: string]: string | null } = {
      offerResultType: 'LOCAL',
      ...locationValue,
      venueType: '',
      ...availableOnValue(currentDate.current),
      'timeRange.from': currentTime.current && currentTime.current.from,
      'timeRange.to': currentTime.current && currentTime.current.to,
    };

    if (currentTreatmentSearchData.current) {
      switch (currentTreatmentSearchData.current.entityType) {
        case 'venueType':
          values.venueType = currentTreatmentSearchData.current.entityValue;
          break;
        case 'treatments':
          values.treatments = currentTreatmentSearchData.current.entityValue;
          break;
        case 'treatmentType':
          values.treatmentType = currentTreatmentSearchData.current.entityValue;
          break;
        default:
          break;
      }
    }

    window.location = props.generateUri('browse', values);
  }

  const onTreatmentSearchInputChange = (
    value: string,
    selectedKey: string | null,
    data: ItemResultData | null,
    index: number
  ): void => {
    isCurrentTreatmentSearchEmpty.current = value.trim().length === 0;
    currentTreatmentSearchData.current = data;
    trackTreatmentInput(value, selectedKey, index);
  };

  const trackTreatmentInput = (
    value: string,
    selectedKey: string | null,
    index: number
  ): void => {
    if (selectedKey) {
      track.treatmentSearchSelect(value, index);
    } else if (value === '') {
      track.treatmentSearchClear();
    }
  };

  const onLocationSearchInputChange = (
    value: string,
    selectedKey: string | null,
    data: ItemResultData | null,
    index: number
  ): void => {
    currentLocationSearchData.current = data;
    let isExternalLocation = false;

    if (data) {
      isExternalLocation = data.entityType === ItemResultType.ExternalLocation;
    }

    if (!isValidLocationSearchInput && data !== null) {
      setIsValidLocationSearchInput(true);
    }

    trackLocationInput(value, selectedKey, isExternalLocation, index);
    setLocationValue(value);
  };

  const trackLocationInput = (
    value: string,
    selectedKey: string | null,
    isExternalLocation: boolean,
    index: number
  ): void => {
    if (selectedKey) {
      track.locationSearchSelect(value, index);

      if (isExternalLocation) {
        externalLocationSearchSelect('treatment_search_form', value);
      }
    } else if (value === '') {
      track.locationSearchClear();
    }
  };

  const onDateTimeChange = (
    date: Date | null,
    timeFrom: string | null,
    timeTo: string | null,
    dateTimeString: string
  ): void => {
    currentDate.current = date;

    if (timeFrom && timeTo) {
      currentTime.current = { from: timeFrom, to: timeTo };
    } else {
      currentTime.current = null;
    }

    trackDateTimeInput(dateTimeString);
  };

  function trackDateTimeInput(dateTimeString: string): void {
    if (dateTimeString === '') {
      track.dateTimeClear();
    } else {
      track.dateTimeSelect(dateTimeString);
    }
  }

  const debouncedTrackLocationSearchType = debounce(
    (value: string, resultCount: number) => {
      track.locationSearchType(value, resultCount);
    },
    500
  );

  const onLocationSearchResultsChange = (
    searchValue: string,
    results: ItemResult[]
  ): void => {
    debouncedTrackLocationSearchType(searchValue, results.length);
  };

  const onDateTimeTrack = (detail: TrackingDetails): void => {
    trackStructuredEvent({
      category: 'treatment_search_form',
      ...detail,
    });
  };

  const buttonColourName = props.cmsSearch['highlight-colour'];
  const buttonColour = getButtonColour(buttonColourName);
  const { cmsCommon } = props;

  return (
    <div className={styles.container}>
      <TreatmentSearchInput
        cmsCommonControls={cmsCommon.controls}
        ref={treatmentSearchInputRef}
        recentTreatments={recentTreatmentSearches}
        searchFunction={treatmentSearchFunction}
        isErrorStyling={!isValidTreatmentSearchInput}
        onChange={onTreatmentSearchInputChange}
        onFocus={track.treatmentSearchFocus}
        onBlur={track.treatmentSearchBlur}
      />

      <LocationSearchInput
        cmsCommonControls={cmsCommon.controls}
        pageData={props.pageData}
        ref={locationSearchInputRef}
        recentLocations={recentLocationSearches}
        popularLocations={props.pageData.homepage.popularLocations}
        hasCurrentLocationOption={!gotCurrentLocationRejection}
        isErrorStyling={!isValidLocationSearchInput}
        onChange={onLocationSearchInputChange}
        onFocus={track.locationSearchFocus}
        onBlur={track.locationSearchBlur}
        onSearchResultsChange={onLocationSearchResultsChange}
      />

      <DateTimeInput
        cmsCalendar={props.cmsCommon}
        cmsCommonControls={cmsCommon.controls}
        ref={dateTimeInputRef}
        selectableToEndOfMonth
        maxDaysInFuture={90}
        onChange={onDateTimeChange}
        placeholder={
          props.cmsCommon.controls['datetime-input']['date-time-placeholder']
        }
        onFocus={track.dateTimeFocus}
        onBlur={track.dateTimeBlur}
        onTrack={onDateTimeTrack}
      />

      <LoadingButton
        disableLoading={
          !isValidLocationSearchInput || !isValidTreatmentSearchInput
        }
        positioningClassname={styles.button}
        colour={buttonColour}
        label={props.cmsSearch['search-button']}
        onClick={onSubmit}
      />
    </div>
  );
}
